import { useState } from 'react'
import { getCompletedStepCount, getCurrentStage, getStepCount } from 'Utils'

import useResults from 'Hooks/useResults'

const useStages = (exercise, onNext, optional) => {
  const stages = exercise.stages
  const { results } = useResults()
  const [currentStage, setCurrentStage] = useState(getCurrentStagePos(exercise, results, optional)[0])
  const [currentStep, setCurrentStep] = useState(getCurrentStagePos(exercise, results, optional)[1])

  const next = () => {
    if (currentStep < stages[currentStage].length - 1) {
      setCurrentStep(currentStep + 1)
    } else if (currentStage < stages.length - 1) {
      setCurrentStage(currentStage + 1)
      setCurrentStep(0)
    } else {
      setCurrentStep(0)
      setCurrentStage(0)
      onNext(true)
    }
  }

  return {
    next,
    currentStage,
    currentStep,
  }
}

const getCurrentStagePos = (exercise, results, optional) => {
  const totalSteps = getStepCount(exercise)
  const lastCompletedStep = getCompletedStepCount(exercise, results)

  if(lastCompletedStep === 0 || lastCompletedStep === totalSteps) {
    return [0, 0]
  }

  const lastAnsweredStage = getCurrentStage(exercise, results)

  const stepsInLastStage = Object.keys(results[`exercise${optional ? `-optionalExercise-${exercise.index}`: exercise.index}`][`stage${lastAnsweredStage}`]).length

  if (exercise.stages[lastAnsweredStage].length === stepsInLastStage) {
    return [lastAnsweredStage + 1, 0]
  }

  return [lastAnsweredStage, stepsInLastStage]
}

export default useStages
