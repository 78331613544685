/* Global imports */
import React from 'react'
import styled from 'styled-components'

import { string, func, bool } from 'prop-types'

/* Local imports */
import Button from 'Components/Button'

/* Component definition */
const NextButton = ({ children, className, disabled, onClick, ...props }) => {
  return (
    <SNextButton
      className={className}  
      disabled={disabled} 
      onClick={onClick}
      primary
      {...props}
    >
    {children}
    </SNextButton>
  )
}

/* PropTypes */
NextButton.propTypes = {
  className: string,
  disabled: bool,
  onClick: func,
}

NextButton.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SNextButton = styled(Button)`
  border-radius: 0px !important;
  border: none !important;
  height: 64px !important;
  font-size: 18px !important;
  font-weight: bold !important;
  letter-spacing: 1.5px !important;
  line-height: 21px !important;
  text-align: center !important;
  text-transform: uppercase !important;
  font-family: OfficinaSansITCPro-Bold !important;
  padding-bottom: 16px !important;
`
export default NextButton