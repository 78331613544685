import { useEffect, useState } from 'react'
import { createMachine, interpret } from 'xstate'

const loadingMachine = createMachine({
  initial: 'idle',
  states: {
    idle: {
      on: {
        START: 'loading',
      },
    },
    loading: {
      on: {
        DONE: 'loaded',
      }
    },
    loaded: {
      type: 'final'
    },
  },
});

export const service = interpret(loadingMachine)
      .start()

const useLoading = function() {
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const subscription = service.subscribe((state) => {
      setIsLoading(state.matches('loading'))
    })

    return subscription.unsubscribe
  }, [])

  return {
    isLoading
  }
}

export default useLoading
