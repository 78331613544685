import { useCallback, useEffect, useState } from 'react'

import useSession from 'Hooks/useSession'

export const STATE = {
  PAUSED:  'paused',
  PLAYING: 'playing',
  STOPPED: 'stopped',
}

const useAudio = (audioFile, allowedReplays = Infinity, optional) => {
  const { isPlus } = useSession()
  const [replays, setReplays] = useState(0)
  const [element] = useState(new Audio())
  const [status, setStatus] = useState(STATE.STOPPED)

  const stop = useCallback(() => {
    element.pause()
    element.currentTime = 0
  }, [element])

  const play = useCallback((playPause = true) => {
    setStatus(getAudioStatus(element))
    playPause && element.paused ? element.play() : element.pause()
    setStatus(getAudioStatus(element))
  }, [element])

  const load = useCallback((filename) => {
    const file = requireAudio(filename, isPlus, optional)

    stop()
    setReplays(0)

    if (file) {
      element.src = file
      element.load()
    }
  }, [element, isPlus, optional, stop])

  useEffect(() => {
    if (audioFile) {
      load(audioFile)
    }
  }, [audioFile, load])

  // Stop playing the audio file if the component is destroyed:
  useEffect(() => {
    const unbindOnEnded = on(element, 'ended', () => {
      element.currentTime = 0
      setReplays(r => r + 1)
      setStatus(getAudioStatus(element))
    })

    return () => {
      element.pause()
      unbindOnEnded()
    }
  }, [element])

  useEffect(() => {
    return on(window, 'visibilitychange', ({ target }) => {
      const isHidden = target.visibilityState === 'hidden'

      if (isHidden) {
        play(false)
      }
    })
  }, [play])

  return { disabled: replays >= allowedReplays, load, play, status }
}

export default useAudio

const requireAudio = (filename, isPlus, optional) => {
  const folder = isPlus ? optional ? 'audiofilesPlus/optional': 'audiofilesPlus' : 'audiofiles' 

  return filename ? require('Assets/'+folder+'/'+filename) : null
}

const on = (element, event, callback) => {
  element.addEventListener(event, callback)

  return () => {
    element.removeEventListener(event, callback)
  }
}

const getAudioStatus = audioElement => {
  return audioElement.paused
    ? audioElement.currentTime === 0
    ? STATE.STOPPED
    : STATE.PAUSED
  : STATE.PLAYING
}
