/* Global imports */
import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { Grid, Responsive } from 'semantic-ui-react'
import { isEmpty } from 'crocks/predicates'
import { append } from 'ramda'
import { withRouter } from 'react-router-dom'

/* Local imports */
import { listenAndSelectToneShape } from 'Definitions/shapes'
import Button from 'Components/Button'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import DemoProgressBar from 'Components/DemoProgressBar'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import useSteps from 'Hooks/useSteps'

/* Component definition */
const ListenAndSelectTone = ({ exercise, onNext }) => {
  const [answer, setAnswer] = useState([])
  const { currentStep, next } = useSteps(exercise, onNext)

  const setCurrentAnswer = value => () => {
    setAnswer(
      exercise.multi
      ? answer.includes(value)
        ? answer.filter(val => val !== value)
        : append(value, answer)
      : [value]
    )
  }

  const goNext = () => {
    setAnswer([])
    next()
  }

  useEffect(()=> {
    window.scrollTo(0, 0)
  },[currentStep])

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
            <Text as="h4" notr>Übung { exercise.number }</Text>
            <Separator minWidth={992}/>
        </Grid.Column>
        <Spacer height="16px"/>
        <Grid.Column mobile={16} computer={8}>
          <Text as="h5" notr>{ exercise.title }</Text>
          <Text className="exercise-text" notr>{ exercise.description }</Text>
          <Spacer height="16px"/>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={currentStep > 0 ? 1 : 0}
                  className="full-width"
                  exercise={exercise}
                  scrollOnLoad
                  scrollOnPlay
                  step={currentStep}
                />
                 <DemoProgressBar currentStep={currentStep} total={exercise.steps.length}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8}>
         <Responsive minWidth={992}>
          <Text as="h5">exercise.selectOption</Text>
          <Spacer height="42px" />
         </Responsive>
          <Grid padded>
            {
              exercise.options.map((option, index) =>
                <Grid.Row key={index}>
                  <Button
                    className="full-width main-font"
                    key={index}
                    notr
                    onClick={setCurrentAnswer(option.value)}
                    secondary={answer.includes(option.value)}
                    toggle
                    value={option.value}
                  >
                    <p>
                    {option.label}
                    </p>
                  </Button>
                </Grid.Row>
            )
            }
          </Grid>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <NextButton
          className="bottom full-width pos-fixed btn-next-disabled"
          disabled={isEmpty(answer)}
          onClick={() => goNext()}
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  )
}

/* PropTypes */
ListenAndSelectTone.propTypes = {
  exercise: listenAndSelectToneShape,
  onNext:   func.isRequired,
}

ListenAndSelectTone.defaultProps = {}

/* Local utility functions */
/* Local Styled Components */

export default withRouter(ListenAndSelectTone)
