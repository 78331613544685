/* Global imports */
import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { Grid, Responsive} from 'semantic-ui-react'
import { isDefined, isEmpty } from 'crocks/predicates'
import { not } from 'crocks/logic'
import { any, equals, path } from 'ramda'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

/* Local imports */
import { listenAndMarkGapsShape } from 'Definitions/shapes'
import Button from 'Components/Button'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import Separator from 'Components/Separator'
import ProgressBar from 'Components/ProgressBar'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import useResults from 'Hooks/useResults'
import useSteps from 'Hooks/useSteps'
import useSession from 'Hooks/useSession'


// const setChoices = (exercise, currentStep) => {
  //   if (exercise.oneStep) {
    //     return []
    //   }
    
    //   const stepChoices = path(['steps', currentStep, 'choices'], exercise)
    
    //   return exercise.choices ? exercise.choices : stepChoices
    // }
    
    // const getAnswerTemplate = ({ oneStep, steps }) => oneStep ? Array.from({ length: steps[0].options.length }) : []
    
    // const addAnswer = ({ oneStep }, step, answer, value) =>
    // oneStep ? update(step, answer[step] === value ? undefined : value, answer) : toggleInList(value)(answer || [])
    
    /* Component definition */
const ListenAndMarkGaps = ({ exercise, history, onNext }) => {
  const { isPlus } = useSession()
  const {currentStep, next } = useSteps(exercise, onNext)
  const [answer, setAnswer] = useState([])
  const { saveAnswer } = useResults()
  const correctAnswer = path(['steps', currentStep, 'answer'], exercise)

  const setCurrentAnswer = index => (e, { value }) => {
    const newAnswer = [...answer]
    
    if (newAnswer.includes(value)) {
      newAnswer.splice(newAnswer.indexOf(value), 1)
      setAnswer(newAnswer)
    } else {
      newAnswer.push(value)
      setAnswer(newAnswer)
    }
}

  const getCorrectnessBit = (selectedAnswer) => {
    if (exercise.oneStep) {
      return equals(correctAnswer, selectedAnswer.sort(function(a, b) { return a - b; })) ? 1 : 0
    }
  }

  const goNext = () => {
    return saveAnswer({
      answer,
      correct:  getCorrectnessBit(answer),
      exercise: exercise.index,
      step:     currentStep,
    })
      .then((res) => {
        setAnswer([])
        next()
      })
  }

  useEffect(()=> {
    window.scrollTo(0, 0)
  },[currentStep])

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4" className={isPlus ? 'plusText' : 'primaryText'} notr>Übung { exercise.index + 1 }</Text>
          <Separator minWidth={768}/>
        </Grid.Column>
        <Spacer height="16px"/>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div>
            <Text as="h5" notr>{ exercise.title }</Text>
          </div>
          <Spacer height="30px"/>
          <Text className="exercise-text" notr>{ exercise.description }</Text>
          <Spacer height="16px"/>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={currentStep > 0 ? 1 : 0}
                  className="full-width audio-btn"
                  exercise={exercise}
                  scrollOnLoad
                  scrollOnPlay
                  step={currentStep}
                />
                <ProgressBar exercise={exercise} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Responsive maxWidth={768}>
            <Spacer height="20px" />
          </Responsive>
          <div>
            <Text as="h5">exercise.selectOption</Text>
          </div>
          <Spacer height="30px"/>
          {renderLetters(exercise, answer, setCurrentAnswer)}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <NextButton
          className={`bottom full-width pos-fixed btn-next-disabled ${isPlus ? 'plus' : ''} `}
          disabled={exercise.oneStep ? any(not(isDefined), answer) : isEmpty(answer)}
          onClick={() => goNext()}
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  )
}

/* PropTypes */
ListenAndMarkGaps.propTypes = {
  exercise: listenAndMarkGapsShape,
  onNext:   func.isRequired,
}

ListenAndMarkGaps.defaultProps = {}

/* Local utility functions */
const renderLetters = (exercise, answer, setCurrentAnswer) => {
  return (
    exercise.steps[0].options.map((option, index) =>
                                  <GContainer key={index}>
                                    <SContainer>
                                      {
                                        option.choices.map((choice) =>
                                          Array.from(choice).map((letter, tindex) => {

                                            return (
                                              <SButton
                                                className={`main-font font22 ${answer.indexOf(tindex) !== -1 && 'rightBordered'}`}
                                                notr
                                                key={tindex}
                                                onClick={setCurrentAnswer(index)}
                                                toggle
                                                value={tindex}
                                              >
                                                <p className="markGapsP">{letter}</p>
                                              </SButton>
                                              )

                                          }
                                          )
                                        )
                                      }
                                    </SContainer>
                                  </GContainer>
                                ))
}

/* Local Styled Components */
const SButton = styled(Button)`
flex: 0;
max-width: 30px;
min-width: 25px;
text-align: center!important;
position: relative;
padding: 3px 9px 3px 0px!important;
margin-right: px!important;
margin-bottom: 15px!important;
border: none!important;
background: none!important;
position: relative;
left: 19px;
`
const SContainer = styled.div`
  align-items:     left;
  display:         flex !important;
  justify-content: left;
  flex-flow: wrap;
`

const GContainer = styled.div`
  padding: 0px;
`

// const SButton = styled(Button)`
// flex: 0;
// max-width: 60px;
// padding: 15px!important;
// margin-right: 6px!important;
// margin-bottom: 6px!important;
// min-width: 35px;
// text-align: center!important;
// border: none!important;
// box-shadow: 0px 1px 1px rgb(234 106 80 / 30%)!important;
// background-color: #FFEAE5!important;
// color: #EA6A50!important;
// position: relative;
// `
// const SContainer = styled.div`
//   align-items:     center;
//   display:         flex !important;
//   justify-content: center;
//   flex-flow: wrap;
// `

// const GContainer = styled.div`
//   background-color: #FFEAE5;
//   padding: 15px;
// `


export default withRouter(ListenAndMarkGaps)
