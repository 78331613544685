/* Global imports */
import React, { useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

// import { } from 'prop-types'
import { Grid, Image } from 'semantic-ui-react'

/* Local imports */
import { PROFESSIONAL_INFO, RESULTS, TRAINING_DAY } from 'Definitions/routes'
import NextButton from 'Components/NextButton'
import Comment from 'Components/Comment'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'

/* Component definition */
const SuccessMessage = ({ history, match, incomplete, optional}) => {
  const [writeComment, setWriteComment] = useState(false)
  const { dayIndex } = match.params
  console.log('Success', optional)

  return (
    <>
    {
    writeComment ?
    <Comment dayIndex={dayIndex} history={history}/>
    :
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'center' }}>
          <SContentWrapper>
            <Grid.Column>
              <Image
                alt="success-img"
                style={{ padding: '8px', objectFit: 'contain' }}
                src={require(`Assets/images/${setIcon(dayIndex)}.gif`)}
              />
            </Grid.Column>
            <SWrapper>
            {
              incomplete ?
              <div>
              <Text as="h5" style={{ textAlign: 'center'}}>exercise.success.incomplete</Text>
              <SNextButton
              className="full-width rounded login-btn"
              onClick={() => history.push(TRAINING_DAY(dayIndex))}
              >
              exercise.success.trainingDay
              </SNextButton>
              </div>
              :
              <div>
              <Text as="h5" style={{ textAlign: 'center'}}>exercise.success.title</Text>
              {
                dayIndex === "13" ?
                <Text as="p" style={{ textAlign: 'center' }}notr>
                  Herzlichen Glückwunsch, Sie haben das Training vollständig absolviert. Mit täglichen Übungen haben Sie aktiv zur Verbesserung Ihrer Hörverarbeitung beigetragen. Ihr Pro Akustiker nimmt sich Zeit für Ihre Fragen und unterstützt Sie dabei, Ihre Trainingserfolge zu festigen und auszubauen.
                </Text>
                :
                <Text as="p" style={{ textAlign: 'center'}}>exercise.success.message</Text>

              }
              <Spacer height="8px"/>
              <SNextButton
                className="full-width rounded login-btn"
                onClick={() => history.push(RESULTS(dayIndex, 0))}
                >
                exercise.success.results
              </SNextButton>
              <Spacer height="16px"/>
              {
                !optional &&
                <SBottomButton
                  className="full-width rounded login-btn"
                  onClick={() => setWriteComment(true)}
                  >
                <Text as="p" style={{ textAlign: 'center', paddingTop: '4px'}}>exercise.success.leaveComment</Text>
                </SBottomButton>
              }
              <Spacer height="16px"/>
              </div>
            }
            </SWrapper>
          </SContentWrapper>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    }
    </>
  )
}

/* PropTypes */
SuccessMessage.propTypes = {}

SuccessMessage.defaultProps = {}

/* Local utility functions */
const setIcon = (dayIndex) => {
  return dayIndex === "13" ? 'flag-green' : 'flag-blue'
}

/* Local Styled Components */
const SContentWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width : 500px !important;
`
const SWrapper = styled.div`
  @media (max-width: 992px) {
    max-width: 360px;
  }
`
const SNextButton = styled(NextButton)`
@media (min-width: 768px) {
  height: 44px !important;
}
`

const SBottomButton = styled.button`
background-color: transparent;
border-radius: 4px;
border: 1px solid #0082BA;
color: #000000;
display: flex;
font-family: OfficinaSansITCPro-Bold !important;
font-size: 14px;
font-weight: bold;
height: 36px;
letter-spacing: 1.5px;
line-height: 36px;
margin: 0 auto !important;
text-align: center;
text-transform: uppercase;
width: fit-content;
`

export default SuccessMessage
