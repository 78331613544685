/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { Responsive } from 'semantic-ui-react'

/* Local imports */

/* Component definition */
const Separator = ({ minWidth }) => {

  return (
    <Responsive minWidth={minWidth}>
      <Line/>
    </Responsive>
  )
}

/* PropTypes */
Separator.propTypes = {
}

Separator.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const Line = styled.hr`
  border: 0.5px solid #D8D8D8 !important;
  margin: 0 !important;
`
export default Separator