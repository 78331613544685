/* Global imports */
import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { Grid, Image, Responsive} from 'semantic-ui-react'
import { isArray, isEmpty } from 'crocks/predicates'
import { eqBy, equals, path, update } from 'ramda'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

/* Local imports */
import { listenAndTypeAndPickShape } from '../definitions/shapes'
import { toggleInList } from 'Utils'
import Button from 'Components/Button'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import Separator from 'Components/Separator'
import ProgressBar from 'Components/ProgressBar'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import useResults from 'Hooks/useResults'
import useSteps from 'Hooks/useSteps'
import useSession from 'Hooks/useSession'
import Input from 'Components/Input'
import NumberInput from 'Components/NumberInput'
import DateInput from './DateInput'
import { processDate, processNumber, processString } from 'Utils'


const setChoices = (exercise, currentStep) => {
  const stepChoices = path(['steps', currentStep, 'pick', 'choices'], exercise)

  return exercise.choices ? exercise.choices : stepChoices
}

const addAnswer = ({ oneStep }, step, answer, value) =>
      oneStep ? update(step, answer[step] === value ? undefined : value, answer) : toggleInList(value)(answer || [])


/* Component definition */
const ListenAndTypeAndPick = ({ exercise, onNext }) => {
  const { isPlus } = useSession()
  const {currentStep, next } = useSteps(exercise, onNext)
  const [pickAnswer, setPickAnswer] = useState([])
  const [typeAnswer, setTypeAnswer] = useState('')
  const correctPickAnswer = path(['steps', currentStep, 'pick', 'answer'], exercise)
  const correctTypeAnswer = path(['steps', currentStep, 'type', 'answer'], exercise)
  const typeExercisePart = exercise.steps[currentStep].type
  const { saveAnswer } = useResults()

  
  useEffect(() => {
    setPickAnswer([])
  }, [exercise])


  const setCurrentPickAnswer = index => (e, { value }) => {
    setPickAnswer(
      exercise.multi
        ? addAnswer(exercise, exercise.oneStep ? index : currentStep, pickAnswer, value)
        : [value]
    )
  }

  const getCorrectnessBit = (selectedAnswer) => {
    if (exercise.oneStep) {
      return equals(correctPickAnswer, selectedAnswer) ? 1 : 0
    }

    return answerPickCorrect(correctPickAnswer, selectedAnswer) ? 1 : 0
  }

  const goNext = () => {
    const isCorrect = answerTypeCorrect(correctTypeAnswer, typeAnswer, typeExercisePart.inputType) && getCorrectnessBit(pickAnswer) === 1

    return saveAnswer({
      answer: [typeAnswer.replace('.', ','), pickAnswer[0]],
      correct: isCorrect ? 1 : 0,
      exercise: exercise.index,
      step:     currentStep,
    })
      .then((res) => {
        setPickAnswer([])
        setTypeAnswer('')
        next()
      })
  }

  useEffect(()=> {
    window.scrollTo(0, 0)
  },[currentStep])

  return (
    <Grid padded>
      <Grid.Row className="relaxed">
        <Grid.Column width={16}>
          <Text className={isPlus ? 'plusText' : 'primaryText'} as="h4" notr>Übung { exercise.index + 1 }</Text>
          <Separator minWidth={768}/>
        </Grid.Column>
        <Spacer height="16px"/>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div>
            <Text as="h5" notr>{ exercise.title }</Text>
          </div>
          <Spacer height="30px"/>
          <Text className="exercise-text" notr>{ exercise.description }</Text>
          <Spacer height="16px"/>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={currentStep > 0 ? 1 : 0}
                  className="full-width audio-btn"
                  exercise={exercise}
                  scrollOnLoad
                  scrollOnPlay
                  step={currentStep}
                />
                 <ProgressBar exercise={exercise} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Responsive maxWidth={768}>
            <Spacer height="20px" />
          </Responsive>
          <div>
            <Text as="h5">exercise.selectOption</Text>
          </div>
          <Spacer height="30px"/>
          <Grid.Row>
            {
              typeExercisePart.description &&
                <Text as="h3" notr>{typeExercisePart.description}</Text>
            }
          </Grid.Row>
          <Spacer height="8px"/>
          {
            renderInput({
              step: typeExercisePart,
              answer: typeAnswer,
              onChange: (e, { value }) => setTypeAnswer(value),
              isPlus
            })
          }
          <Spacer height="42px"/>
          {
            renderSteps(exercise, currentStep, setCurrentPickAnswer, pickAnswer, isPlus)
          }
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <NextButton
          className={`bottom full-width pos-fixed btn-next-disabled ${isPlus ? 'plus' : ''} `}
          disabled={isEmpty(pickAnswer) || isInputEmpty(typeAnswer, typeExercisePart.inputType)}
          onClick={() => goNext()}
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  )
}

/* PropTypes */
ListenAndTypeAndPick.propTypes = {
  exercise: listenAndTypeAndPickShape,
  onNext:   func.isRequired,
}

ListenAndTypeAndPick.defaultProps = {}

/* Local utility functions */

const isInputEmpty = (answer, type) => {
  return type === 'number'
    ? answer
      .replace('.', '')
      .split('')
      .every(c => c === '0')
    : !answer
}

const renderInput = ({ step, answer, onChange, isPlus}) => {
  switch (step.inputType) {
    case 'number':
      return (
        <NumberInput
          mask={step.answer}
          className={`numeric increase ${isPlus ? 'plus' : ''}`}
          labelClass="input-label"
          onChange={onChange}
          // placeholder="exercise.placeholder.answer"
          prefix={step.prefix}
          sufix={step.sufix}
          value={answer}
        />
      )
    case 'date':
      return (
        <DateInput
          format={step.answer}
          className={`full-width increase ${isPlus ? 'plus' : ''}`}
          labelClass="input-label"
          onChange={onChange}
          placeholder="exercise.placeholder.answer"
          value={answer}
        />
      )
    default:
      return (
        <Input
          className={`full-width increase ${isPlus ? 'plus' : ''}`}
          labelClass="input-label increase"
          onChange={onChange}
          placeholder="exercise.placeholder.answer"
          prefix={step.prefix}
          sufix={step.sufix}
          value={answer}
        />
      )
  }
}

const answerTypeCorrect = (correctAnswer, answer, inputType) => {
  const comparator = inputType === 'number'
        ? processNumber
        : inputType === 'date'
        ? processDate
        : processString

  return eqBy(comparator , answer, correctAnswer)
}

const setIcon = (exercise, index) => {
  return isArray(exercise.icon) ? exercise.icon[index] : exercise.icon
}

const answerPickCorrect = (correctPickAnswer, userAnswer) => {
  return correctPickAnswer.length === userAnswer.length
    && userAnswer.reduce((total, current) => total && correctPickAnswer.includes(current), true)
}

const renderSteps = (exercise, currentStep, setCurrentPickAnswer, pickAnswer, isPlus) => {
  const step = exercise.steps[currentStep].pick
  const choices = setChoices(exercise, currentStep)

  return <div>
                <Grid.Row>
                  {
                    step.description &&
                      <Text as="h3" notr>{step.description}</Text>
                  }
                </Grid.Row>
                <Grid padded>
                  {
                    choices.map((choice, index) =>
                                <Grid.Row key={index}>
                                  <SButton
                                    className={`full-width main-font ${isPlus ? 'plus' : ''}`}
                                    key={index}
                                    notr
                                    icon={exercise.icon}
                                    onClick={setCurrentPickAnswer(index)}
                                    secondary={pickAnswer.includes(index)}
                                    toggle
                                    value={index}
                                  >
                                    {exercise.icon && <Image key={index} src={require(`Assets/icons/${setIcon(exercise, index)}.svg`)} alt='icon' />}
                                    <p>{choice}</p>
                                  </SButton>
                                </Grid.Row>
                              )
                  }
                </Grid>
              </div>
}

/* Local Styled Components */
const SButton = styled(Button)`
  align-items:     center;
  display:         flex !important;
  justify-content: flex-start;
  padding:         ${( { icon }) => icon ? '0px 6px' : '6 16px'} !important;
  box-shadow:      ${( { icon }) => icon ? '0 0 5px 0 rgba(0,0,0,0.15)' : 'none'} !important;
  border:          ${( { icon }) => icon && 'none' } !important;
`

export default withRouter(ListenAndTypeAndPick)
