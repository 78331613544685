/* Global imports */
import React, { useState } from 'react'
import { number, object } from 'prop-types'

/* Local imports */
import { DEMO_EXERCISE } from 'Definitions/routes'
import ListenAndType from 'Components/demo/ListenAndType'
import ListenAndSort from 'Components/demo/ListenAndSort'
import ListenAndSelectTone from 'Components/demo/ListenAndSelectTone'
import ListenAndPick from 'Components/demo/ListenAndPick'
import ReviewExercise from 'Components/demo/ReviewExercise'
import DemoSuccessMessage from 'Components/DemoSuccessMessage'

import exercises from '../ExercisesDemo'

/* Component definition */
const DemoExercise = ({ history, match }) => {
  const { exerciseIndex, dayIndex } = match.params
  const [ lastDayExercise, setLastDayExercise] = useState(false)
  const currentExercise = exercises[dayIndex][exerciseIndex]

  const onNext = (final) => {
    const nextExerciseIndex = parseInt(exerciseIndex) + 1

    if (nextExerciseIndex < exercises[dayIndex].length) {
      return history.push(DEMO_EXERCISE(dayIndex, nextExerciseIndex))
    }

    return setLastDayExercise(true)
  }

  if (lastDayExercise) {
    return <DemoSuccessMessage history={history} match={match}/> 
  }

  return (
    <>
      {
        renderExercise(currentExercise, onNext)
      }
    </>
  )
}

/* PropTypes */
DemoExercise.propTypes = {
  currentDay: number,
  match:      object,
}

DemoExercise.defaultProps = {
  currentDay: 0,
}

/* Local utility functions */
const renderExercise = (exercise, onNext) => {
  switch (exercise.type) {
    case 'LISTEN_AND_TYPE':
      return <ListenAndType exercise={exercise} onNext={onNext} />
    case 'LISTEN_AND_SORT':
      return <ListenAndSort exercise={exercise} onNext={onNext} />
    case 'LISTEN_AND_SELECT_TONE':
      return <ListenAndSelectTone exercise={exercise} onNext={onNext} />
    case 'LISTEN_AND_PICK':
      return <ListenAndPick exercise={exercise} onNext={onNext} />
    case 'REVIEW_EXERCISE':
      return <ReviewExercise exercise={exercise} onNext={onNext} />
  default:
    throw Error(`Unrecognized Type ${exercise.type}`)
  }
}

/* Local Styled Components */

export default DemoExercise
