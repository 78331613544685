/* Global imports */
import React from 'react'
import { pickAll } from 'ramda'
import { Grid, Image, Responsive, GridColumn } from 'semantic-ui-react'

/* Local imports */
import Text from 'Components/Text'
import Spacer from 'Components/Spacer'
import Separator from 'Components/Separator'
import useSession from 'Hooks/useSession'

/* Component definition */
const ProfessionalInfo = () => {

  const { settings } = useSession()

    const professionalData= settings && pickAll([
        'address',
        'city',
        'companyName',
        'firstName',
        'email',
        'name',
        'fax',
        'phone',
        'settings',
        'zipCode',
    ])(settings)

  return (
    settings &&
    <>
      <Grid padded>
        <Grid.Row>
          <Grid.Column width={16}>
            <Text as="h4">mainMenu.professionalInfo</Text>
            <Separator minWidth={0}/>
            <Spacer height="32px"/>
          </Grid.Column>
          <Responsive style={{margin: '0 auto'}} maxWidth={767}>
                {renderLogo(professionalData, 'logo')}
          </Responsive>
        <Grid.Column mobile={16} tablet={8} computer={10}>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} tablet={6} computer={6}>
                <Text as= "h6" notr>Wo Sie uns finden</Text>
              </Grid.Column>
              <GridColumn mobile={16} tablet={10} computer={10}>
                <Responsive maxWidth={768}>
                  <Spacer height="16px"/>
                </Responsive>
                <Text as="p" notr>{professionalData.companyName}</Text>
                <Text as="p" notr>{professionalData.address}</Text>
                <Text className="right-padded" notr>{professionalData.zipCode}</Text>
                <Text notr>{professionalData.city}</Text>
                <br/>
                <Spacer height="16px"/>
                <Text className="right-padded" notr>{professionalData.phone}</Text>
                <Text notr>{professionalData.fax}</Text>
                <br/>
                <Spacer height="16px"/>
                <Text as="p" notr>{professionalData.contactEmail}</Text>
              </GridColumn>
            </Grid.Row>
            <Spacer height="16px"/>
              {
                professionalData.settings &&
                <>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={6} computer={6}>
                      <Text as= "h6" notr>Öffnungszeiten</Text>
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={10} computer={10}>
                    <Responsive maxWidth={768}>
                      <Spacer height="16px"/>
                    </Responsive>
                      <div dangerouslySetInnerHTML={{ __html: professionalData.settings.openingHours }}></div>
                    </Grid.Column>
                  </Grid.Row>
                  <Spacer height="16px"/>
                  <Grid.Row>
                    <Grid.Column mobile={16} tablet={10} computer={10}>
                      <div 
                        dangerouslySetInnerHTML={{ __html: professionalData.settings.aboutUs }}>
                      </div>
                    </Grid.Column>
                  </Grid.Row>
                </>
              }
          </Grid>
        </Grid.Column>
        <Responsive style={{margin: '0 auto'}} maxWidth={767}>
          <Grid.Column mobile={16} tablet={6} computer={6}>
            <Spacer height="32px"/>
            {renderLogo(professionalData, 'image')}
          </Grid.Column>
        </Responsive>
        <Responsive  style={{margin: '0 auto'}} minWidth={768}>
          <Grid.Column mobile={16} tablet={6} computer={6}>
            {renderLogo(professionalData, 'logo')}
            {renderLogo(professionalData, 'image')}
          </Grid.Column>
        </Responsive>
      </Grid.Row>
     </Grid>
      <Spacer height="88px"/>
    </>
  )
}

/* PropTypes */
ProfessionalInfo.propTypes = {}

ProfessionalInfo.defaultProps = {}

/* Local utility functions */

const renderLogo = (professionalData, type) => {

  return (
    <Grid.Column mobile={16} tablet={6} computer={6}>
      <Image 
        alt="Logo" 
        size="medium"
        src={setLogo(professionalData, type)}
        style={{ marginBottom: '48px'}}
      />
  </Grid.Column>
  )
}

const setLogo = (professionalData, type) => {
  const logoPlaceholder = require('Assets/images/login.svg')
  
  if(!professionalData.settings) {
    return logoPlaceholder
  } else {
    return professionalData.settings[type].downloadUrl !== '' 
      ? professionalData.settings[type].downloadUrl
      : logoPlaceholder
  }
}

/* Local Styled Components */

export default ProfessionalInfo
