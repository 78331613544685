/* Global imports */
import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { Grid, Responsive } from 'semantic-ui-react'
import { isEmpty } from 'crocks/predicates'
import { append, path } from 'ramda'
import { withRouter } from 'react-router-dom'

/* Local imports */
import { listenAndSelectToneShape } from 'Definitions/shapes'
import Button from 'Components/Button'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import ProgressBar from 'Components/ProgressBar'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import useResults from 'Hooks/useResults'
import useSteps from 'Hooks/useSteps'
import useSession from 'Hooks/useSession'

/* Component definition */
const ListenAndSelectTone = ({ exercise, onNext }) => {
  const { isPlus } = useSession()
  const [answer, setAnswer] = useState([])
  const { currentStep, next } = useSteps(exercise, onNext)
  const { saveAnswer } = useResults()
  const correctAnswer = path(['steps', currentStep, 'answer'], exercise)

  const setCurrentAnswer = value => () => {
    setAnswer(
      exercise.multi
      ? answer.includes(value)
        ? answer.filter(val => val !== value)
        : append(value, answer)
      : [value]
    )
  }

  const goNext = () => {
    return saveAnswer({
      answer,
      correct:  answerCorrect(correctAnswer, answer) ? 1 : 0,
      exercise: exercise.index,
      step:     currentStep,
    })
      .then((res) => {
        setAnswer([])
        next()
      })
  }

  useEffect(()=> {
    window.scrollTo(0, 0)
  },[currentStep])

  return (
    <Grid padded>
      <Grid.Row className="relaxed">
        <Grid.Column width={16}>
            <Text as="h4" className={isPlus ? 'plusText' : 'primaryText'} notr>Übung { exercise.index + 1 }</Text>
            <Separator minWidth={992}/>
        </Grid.Column>
        <Spacer height="16px"/>
        <Grid.Column mobile={16} computer={8}>
          <div>
            <Text as="h5" notr>{ exercise.title }</Text>
          </div>
          <Spacer height="30px"/>
          <Text className="exercise-text" notr dangerouslySetInnerHTML={{__html: exercise.description}}></Text>
          <Spacer height="16px"/>
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={currentStep > 0 ? 1 : 0}
                  className="full-width"
                  exercise={exercise}
                  scrollOnLoad
                  scrollOnPlay
                  step={currentStep}
                />
                 <ProgressBar exercise={exercise} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8}>
          <Responsive maxWidth={768}>
            <Spacer height="20px" />
          </Responsive>
          <div>
            <Text as="h5">exercise.selectOption</Text>
          </div>
          <Spacer height="30px" />
          <Grid padded>
            {
              exercise.options.map((option, index) =>
                <Grid.Row key={index}>
                  <Button
                    className="full-width main-font"
                    key={index}
                    notr
                    onClick={setCurrentAnswer(option.value)}
                    secondary={answer.includes(option.value)}
                    toggle
                    value={option.value}
                  >
                    <p>
                    {option.label}
                    </p>
                  </Button>
                </Grid.Row>
            )
            }
          </Grid>
        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <NextButton
          className={`bottom full-width pos-fixed btn-next-disabled ${isPlus ? 'plus' : ''}`}
          disabled={isEmpty(answer)}
          onClick={() => goNext()}
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  )
}

/* PropTypes */
ListenAndSelectTone.propTypes = {
  exercise: listenAndSelectToneShape,
  onNext:   func.isRequired,
}

ListenAndSelectTone.defaultProps = {}

/* Local utility functions */

const answerCorrect = (correctAnswer, userAnswer) => {
  return correctAnswer.length === userAnswer.length
  && userAnswer.reduce((total, current) => total && correctAnswer.includes(current), true)
}

/* Local Styled Components */

export default withRouter(ListenAndSelectTone)
