/* Global imports */
import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
// import { } from 'prop-types'

/* Local imports */
import { EXTERNAL_TEST, TRAINING_OVERVIEW } from 'Definitions/routes'
import Button from 'Components/Button'
import Text from 'Components/Text'
import Spacer from 'Components/Spacer'

/* Component definition */
const Home = ({ history }) => {
  return (
    <Grid padded>
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={8} computer={8} >
          <Image
            centered
            alt="start-img"
            src={require('Assets/images/home.svg')}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text
            as="h5"
            notr
            style={{textAlign: 'center'}}
            >Pro Akustik Hörtraining</Text>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column width={16}>
          <Text 
            as="p" 
            notr 
            style={{textAlign: 'center'}}>
            Herzlich willkommen bei unserer Hörtraining App. Hier finden Sie unser Hörtraining und unseren Schnell-Hörtest.
          </Text>
        </Grid.Column>
      </Grid.Row>
      <Spacer height="8px"/>
      <Grid.Row stretched textAlign={'center'}>
        <Grid.Column  mobile={16} tablet={16} computer={16} textAlign={'center'}>
          <Button
              className="rounded horentest bordered"
              onClick={() => history.push(EXTERNAL_TEST())}
              primary
            >
              mainMenu.externalTest
          </Button>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Button
              className="rounded horentest green"
              onClick={() => history.push(TRAINING_OVERVIEW())}
              primary
            >
              mainMenu.exercises
          </Button>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16}>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={16} computer={16}>
          <Button
              className="rounded horentest blue"
              onClick={() => history.push({ 
                pathname: TRAINING_OVERVIEW(), 
                state: { isPlus: true},
              })}
              primary
            >
              mainMenu.exercisesPlus
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
 )
}

/* PropTypes */
Home.propTypes = {}

Home.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default Home
