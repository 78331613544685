import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import firebaseConfig from '../common/config'
import { collection as getCollection, doc } from 'rxfire/firestore'
import { of, throwError } from 'rxjs'
import { first, map, switchMap } from 'rxjs/operators'
import { assoc, propIs } from 'ramda'
import { isInPast } from 'Utils'
import { isAccessCodePlus }  from 'Utils'

export let connection = undefined;
let collection;
let auth = undefined

export default {
  initFirebase: async function initFirebase() {
    if (firebase.apps.length === 0) {
      connection = await firebase.initializeApp(firebaseConfig)

      // Enable persistance for offline functionality
      firebase
      .firestore()
      .enablePersistence()
      .catch(function (err) {
        if (err.code === "failed-precondition") {
          // Multiple tabs open, persistence can only be enabled
          // in one tab at a a time.
          console.log(err);
        } else if (err.code === "unimplemented") {
          // The current browser does not support all of the
          // features required to enable persistence
          console.log(err);
        }
    })
    }

    collection = connection ? connection.firestore().collection('clients') : null
    auth =  connection ? connection.auth() : null
  },

  login: (accessCode) => {
    const key = isAccessCodePlus(accessCode) ? 'accessCodePlus' : 'accessCode'
    const checkActiveCode = isAccessCodePlus(accessCode) ? checkTrainingPlusActive : checkTrainingActive
    const checkActiveStartDate = isAccessCodePlus(accessCode) ? checkStartDatePlus : checkStartDate
    const checkActiveEndDate = isAccessCodePlus(accessCode) ? checkEndDatePlus : checkEndDate
    return getCollection(collection.where(key, '==', accessCode))
      .pipe(first())
      .pipe(map(docs => docs.map(doc => assoc('uid', doc.id, doc.data()))))
      .pipe(map(docs => docs.length < 1 ? null : docs[0]))
      .pipe(switchMap(checkActive))
      .pipe(switchMap(checkActiveCode))
      .pipe(switchMap(checkActiveStartDate))
      .pipe(switchMap(checkActiveEndDate))
  },

  getUser: (clientId) => {
    return doc(connection.firestore().collection('clients').doc(clientId))
      .pipe(map(doc => assoc('uid', doc.id, doc.data())))
  },
  
  logout: () => {
    return auth
      .signOut()
  },
  
  updateUser: (clientId, data) => {
    return connection.firestore().collection('clients')
      .doc(clientId)
      .update(data)
  }
}

const checkStartDate = user => !isInPast(user.startDate)
  ? throwError(new Error('The code provided is not yet valid.'))
  : of(user)


const checkStartDatePlus = user => !isInPast(user.startDatePlus)
  ? throwError(new Error('The code provided is not yet valid.'))
  : of(user)

const checkEndDate = user => isInPast(user.endDate)
      ? throwError(new Error('The code provided is no longer valid.'))
      : of(user)

const checkEndDatePlus = user => isInPast(user.endDatePlus)
  ? throwError(new Error('The code provided is no longer valid.'))
  : of(user)

const checkActive = user => user.active
      ? of(user)
      : throwError(new Error('The code provided is not active.'))


const checkTrainingActive = user => {
      if(propIs(Boolean, 'trainingActive', user)) {
        return user.trainingActive 
            ? of(user)
            : throwError(new Error('The code provided is not active.'))
      } else {
        return user.active
        ? of(user)
        : throwError(new Error('The code provided is not active.'))
      }
    }

const checkTrainingPlusActive = user => {
  if(propIs(Boolean, 'trainingPlusActive', user)) {
    return user.trainingPlusActive 
        ? of(user)
        : throwError(new Error('The code provided is not active.'))
  } else {
    return user.active
    ? of(user)
    : throwError(new Error('The code provided is not active.'))
  }
}
