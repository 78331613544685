/* Global imports */
import React, { useEffect, useState } from 'react'
import { func } from 'prop-types'
import { Confirm, Grid, Responsive } from 'semantic-ui-react'
import { isEmpty } from 'crocks/predicates'
import { append, path } from 'ramda'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

/* Local imports */
import I18n from 'Common/i18n'
import { listenAndPickAccentedWord } from 'Definitions/shapes'
import Button from 'Components/Button'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import ProgressBar from 'Components/ProgressBar'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import useResults from 'Hooks/useResults'
import useStages from 'Hooks/useStages'
import useSession from 'Hooks/useSession'

/* Component definition */
const ListenAndPickAccentedWord = ({ exercise, history, onNext }) => {
  const { isPlus } = useSession()
  const { currentStage, currentStep, next } = useStages(exercise, onNext)
  const choices = path(['stages', currentStage, currentStep, 'choices'], exercise)
  const correctAnswer = path(['stages', currentStage, currentStep, 'answer'], exercise)
  const [transitionModalOpen, setTransitionModalOpen] = useState(false)
  const [answer, setAnswer] = useState([])
  const { saveAnswer } = useResults()

  const setCurrentAnswer = (choice) => (e, { value }) => {
    setAnswer(
      exercise.multi
      ? answer.includes(value)
        ? answer.filter(val => val !== value)
        : append(value, answer)
      : [value]
    )
  }

  const goNext = () => {
    return saveAnswer({
      answer,
      correct:  answerCorrect(correctAnswer, answer) ? 1 : 0,
      exercise: exercise.index,
      stage:    currentStage,
      step:     currentStep,
    })
      .then((res) => {
        setAnswer([])
        next()
      })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  },[currentStep])

  useEffect(() => {
    if(currentStep === 0 && currentStage === 1) {
      setTransitionModalOpen(true)
    }
  },[currentStage, currentStep])

  return (
    <Grid padded>
      <Confirm
        className="exercise-modal"
        confirmButton=""
        cancelButton={I18n.t('exercise.buttons.next')}
        content={
          <Text as="h5" notr>
            In der folgenden Ansicht verändert sich der Satz!
          </Text>
        }
        open={transitionModalOpen}
        onCancel={() => setTransitionModalOpen(false)}
      />
        <Grid.Row className="relaxed">
          <Grid.Column width={16}>
            <Text as="h4" className={isPlus ? 'plusText' : 'primaryText'} notr>Übung { exercise.index + 1 }</Text>
            <Separator minWidth={768}/>
          </Grid.Column>
          <Spacer height="16px"/>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <div>
              <Text as="h5" notr>{ exercise.title }</Text>
            </div>
            <Spacer height="30px"/>
            <Text className="exercise-text" notr dangerouslySetInnerHTML={{__html: exercise.description[currentStage]}}></Text>
            <Spacer height="16px"/>
            <Grid>
              <Grid.Row>
                <Grid.Column mobile={16} computer={8}>
                  <PlayButton
                    allowedReplays={2}
                    autoplay={currentStep > 0 ? 1 : 0}
                    className="full-width audio-btn"
                    exercise={exercise}
                    scrollOnLoad
                    scrollOnPlay
                    stage={currentStage}
                    step={currentStep}
                  />
                   <ProgressBar exercise={exercise} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Responsive maxWidth={768}>
            <Spacer height="20px" />
          </Responsive>
          <div>
            <Text as="h5">exercise.selectOption</Text>
          </div>
          <Spacer height="30px"/>
          <SGrid padded>
            {
              choices.map((choice, index) =>
                  <SButton
                    className="main-font font22 wordBlue"
                    key={index}
                    notr
                    onClick={setCurrentAnswer(choice)}
                    secondary={answer.includes(index)}
                    toggle
                    value={index}
                  >
                    <p>{choice}</p>
                  </SButton>
              )
            }
          </SGrid>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <NextButton
          className={`bottom full-width pos-fixed btn-next-disabled ${isPlus ? 'plus' : ''}`}
          disabled={isEmpty(answer)}
          onClick={() => goNext()}
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  )
}

/* PropTypes */
ListenAndPickAccentedWord.propTypes = {
  exercise: listenAndPickAccentedWord,
  onNext: func.isRequired,
}

ListenAndPickAccentedWord.defaultProps = {}

/* Local utility functions */
const answerCorrect = (correctAnswer, userAnswer) => {
  return correctAnswer.length === userAnswer.length
  && userAnswer.reduce((total, current) => total && correctAnswer.includes(current), true)
}

/* Local Styled Components */
const SGrid = styled(Grid)`
  justify-content: center;

  button {
    margin: 4px !important;
  }
`

const SButton = styled(Button)`
  align-items:     center;
  display:         flex !important;
  justify-content: flex-start;

  padding:         2px!important;
  border:          none!important;
`

export default withRouter(ListenAndPickAccentedWord)
