import { connection } from 'Services/user'

export async function getLatestWebAppVersion() {
  let webAppVersionDocRef;

  if (connection) {
    webAppVersionDocRef = connection.firestore().collection("appVersion").doc("webApp");
    try {
      const webAppVersionDoc = await webAppVersionDocRef.get();
      if (webAppVersionDoc.exists) {
        return webAppVersionDoc.data().lastVersion;
      } else {
        throw new Error("Error: No app version found on firebase");
      }
    } catch (error) {
      console.error(error)
    }
  }
}
