/* Global imports */
import React, { useEffect, useState, useCallback } from 'react'
import { fromPairs, map, pipe } from 'ramda'

/* Local imports */
import Input from 'Components/Input'

const FIELDS = ['day', 'month', 'year', 'hour', 'minute']

const fromArray = pipe(
  map(x => [x, '']),
  fromPairs,
)

const getInitialState = length => fromArray(FIELDS.slice(0, length))

const useObjectState = (initialState) => {
  const [data, _setData] = useState(initialState)

  const setData = useCallback(newData => {
    _setData({
      ...data,
      ...newData,
    })
  },[data])

  return [data, setData]
}

const getValue = (fields, data) => fields.reduce((total, current, index) => {
  if (index === (fields.length - 1)) {
    return total + data[current]
  }
  return  total + data[current] + '/'
}, '')


/* Component definition */
const DateInput = (props) => {
  const { format, onChange, value } = props
  const fields = FIELDS.slice(0, format.split('/').length)
  const [data, setData] = useObjectState(getInitialState(fields.length))

  useEffect(() => {
    onChange(
      null,
      { value: isValid(fields, data) ? getValue(fields, data) : null }
    )
  }, [data, onChange, fields, setData])

  useEffect(() => {
    if (value === '') {
      setData(getInitialState(fields.length))
    }
  }, [value, setData, fields.length])

  return renderDate(props, data, setData)
}

/* PropTypes */
DateInput.propTypes = {}

DateInput.defaultProps = {}

/* Local utility functions */
const renderDate = (props, data, setData) => {
  const { format } = props

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {
        FIELDS.slice(0, format.split('/').length)
          .map(item => (
            <Input
              {...props}
              key={item}
              placeholder={`exercise.placeholder.${item}`}
              onChange={(e, { value }) => setData({ [item]: value })}
              value={data[item]}
              inputType="number"
            />
          ))
      }
    </div>
  )
}

const isValid = (fields, data) => {
  if (fields.length === 5) {
    return !!data.day && !!data.month && !!data.year && !!data.hour && !!data.minute
  }

  return  !!data.day && !!data.month && !!data.year
}

/* Local Styled Components */

export default DateInput
