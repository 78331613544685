/* Global imports */
import React, { useEffect, useState } from 'react'
import { Grid, Image, Responsive } from 'semantic-ui-react'
import {
  addIndex,
  adjust,
  any,
  assoc,
  cond,
  equals,
  map,
  splitEvery,
  zipWith
} from 'ramda'
import { isArray, isNumber, isFunction } from 'crocks/predicates'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

/* Local imports */
import useSteps from 'Hooks/useSteps'
import Text from 'Components/Text'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Input from 'Components/Input'

const screens = {
  EXERCISE: 'exercise',
  INTRO:    'intro',
  RESULTS:  'results',
}

/* Component definition */
const ListenAndSort = ({ exercise, onNext }) => {
  const [currentScreen, setCurrentScreen] = useState(!!exercise.intro ? screens.INTRO : screens.EXERCISE)
  const [currentIndex, setCurrentIndex] = useState(0)
  const { currentStep, next } = useSteps(exercise, onNext)
  const [answerArray, setAnswerArray] = useState(getAnswerObject(exercise, currentStep))
  const [audioPlaying, setAudioPlaying] = useState(true)

  const onBoxClick = (o) => {
    if (!isNumber(o.answer)) {
      setAnswerArray(
        adjust(o.index, assoc('answer', currentIndex), answerArray)
      )

      setCurrentIndex(currentIndex + 1)
    }
  }

  const goNext = () => {
    switch (currentScreen) {
      case screens.INTRO:
        return setCurrentScreen(screens.EXERCISE)
      default:
        setCurrentIndex(0)
        return next()
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [currentStep])

  useEffect(() => {
    setAnswerArray(getAnswerObject(exercise, currentStep))
    setCurrentScreen(screens.INTRO)
  }, [exercise, currentStep])

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4" notr>Übung {exercise.number}</Text>
          <Separator minWidth={768} />
        </Grid.Column>
        <Spacer height="16px" />
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Text as="h5" notr>{exercise.title}</Text>
          <Text className="exercise-text" notr>
          {
            currentScreen === screens.INTRO ? exercise.description[0] :  exercise.description[1]
          }
          </Text>
          <Spacer height="16px" />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={currentStep > 0 ? 1 : 0}
                  className="full-width audio-btn"
                  exercise={exercise}
                  scrollOnLoad
                  audiostopped={() => setAudioPlaying(false)}
                  scrollOnPlay={ currentStep === 0 }
                  step={currentScreen === screens.INTRO ? 0 : 1}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Responsive minWidth={768}>
            <Text as="h5">exercise.selectOption</Text>
            <Spacer height="24px" />
          </Responsive>
          <Grid>
            {
              splitEvery(2, answerArray).map(
                (o, index) =>
                  <Grid.Row
                    centered
                    columns={2}
                    key={index}
                    textAlign="center"
                  >
                    {
                      o.map(
                        cond([
                          [equals(screens.INTRO), () => renderIntroColumn],
                          [() => true, () => renderColumn(onBoxClick)],
                        ])(currentScreen)
                      )
                    }
                  </Grid.Row>
              )
            }
          </Grid>

        </Grid.Column>
      </Grid.Row>

      <Grid.Row>
        <NextButton
          className="bottom full-width pos-fixed btn-next-disabled"
          disabled={(currentScreen === screens.EXERCISE && any(({ answer }) => answer === '', answerArray)) || audioPlaying}
          onClick={goNext}
        >
          exercise.buttons.next
      </NextButton>
      </Grid.Row>
      <Spacer height="48px" />
    </Grid>
  )
}

/* PropTypes */
ListenAndSort.propTypes = {}

ListenAndSort.defaultProps = {}

/* Local utility functions */
const mapIndexed = addIndex(map)

const getAnswerObject = (exercise, step) => {
  const answer = exercise.steps[step].answer
  const propName = isArray(exercise.icons) ? 'icon' : 'label'
  const items = exercise[`${propName}s`]
  const answerObjects = zipWith((x, y) => ({ correctAnswer: x, [propName]: y }), answer, items)

  return mapIndexed((el, index) => ({ ...el, answer: '', index }), answerObjects)
}

const renderIconOrLabel = (o, onClick) => {
  if (o.icon) {
    return <Image
      className="icon-img"
      alt={o.icon}
      inline
      onClick={onClick}
      src={require(`Assets/icons/negativ/${o.icon}.png`)}
      style={{
        borderRadius: '50%',
        cursor: isFunction(onClick) ? 'pointer' : 'unset',
        marginLeft: '5px',
        width: '80px',
      }}
    />
  }

  return <span
    onClick={onClick}
    style={{
      color: isNumber(o.answer) ? '#0082BA' : null,
      marginLeft: '5px',
    }}
  >
    {o.label}
  </span>
}

const renderColumn = onBoxClick =>
  (o, index) => <Grid.Column
    key={index}
    width={8}
  >
    <Grid.Row>
      <ItemsWrapper style={{ cursor: 'pointer' }} icon={o.icon}>
        <ExerciseInput
          color={isNumber(o.answer) ? '#0082BA' : null}
          onClick={() => onBoxClick(o)}
          readOnly
          style={{ borderColor: isNumber(o.answer) && '#0082BA' }}
          textcolor={isNumber(o.answer) ? '#ffffff' : null}
          value={o.answer !== '' ? o.answer + 1 : ''}
        />
        {renderIconOrLabel(o, () => onBoxClick(o))}
      </ItemsWrapper>
    </Grid.Row>
  </Grid.Column>

const renderIntroColumn =
  (o, index) => <Grid.Column
    key={index}
    width={8}
  >
    <ItemsWrapper icon={o.icon}>
      <ExerciseInput
        textcolor="#D8D8D8"
        readOnly
        value={o.index + 1} />
      {renderIconOrLabel(o)}
    </ItemsWrapper>
  </Grid.Column>

/* Local Styled Components */
const ExerciseInput = styled(Input)`
  background-color: ${({ color }) => color ? color : '#ffffff'} !important;
  border-radius:    40px !important;
  color:            ${({ textcolor }) => textcolor ? textcolor : null} !important;
  cursor:           pointer;
  font-size:        24px !important;
  font-weight:      bold;
  height:           55px !important;
  letter-spacing:   0;
  line-height:      32px !important;
  text-align:       center !important;
  width:            55px !important;
  
  input {
    padding-top:      16px !important;
    padding-right:    12px !important;
    marggin-top:       6px !important;
  }
`

const ItemsWrapper = styled.div`
    display: flex !important;
    justify-content: ${({ icon }) => icon ? 'center' : 'flex-start'} !important;
    align-items: center !important;
`
export default withRouter(ListenAndSort)
