/* Global imports */
import React from 'react'
import { array, bool, object, oneOfType, number, string } from 'prop-types'
import { isFunction } from 'crocks/predicates'

/* Local imports */
import I18n from 'Common/i18n'

/* Component definition */
export const Text = ({ as: As, notr, children: value, vars, ...props }) => {
  return (
    <As style={getStyles(props.onClick)} {...props}>
      { getValue(!notr, value, vars) }
    </As>
  )
}
/* PropTypes */
Text.propTypes = {
  as:       string,
  children: oneOfType([array, object, number, string]),
  notr:     bool,
}

Text.defaultProps = {
  as:   'span',
  notr: false,
}

/* Local utility functions */
export const getValue = (shouldTranslate, value, vars) => {

  // TODO: Allow variables
  return shouldTranslate ? I18n.t(value, vars) : value
}

const getStyles = onClick => {
  if (isFunction(onClick)) {
    return {
      cursor: 'pointer',
    }
  }

  return {}
}

export default Text
