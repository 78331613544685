/* Global imports */
import React, { useState }from 'react'
import styled from 'styled-components'
// import { } from 'prop-types'
import { Grid, Image } from 'semantic-ui-react'

/* Local imports */
import { OPTIONAL_EXERCISE, EXERCISE } from 'Definitions/routes'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import useSession from 'Hooks/useSession'

/* Component definition */
const AudioAdjust = ({ history, match, location: { state } }) => {
  const { isPlus } = useSession()
  const { exerciseIndex, dayIndex } = match.params
  const [ audioPlayed, setAudioPlayed ] = useState(false)
  const [optionalExercise] = useState(state && state.optional ? true : false)

  return (
    <Grid padded>
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Image
            alt="AudioAdjust-img"
            centered
            style={{ paddingBottom: "32px", minHeight: '200px'}}
            src={require('Assets/images/audio.svg')}
            />
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <SWrapper>
            <PlayButton
              className="full-width audio-btn"
              notExerciseAudio
              onPlayAudio={() => setAudioPlayed(true)}
              scrollOnPlay
              scrollOnLoad
            />
            <Text className="audio-adjust-title" as="h5" notr>Trainingslautstärke wählen</Text>
            <div>
              Damit Sie Ihr Hörtraining optimal nutzen können, sollten die
              Tonbeispiele der Übungen eine Lautstärke haben, die für Sie angenehm ist.
              <br/>
              <br/>
              Wichtig ist, dass Sie diese Lautstärke bei allen Übungen beibehalten.
              <br/>
              <br/>
              Um die richtige Lautstärke zu ermitteln spielen Sie das folgende Beispiel ab.
            </div>
          </SWrapper>
        </Grid.Column>
      </Grid.Row>
      <Spacer height="48px"/>
      <Grid.Row>
        <NextButton
          className={`bottom full-width pos-fixed btn-next-disabled ${isPlus ? 'plus' : ''} `}
          disabled={!audioPlayed}
          onClick={() => history.push({ 
            pathname: optionalExercise ? OPTIONAL_EXERCISE(dayIndex, exerciseIndex) : EXERCISE(dayIndex, exerciseIndex), 
            state: { audioAdjusted: true },
          })}
          >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
    </Grid>
  )
}

/* PropTypes */
AudioAdjust.propTypes = {}

AudioAdjust.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SWrapper = styled.div`
  @media (min-width: 768px) {
    margin: 80px auto;
}
`
export default AudioAdjust
