/* Global imports */
import React, { useState, useEffect } from "react";
import { func } from "prop-types";
import {
  Grid,
  Label,
  Responsive,
  Input as SemanticInput,
} from "semantic-ui-react";
import { eqBy, path } from "ramda";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

/* Local imports */
import { listenAndTypeShape } from "Definitions/shapes";
import InsertHearingAids from "Components/InsertHearingAids";
import NextButton from "Components/NextButton";
import PlayButton from "Components/PlayButton";
import Separator from "Components/Separator";
import Spacer from "Components/Spacer";
import Input from "Components/Input";
import NumberInput from "Components/NumberInput";
import Text from "Components/Text";
import ProgressBar from "Components/ProgressBar";
import useResults from "Hooks/useResults";
import useStages from "Hooks/useStages";

import { processDate, processNumber, processString } from "Utils";
import DateInput from "./DateInput";

import useSession from "Hooks/useSession";

const getInitialState = (exercise) => {
  return exercise.multi ? [] : "";
};
const handleReset = () => {
  Array.from(document.querySelectorAll("input")).forEach(
    (input) => (input.value = "")
  );
};

function arrayEquals(a, b) {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val, index) => val === b[index])
  );
}

/* Component definition */
const ListenAndType = ({ exercise, onNext, optional }) => {
  const { isPlus } = useSession();
  const [answer, setAnswer] = useState(getInitialState(exercise));
  const [insertScreenDisplayed, setInsertScreenDisplayed] = useState(false);
  const [lastStepDisplayed, setLastStepDisplayed] = useState(false);
  const { currentStage, currentStep, next } = useStages(
    exercise,
    onNext,
    optional
  );
  const { saveAnswer } = useResults();
  const correctAnswer = path(
    ["stages", currentStage, currentStep, "answer"],
    exercise
  );

  const step = exercise.stages[currentStage][currentStep];
  const hasIntermediateScreens = exercise && exercise.intermediateScreens;

  const lastStepCompleted =
    hasIntermediateScreens &&
    !insertScreenDisplayed &&
    currentStage === 1 &&
    currentStep === 9;
  const goNext = () => {
    saveAnswer({
      answer: exercise.multi ? answer : answer.replace(".", ","),
      correct: answerCorrect(
        exercise,
        correctAnswer,
        answer,
        exercise.inputType
      )
        ? 1
        : 0,
      exercise: optional
        ? `-optionalExercise-${exercise.index}`
        : exercise.index,
      stage: currentStage,
      step: currentStep,
    }).then((res) => {
      setAnswer(getInitialState(exercise));
      handleReset();
      next();
    });
  };

  const setMultiAnswer = (event, prevState, position) => {
    let newState = [...prevState];
    newState[position] = event && event.target && event.target.value;
    setAnswer(newState);
  };

  const handleKeyDown = (step) => (event) => {
    var key = window.event ? event.keyCode : event.which;
    if (
      event.keyCode === 8 ||
      event.keyCode === 46 ||
      event.keyCode === 37 ||
      event.keyCode === 39
    ) {
    } else if (key < 48 || key > 57) {
      event.preventDefault();
    } else if (event.currentTarget.value.length >= (step.maxlength || exercise.maxlength)) {
      event.preventDefault();
    } else return true;
  };

  const setCommaAnswer = (event, prevState, position) => {
    let newState = [...prevState];

    if (prevState.indexOf(",") === -1 && prevState === "") {
      if (position === 0) {
        newState = event && event.target && event.target.value + ",";
      } else {
        newState = event && event.target && "," + event.target.value;
      }
    } else {
      newState = prevState.split(",");
      newState[position] = event.target.value;
      newState = newState.join();
    }
    setAnswer(newState);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 1) {
      setInsertScreenDisplayed(false);
    }
  }, [currentStep]);
  useEffect(() => {
    if (currentStep === 1) {
      setInsertScreenDisplayed(false);
    }
  }, [currentStep]);

  if (
    hasIntermediateScreens &&
    !insertScreenDisplayed &&
    (currentStage === 0) | 1 &&
    currentStep === 0
  ) {
    const message =
      currentStage === 0
        ? "Bitte führen Sie die folgende Übung mit Ihrem Hörsystem durch."
        : "Bitte führen Sie die folgende Übung ohne Ihr Hörsystem durch.";

    return (
      <InsertHearingAids
        onNext={() => setInsertScreenDisplayed(true)}
        message={message}
      />
    );
  }

  if (
    hasIntermediateScreens &&
    lastStepDisplayed &&
    currentStage === 1 &&
    currentStep === 9
  ) {
    const message =
      "Bitte führen Sie die folgende Übung mit Ihrem Hörsystem durch.";

    return (
      <InsertHearingAids
        lastStepDisplayed={lastStepDisplayed}
        onNext={() => setInsertScreenDisplayed(true)}
        message={message}
        goNext={goNext}
      />
    );
  }

  return (
    <Grid padded>
      <Grid.Row className="relaxed">
        <Grid.Column width={16}>
          <Text as="h4" className={isPlus ? "plusText" : "primaryText"} notr>
            {optional
              ? `Zusatzübung  ${exercise.letter}`
              : `Übung ${exercise.index + 1}`}
          </Text>
          <Separator minWidth={992} />
        </Grid.Column>
        <Spacer height="16px" />
        <Grid.Column mobile={16} computer={8}>
          <div>
            <Text as="h5" notr>
              {exercise.title}
            </Text>
          </div>
          <Spacer height="30px" />
          <Text
            className="exercise-text"
            notr
            dangerouslySetInnerHTML={{ __html: exercise.description }}
          ></Text>
          <Spacer height="16px" />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={currentStep > 0 ? 1 : 0}
                  className="full-width"
                  exercise={exercise}
                  scrollOnLoad
                  scrollOnPlay
                  stage={currentStage}
                  step={currentStep}
                  optional={optional}
                />
                <ProgressBar exercise={exercise} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8}>
          <Responsive maxWidth={768}>
            <Spacer height="20px" />
          </Responsive>
          <div>
            <Text as="h5">exercise.selectOption</Text>
          </div>
          <Spacer height="30px" />
          <Grid.Row>
            {exercise.multi
              ? renderInputs({
                  exercise,
                  step,
                  answer,
                  setMultiAnswer,
                })
              : renderInput({
                  exercise,
                  step,
                  answer,
                  onChange: (e, { value }) => setAnswer((prevState) => value),
                  setCommaAnswer,
                  handleKeyDown,
                })}
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <NextButton
          className={`bottom full-width pos-fixed btn-next-disabled ${
            isPlus ? "plus" : ""
          }`}
          disabled={isEmpty(answer, exercise, step)}
          onClick={() =>
            !lastStepCompleted ? goNext() : setLastStepDisplayed(true)
          }
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  );
};

/* PropTypes */
ListenAndType.propTypes = {
  exercise: listenAndTypeShape,
  onNext: func.isRequired,
};

ListenAndType.defaultProps = {};

/* Local utility functions */
const isEmpty = (answer, { type }, { multi }) => {
  if (multi) {
    if (answer.length < 2) {
      return true;
    }
    for (var i = 0; i < answer.length; i++) {
      if (answer[i] === "") return true;
    }
    return false;
  }
  return type === "number"
    ? answer
        .replace(".", "")
        .split("")
        .every((c) => c === "0")
    : !answer;
};

const renderInput = ({
  exercise,
  step,
  answer,
  onChange,
  setCommaAnswer,
  handleKeyDown,
}) => {
  switch (exercise.inputType) {
    case "number":
      if (exercise.commaSeparated) {
        return (
          <SSemanticInput className="" labelPosition="left">
            {step.prefix && (
              <Label className="input-label" basic>
                {step.prefix}
              </Label>
            )}
            <input
              type={exercise.inputType}
              pattern={exercise.inputType === "number" ? "[0-9]*" : null}
              style={{ marginBottom: "5px" }}
              className="numericInput"
              onChange={(e) => setCommaAnswer(e, answer, 0)}
              onKeyDown={handleKeyDown(step)}
            />
            <Label className="input-label" basic>
              ,
            </Label>
            <input
              type={exercise.inputType}
              pattern={exercise.inputType === "number" ? "[0-9]*" : null}
              style={{ marginBottom: "5px" }}
              className="numericInput"
              onChange={(e) => setCommaAnswer(e, answer, 1)}
              onKeyDown={handleKeyDown(step)}
            />
            {step.sufix && (
              <Label className="input-label" basic>
                {step.sufix}
              </Label>
            )}
          </SSemanticInput>
        );
      } else {
        return (
          <NumberInput
            mask={step.answer}
            className="numeric increase"
            labelClass="input-label"
            onChange={onChange}
            prefix={step.prefix}
            sufix={step.sufix}
            value={answer}
          />
        );
      }
    case "date":
      return (
        <DateInput
          format={step.answer}
          className="full-width increase"
          labelClass="input-label"
          onChange={onChange}
          placeholder="exercise.placeholder.answer"
          value={answer}
        />
      );
    default:
      return (
        <Input
          className="full-width increase"
          labelClass="input-label increase"
          onChange={onChange}
          placeholder="exercise.placeholder.answer"
          prefix={step.prefix}
          sufix={step.sufix}
          separator={step.separator}
          multi={exercise.multi}
          value={answer}
        />
      );
  }
};

const renderInputs = ({ exercise, step, answer, setMultiAnswer }) => {
  return (
    <SSemanticInput className="full-width increase" labelPosition="left">
      {step.prefix && (
        <Label className="input-label" basic>
          {step.prefix}
        </Label>
      )}
      <input
        type={exercise.inputType}
        pattern={exercise.inputType === "number" ? "[0-9]*" : null}
        style={{ marginBottom: "5px" }}
        className="small-input increase"
        onChange={(e) => setMultiAnswer(e, answer, 0)}
      />
      {step.separator && (
        <Label className="input-label" basic>
          {step.separator}
        </Label>
      )}
      {step.multi && (
        <input
          type={exercise.inputType}
          pattern={exercise.inputType === "number" ? "[0-9]*" : null}
          style={{ marginBottom: "5px" }}
          className="small-input increase"
          onChange={(e) => setMultiAnswer(e, answer, 1)}
        />
      )}
      {step.sufix && (
        <Label className="input-label" basic>
          {step.sufix}
        </Label>
      )}
    </SSemanticInput>
  );
};

const answerCorrect = (exercise, correctAnswer, answer, inputType) => {
  const comparator =
    inputType === "number"
      ? processNumber
      : inputType === "date"
      ? processDate
      : processString;

  if (exercise.multi) {
    return arrayEquals(
      answer.map((answer) => answer.toLowerCase()),
      correctAnswer.map((correctAnswer) => correctAnswer.toLowerCase())
    );
  }

  return eqBy(comparator, answer, correctAnswer);
};

/* Local Styled Components */
const SSemanticInput = styled(SemanticInput)`
  display: flex;
  flex-wrap: wrap;
`;

export default withRouter(ListenAndType);
