/* Global imports */
import React, { useState } from 'react'
import { Confirm, Grid, TextArea } from 'semantic-ui-react'
import { isEmpty } from 'ramda'
import styled from 'styled-components'
import { assign } from 'crocks/helpers'

/* Local imports */
import { TRAINING_DAY } from 'Definitions/routes'
import useSteps from 'Hooks/useSteps'
import useResults from 'Hooks/useResults'
import Text from 'Components/Text'
import NextButton from 'Components/NextButton'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import SuccessMessage from 'Components/SuccessMessage'
import Button from 'Components/Button'
import CancelButton from 'Components/CancelButton'
import exercise from '../Exercises/REVIEW_EXERCISE'
import I18n from 'Common/i18n'

const initialAnswerState = {
  button:   '',
  comments: '',
}

/* Component definition */
const ReviewExercise = ({ history, match }) => {
  const [isIntro, setIsIntro] = useState(true)
  const [isCompleted, setIsCompleted] = useState(false)
  const [cancelModalOpen, setCancelModalOpen] = useState(false)
  const [answer, setAnswer] = useState(initialAnswerState)
  const { currentStep, next } = useSteps(exercise, () => setIsCompleted(true))
  const { saveAnswer } = useResults()
  const {  dayIndex } = match.params

  const goNext = () => {
    if(isIntro) {
      return setIsIntro(false)
    }

    saveAnswer({
        answer,
        exercise: `-reviewExercise-${parseInt(dayIndex) +1}`,
        step:     currentStep,
      })

    next()
    setAnswer(initialAnswerState)
  }

  const buttons = [
    {
      text: 'exercise.buttons.veryUnusual',
      value: 5,
    },
    {
      text: 'exercise.buttons.unusual',
      value: 4,
    },
    {
      text: 'exercise.buttons.neutral',
      value: 3,
    },
    {
      text: 'exercise.buttons.usual',
      value: 2,
    },
    {
      text: 'exercise.buttons.veryUsual',
      value: 1,
    },
  ]


  if(isCompleted) {
    return (
      <SuccessMessage history={history} match={match}/>
     )
  }

  return (
<Grid padded>
<Confirm
        cancelButton={I18n.t('exercise.buttons.cancel.title')}
        confirmButton={I18n.t('exercise.buttons.confirmation')}
        content={
          <Text as="h5">
            exercise.buttons.cancel.confirmationText
          </Text>
        }
        open={cancelModalOpen}
        onCancel={() => setCancelModalOpen(false)}
        onConfirm={() => history.push(TRAINING_DAY(dayIndex))}
      />
      <CancelButton
        onClick={() => setCancelModalOpen(true)}
      />
      {
      isIntro ?
        <>
          <Grid.Row>
          <Spacer height="64px"/>
            <Grid.Column width={16}>
              <Text as="h4" notr>{ exercise.title }</Text>
              <Separator minWidth={0}/>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={16}>
              <Text notr>{ exercise.description }</Text>
              <Spacer height="32px"/>
              <SIntroButton
                className="full-width rounded"
                onClick={goNext}
              >
                exercise.buttons.next
              </SIntroButton>
            </Grid.Column>
          </Grid.Row>
        </>
        :
        <>
          <Grid.Row>
            <Grid.Column width={16}>
              <Text as="h4" notr>{ exercise.steps[currentStep].title }</Text>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column mobile={16} computer={8}>
              <Text as="p" notr>{ exercise.steps[currentStep].description }</Text>
            </Grid.Column>
            <Grid.Column mobile={16} computer={8}>
              <Spacer height="8px"/>
              <Text as="h6" notr>Das Geräusch klingt:</Text>
              <Spacer height="8px"/>
              <Column>
                {
                  buttons.map(({ text, value }) => <SButton
                                                     active={answer.button === value}
                                                     key={value}
                                                     onClick={() => setAnswer(assign({ button: value }))}
                                                     secondary={answer.button === value}
                                                     notr
                                                     >
                                                      <Text as="p">{ text }</Text>
                                                    </SButton>)
                }
              </Column>
              <Spacer height="8px"/>
              <Grid.Row>
                <Text className="caption font-weight-soft" notr>KOMMENTAR</Text>
                <STextArea 
                  onChange={(e, { value }) => setAnswer(assign({ comments: value }))}
                  placeholder="Anmerkungen" 
                  value={answer.comments}
                />
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
              <Spacer height="64px"/>
          <NextButton
            className="bottom full-width pos-fixed btn-next-disabled"
            disabled={isEmpty(answer.button)}
            onClick={goNext}
          >
            exercise.buttons.next
          </NextButton>
      </>
      }
    </Grid>
  )
}

/* PropTypes */
ReviewExercise.propTypes = {
}

ReviewExercise.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SIntroButton = styled(NextButton)`
@media (min-width: 768px) {
  height:    44px !important;
  max-width: 280px !important
}
`

const SButton = styled(Button)`
  color: black !important;
  font-family: OfficinaSansITCPro-Book !important;
  text-align:       center !important;
  width:            300px !important;
`

const STextArea= styled(TextArea)`
background-color: #F4F4F4;
border: none;
border-radius: 4px;
font-family: OfficinaSansITCPro-Book;
height: 300px;
letter-spacing: 1.5px !important;
line-height: 21px;
margin: 0px;
padding: 12px 16px;
width: 100%;
`

const Column = styled.div`
  display:        flex;
  flex-direction: column;
  margin-bottom:  10px;
  align-items: center;

  button {
    margin: 4px 0 !important;
  }
`

export default ReviewExercise
