import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
body, p {
  font-size: ${({ fontSizeFactor }) => fontSizeFactor * 16 }px!important;
  font-weight: 300 !important;	
  letter-spacing: 1.5px !important;
  line-height: ${({ fontSizeFactor }) => fontSizeFactor * 21 }px!important;;
}

.increase {
  font-size: ${({ fontSizeFactor }) => fontSizeFactor * 16 }px!important;;
}

.no-increase {
  font-size: 16px !important;
}

h1,h2,h3,h4,h5, h6 {
  font-family: OfficinaSansITCPro-Bold !important;
}

h4 {
  color: #0082BA !important;
  font-size: 32px !important;
  font-weight: bold !important;
  letter-spacing: 0 !important;
  line-height: 42px !important;
  margin-bottom: 8px !important;
}

h5 {
  font-family: OfficinaSansITCPro-Bold !important;
  color: #000;
  font-size: 24px !important;
  font-weight: bold !important;
  line-height: 32px !important;
  letter-spacing: 0 !important;
}
 h6 {
   font-size: 20px !important;
   font-weight: bold !important;
   letter-spacing:0 !important;
   line-height: 24px !important;
   margin: 0px !important;
   padding-top:0px !important;
 }

 .caption {
  color: #000000;
  font-size: ${({ fontSizeFactor }) => fontSizeFactor * 12 }px !important;
  font-weight: bold;
  height: 16px;
  letter-spacing: 1.5px;
  line-height: 16px;
  text-transform: uppercase;
}

.caption-blue {
  color: #0082BA !important;
  font-size: ${({ fontSizeFactor }) => fontSizeFactor * 16 }px !important;
  font-weight: bold;
  height: 21px;
  letter-spacing: 1.5px;
  line-height: 21px;
}

.footer {
  background-color: #0082BA !important;
  color: white;
  font-size: ${({ fontSizeFactor }) => fontSizeFactor * 12 }px !important;
  font-weight: bold;
  height: 36px !important;
  letter-spacing: 1.5px;
  line-height: 16px;
  padding: 10px  0px!important;
  text-align: center !important;
  width: 100% !important;
}

.footer.plus {
  background-color: #v !important;
}

.primaryText {
  color: #41B094 !important;
  
}

.plusText {
  color: #0082BA !important;
}

.plusImg {
  filter: invert(31%) sepia(90%) saturate(4746%) hue-rotate(181deg) brightness(99%) contrast(101%);
}

.exercise-text {
  white-space: pre-line;
}

.exercise-text > b {
  font-family: OfficinaSansITCPro-Bold;
}

p > b {
  font-family: OfficinaSansITCPro-Bold;
}

.small-input {
  width: 30%
}
`
export default GlobalStyle