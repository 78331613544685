import React from 'react'
import ReactDOM from 'react-dom'
import firebase from 'firebase/app'
import 'firebase/analytics';
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { service } from './hooks/useLoading'

export const initializeFirebaseAnalytics = () => {
  return firebase.analytics()
}

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onError() {
    service.send('DONE')
  },
  onInstalling() {
    service.send('START')
  },
  onSuccess() {
    service.send('DONE')
  },
  onUpdate() {
    service.send('DONE')
  }
})
