import userService from 'Services/user'
import useSession from 'Hooks/useSession'
import { isAnswered, setAnswer, setTempAnswer, addRepetition } from 'Utils'
import { format } from 'date-fns'
import { assocPath } from 'ramda'

const DATE_FORMAT = 'yyyy-MM-dd HH:mm'

export default function() {
  const { user, isPlus } = useSession()

  if (!user) {
    return {
      results: {},
      saveAnswer: () => null
    }
  }

  const saveAnswer = (answerData) => {
    if (!isAnswered(user, answerData, isPlus)) {
      const { answers, answersPlus } = setAnswer(user, answerData, isPlus)

      userService.updateUser(user.uid, isPlus ? {
        answersPlus,
        lastActivity: format(new Date(), DATE_FORMAT)
      } : {
        answers,
        lastActivity: format(new Date(), DATE_FORMAT)
      })
    } else {
      const { answersTemp, answersPlusTemp } = setTempAnswer(user, answerData, isPlus)

      userService.updateUser(user.uid, isPlus ? {
        answersPlusTemp,
        lastActivity: format(new Date(), DATE_FORMAT)
      } : {
        answersTemp,
        lastActivity: format(new Date(), DATE_FORMAT)
      })
    }

    return Promise.resolve()
  }

  const updateRepetitions = (exercise) => {
    const { answers, answersPlus } = addRepetition(user, exercise, isPlus)

    userService.updateUser(user.uid, isPlus ? {
      answersPlus,
    }: {
      answers,
    })
  }

  const saveComment = ({ day, comment: newComment}) => {
    const { comments } = assocPath(['comments', `day${day}`], newComment, user)

    return userService.updateUser(user.uid, {
      comments,
      lastActivity: format(new Date(), DATE_FORMAT)
    })
  }

  const resetTemp = () => {
    userService.updateUser(user.uid, {
      answersTemp: {},
      answersPlusTemp: {}
    })
  }

  return {
    results: isPlus ? user.answersPlus : user.answers,
    temporaryResults: isPlus ? user.answersPlusTemp : user.answersTemp,
    saveAnswer,
    saveComment,
    resetTemp,
    updateRepetitions
  }
}
