/* Global imports */
import React, { useEffect, useState } from 'react'
import { Grid, TextArea } from 'semantic-ui-react'
import { isEmpty } from 'ramda'
import styled from 'styled-components'

/* Local imports */
import { TRAINING_DAY } from 'Definitions/routes'
import useResults from 'Hooks/useResults'
import useSession from 'Hooks/useSession'
import Text from 'Components/Text'
import NextButton from 'Components/NextButton'
import Spacer from 'Components/Spacer'

/* Component definition */
const Comment = ({ dayIndex, history, setWriteComment }) => {
  const { user, isPlus } = useSession()
  const [comment, setComment] = useState()
  const [answered, setAnswered] = useState(false)
  const { saveComment } = useResults()

  const goNext = () => {
    if (!answered) {
      saveComment({
          day: parseInt(dayIndex) + 1,
          comment,
        })
      setComment('')
      setAnswered(true)
    } else {
      if(setWriteComment) {
        setWriteComment(false)
      }
      history.push(TRAINING_DAY(dayIndex))
    }
  }

  useEffect(() => {
    const dayNumber = parseInt(dayIndex) +1 

    if (user && user.comments && user.comments[`day${dayNumber}`]) {
      setComment(user.comments[`day${dayNumber}`])
    }
  },[dayIndex, user])

  return (
    <Grid padded>
      <Spacer height="64px"/>
      <Grid.Row>
        <Grid.Column>
          {
            answered ?
            <STextWrapper>
              <Text as="h5" style={{ color: '#0082BA' }}>comment.feedback</Text>
            </STextWrapper>
            :
            <>
            <Text className="caption font-weight-soft" notr>KOMMENTAR</Text>
            <STextArea 
              onChange={(e, { value }) => setComment(value)}
              placeholder="Anmerkungen" 
              value={comment}
            />
            </>
          }
        </Grid.Column>
      </Grid.Row>
      <Spacer height="64px"/>
      <NextButton
      className={`bottom full-width pos-fixed btn-next-disabled ${isPlus ? 'blue' : 'green'}`}
      disabled={isEmpty(comment) && !answered}
      onClick={goNext}
      >
      {
      answered ?
      'exercise.success.trainingDay'
      :
      'exercise.buttons.next'
      }
      </NextButton>
    </Grid>
  )
}

/* PropTypes */
Comment.propTypes = {
}

Comment.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const STextArea= styled(TextArea)`
background-color: #F4F4F4;
border: none;
border-radius: 4px;
font-family: OfficinaSansITCPro-Book;
height: 300px;
letter-spacing: 1.5px !important;
line-height: 21px;
margin: 0px;
padding: 12px 16px;
width: 100%;
`
const STextWrapper = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  height: 80vh;
  padding: 0 16px;
`

export default Comment
