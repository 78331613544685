/* Global imports */
import React from 'react'
import { object } from 'prop-types'
import { Grid } from 'semantic-ui-react'
import Button from 'Components/Button'
import styled from 'styled-components'


/* Local imports */
import { listenAndMarkGapsShape } from 'Definitions/shapes'
import Text from 'Components/Text'
import PlayButton from 'Components/PlayButton'
import Spacer from 'Components/Spacer'
/* Component definition */
const ListenAndMarkGaps = ({ exercise, results }) => {
  return (
    <div>
      <div>
        <Spacer height="16px" />
        <Text className="exercise-text" notr>{exercise.description}</Text>
        <Spacer height="48px" />
        <Grid>                
          <Grid.Row>
            <Grid.Column width={16}>
              <SWrapper>
                {renderLetters(exercise, results)}
                <SPlayButton exercise={exercise} step={exercise.steps.length -1}  />
              </SWrapper>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Spacer height="64px" />
      </div>
    </div>
  )
}

/* PropTypes */
ListenAndMarkGaps.propTypes = {
  exercise: listenAndMarkGapsShape,
  results: object,
}

ListenAndMarkGaps.defaultProps = {}

    /* Local utility functions */
    const renderLetters = (exercise, results) => {
      
      return (
        exercise.steps[0].options.map((option, index) =>
        <div key={index}>
                                    <SContainer>
                                      {
                                        option.choices.map((choice) =>
                                        Array.from(choice).map((letter, tindex) => {

                                          const DynamicButton = (results.step0 && results.step0.includes(tindex)) ? 
                                            exercise.steps[0].answer.includes(tindex) ?
                                                CButton
                                                :
                                                IButton
                                            : SButton


                                          
                                          return (
                                            <DynamicButton
                                            className='main-font font22'
                                                notr
                                                key={tindex}
                                                toggle
                                                value={tindex}
                                              >
                                                <p>{letter}</p>
                                              </DynamicButton>
                                              )

                                          }
                                          )
                                        )
                                      }
                                    </SContainer>
                                  </div>
                                ))
}

/* Local Styled Components */
const SPlayButton = styled(PlayButton)`
  margin: 16px 0px !important;
  margin-bottom: 32px !important;
`

const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0px 16px;

  @media (min-width: 786px) {
    max-width: 400px;
    margin: 0 auto;
  }
`

const SButton = styled(Button)`
  align-items:     center;
  display:         flex !important;
  justify-content: flex-start;
  flex: 0;
  padding:         2px!important;
  border:          none!important;
  margin-left: 1px!important;
  margin-bottom: 5px!important;
  padding-bottom: 0px!important;
  max-width: 50px;
`

const CButton = styled(Button)`
  align-items:     center;
  display:         flex !important;
  justify-content: flex-start;
  flex: 0;
  padding:         2px!important;
  border: none!important;
  border-right: 2px solid green!important;
  border-radius: 0!important;
  margin-left: 1px!important;
  margin-bottom: 5px!important;
  padding-bottom: 0px!important;
  max-width: 50px;
  `
  
  const IButton = styled(Button)`
  align-items:     center;
  display:         flex !important;
  justify-content: flex-start;
  flex: 0;
  padding:         2px!important;
  border: none!important;
  border-right: 2px solid red!important;
  margin-left: 1px!important;
  margin-bottom: 5px!important;
  padding-bottom: 0px!important;
  border-radius: 0!important;
  max-width: 50px;
`

const SContainer = styled.div`
  align-items:     center;
  display:         flex !important;
  justify-content: flex-start;
  flex-flow: wrap;
`


export default ListenAndMarkGaps
