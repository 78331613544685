/* Global imports */
import React from 'react'
import { array, number } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import dotEmpty from 'Assets/images/Oval-border.svg'
import dotFilled from 'Assets/images/Oval-filled.svg'

/* Component definition */
const DotProgress = ({ active, steps, onClick }) => {
  return (
    <Wrapper>
      {
        steps.reduce((total, current, index) => {
          return total.concat([
            index <= active ? renderDot(index, onClick)
              : renderEmptyDot(index, onClick)
          ])
        }, [])
      }
    </Wrapper>
  )
}


/* PropTypes */
DotProgress.propTypes = {
  active: number,
  steps:  array,
}

DotProgress.defaultProps = {}

/* Local utility functions */
const renderDot = (index, onClick) => {
  return <Dot key={index} onClick={() => onClick(index)}>
           <img alt="oval-empty" src={dotFilled} />
         </Dot>
}

const renderEmptyDot = (index, onClick) => {
  return <Dot key={index} onClick={() => onClick(index)}>
           <img alt="oval-empty" src={dotEmpty} />
         </Dot>
}

/* Local Styled Components */
const Wrapper = styled.div`
  display: flex;

  div {
    margin: 3px;
  }
`

const Dot = styled.div`
  cursor:  pointer;
  display: flex;
  width:   8px;
`

export default DotProgress
