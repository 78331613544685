/* Global imports */
import React from 'react'
// import { } from 'prop-types'
import { Progress, Segment, Grid, Responsive } from 'semantic-ui-react'
import { Link, Redirect } from 'react-router-dom'
import { map, pipe, reduce } from 'ramda'

/* Local imports */
import { EXERCISE, LOGIN, TRAINING_DAY } from 'Definitions/routes'
import { isInPast, getStepCount, getCompletedStepCount } from 'Utils'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import Wizard from 'Components/Wizard'
import Spinner from 'Components/Spinner'
import useSession from 'Hooks/useSession'
import useResults from 'Hooks/useResults'
import useLocalStorage from 'Hooks/useLocalStorage'
import useLoading from 'Hooks/useLoading'

import trainingDays from '../Exercises'
import trainingDaysPlus from '../ExercisesPlus'

const DayLink = ({ disabled, ...props }) => {
  if(disabled) {
    return <Text notr {...props} />
  }
  return <Link { ...props }/>
}

/* Component definition */
const TrainingOverview = () => {
  const { user, userId, isPlus } = useSession()
  const { results } = useResults()
  const { isLoading } = useLoading()
  const [showWizard, setShowWizard] = useLocalStorage('showWizard', true)

  const { endDate: endDateStandard, endDatePlus, startDate: startDateStandard, startDatePlus, startDay: startDayStandard = 1, startDayPlus = 1 } = user || {}

  const trainingDaysActive = isPlus ? trainingDaysPlus : trainingDays
  const startDate = isPlus ? startDatePlus : startDateStandard
  const endDate = isPlus ? endDatePlus : endDateStandard
  const startDay = isPlus ? startDayPlus : startDayStandard

  const availableDays = trainingDaysActive.slice(startDay - 1)

  const getProgressForDay = getDailyProgress(results, availableDays)

  if (!userId) {
    return <Redirect to={LOGIN()} />
  }

  if (isLoading) {
    return (
      <Spinner />
    )
  }

  if (showWizard) {
    return <Wizard onClose={() => setShowWizard(false)}/>
  }

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text className={isPlus ? 'plusText' : 'primaryText'} as="h4">trainingOverview.title</Text>
          <Separator  minWidth={0}/>
        </Grid.Column>
      </Grid.Row>
      <Spacer height={'8px'}/>
      <Grid.Row>
      <Grid.Column width={16}>
        {
          availableDays
            .map((exercises, index) => ({
              dayIndex: exercises[0].day - 1,
              exercises,
              progress: getProgressForDay(index),
            }))
            .map(({ dayIndex, exercises, progress }, index) => (
            <Segment key={index} disabled={!isInPast(startDate, index) || isInPast(endDate)}>
              <DayLink
                disabled={!isInPast(startDate, index) || isInPast(endDate)}
                to={progress > 0 ? TRAINING_DAY(dayIndex) : EXERCISE(dayIndex, 0)}
              >
                <Grid padded>
                  <Grid.Column mobile={16} computer={8}>
                    <Text as="h6" className={isPlus ? 'plusText' : 'primaryText'} vars={{ dayNumber: exercises[0].day }}>trainingOverview.titleDay</Text>
                </Grid.Column>
                <Grid.Column mobile={16} computer={8}>
                <Responsive minWidth={992}>
                    <Text className={isPlus ? 'plusText' : 'primaryText'} as="h6">trainingOverview.progress</Text>
                    <Spacer height="16px" />
                </Responsive>
                  <div style={{textAlign: 'center'}}>
                    <Progress
                      autoSuccess
                      percent={getProgressForDay(index)}
                      size="tiny"
                      indicating
                    />
                    <Text className="caption" as="p" notr>{ `${progress} %` }</Text>
                  </div>
                </Grid.Column>
                </Grid>
              </DayLink>
            </Segment>))
        }
      </Grid.Column>
      </Grid.Row>

    </Grid>
  )
}

/* PropTypes */
TrainingOverview.propTypes = {}

TrainingOverview.defaultProps = {}

/* Local utility functions */

const getDailyProgress = (results, days) => {
  const countAnsweredSteps = day => day.reduce(
    (total, exercise) => total + getCompletedStepCount(exercise, results),
    0
  )

  const getProgress = map(countAnsweredSteps)

  const exerciseCount = pipe(
    map(reduce((t, exercise) => {
      return t + getStepCount(exercise)
    }, 0)),
  )(days)

  return index => {
    const progress = getProgress(days)[index] * 100 / exerciseCount[index]

     return progress.toFixed(0)
  }
}

/* Local Styled Components */

export default TrainingOverview
