/* Global imports */
import React, { useState } from 'react'
import { func, bool } from 'prop-types'
import { Menu, Image } from 'semantic-ui-react'
import { isNil } from 'crocks/predicates'
import ifElse from 'crocks/logic/ifElse'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

/* Local imports */
import * as ROUTES from 'Definitions/routes'
import Separator from 'Components/Separator'
import Text from 'Components/Text'
import useSession from 'Hooks/useSession'

/* Component definition */
const MobileMenu = ({ history, onToggleMenu, menuItems }) => {
  const { logout, user, isPlus, setPlus, settings } = useSession()
  const [ activeItem, setActiveItem ] = useState()

  const onLogout = () => {
    logout()
      .then(console.log.bind(null, 'Logging out...'))
      .then(() => { 
        history.push({
          pathname: ROUTES.LOGIN(),
          state: { isPlus }
        })
        setPlus(false)
      })
  }

  const goTo = (path, plus) => {
    setActiveItem(plus ? `${path}+`: path)
    onToggleMenu()
    history.push({ 
      pathname: path, 
      state: { isPlus: plus},
    })
  }

  return (
      <Menu style={{ padding: '0px 8px', width: '100%'}} text vertical>
          <Menu.Menu>
            {
              getVisibleItems(user, menuItems)
                .map((item) => {
                  if(item.title === 'mainMenu.professionalInfo') {
                    return {
                      ...item,
                      notr: true,
                      title: settings.companyName
                    }
                  }
                  return item;
                })
                  .map((item, index) =>
                          <div key={index}>
                            <Menu.Item
                              className="mobile-menu-item ui grid"
                              active={activeItem === item.to}
                              name={item.to}
                              key={item.to}
                            >
                              <div className="row" style={{
                                "alignItems": "center",
                                "padding": 0
                              }}>
                                <div className="two wide column" style={{"paddingLeft": 0}}>
                                  {item.icon && <Image style={{"margin": "auto"}} src={require(`Assets/icons/${item.icon}`)}/>}
                                </div>
                                <div className="fourteen wide column" style={{"padding" : 0}}>
                                  <Text notr={item.notr} as="h5" style={{"margin": 0}} onClick={() => goTo(item.to)}>
                                  { isPlus && item.titlePlus ? item.titlePlus : item.title}
                                  </Text>
                                </div>
                              </div>
                            </Menu.Item>
                            <Separator/>
                          </div>
                      )
            }
          </Menu.Menu>
          <SSesionButton>
            {renderSessionButton(onLogout, goTo)(user)}
          </SSesionButton>
      </Menu>
  )
}

/* PropTypes */
MobileMenu.propTypes = {
  onToggleMenu: func,
  visible:      bool,
}

MobileMenu.defaultProps = {}

/* Local utility functions */
const renderSessionButton = (logout, goTo) => ifElse(
  isNil,
  () => (
        <>
          <SSessionItemLogIn>
            <Text onClick={() => goTo(ROUTES.LOGIN())}>mainMenu.login</Text>
          </SSessionItemLogIn>
          <SSessionItemLogInPlus>
            <Text onClick={() => goTo(ROUTES.LOGIN(), true)}>mainMenu.loginPlus</Text>
          </SSessionItemLogInPlus>
        </>
        ),
  () => <SSessionItemLogOut  onClick={logout}>
        <Text>mainMenu.logout</Text> 
        </SSessionItemLogOut>,
)

const getVisibleItems = (user, items) => {
  if (user) {
    return items.filter(i => !i.sessionHide)
  }

  return items.filter(x => !x.session)
}

/* Local Styled Components */

const SSesionButton = styled.div`
  margin: 44px 0px !important;
  width: 100%;
`
const SSessionItemLogIn = styled.div`
  font-family: OfficinaSansITCPro-Bold !important;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  height: 61px;
  letter-spacing: 1.5px;
  line-height: 50px;
  margin: 12px auto;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  background-color: #41B094;
`
const SSessionItemLogInPlus = styled.div`
  font-family: OfficinaSansITCPro-Bold !important;
  border-radius: 4px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  height: 61px;
  letter-spacing: 1.5px;
  line-height: 50px;
  margin: 12px auto;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  background-color: #0082BA;
`

const SSessionItemLogOut = styled.div`
  font-family: OfficinaSansITCPro-Bold !important;
  border-radius: 4px;
  border: 1px solid #0082BA;
  border-color: #0082BA;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
  height: 61px;
  letter-spacing: 1.5px;
  line-height: 50px;
  margin: 12px auto;
  padding: 6px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
  background-color: #0082BA;
  cursor: pointer;
`

export default withRouter(MobileMenu)