/* Global imports */
import React from 'react'
import { bool, element, node, number, oneOfType, string } from 'prop-types'
import { Button as SemanticButton } from 'semantic-ui-react'

/* Local imports */
import I18n from 'Common/i18n'

/* Component definition */
const Button = ({ children, notr, ...props }) => {
  return (
    <SemanticButton children={notr ? children : I18n.t(children)} {...props} />
  )
}

/* PropTypes */
Button.propTypes = {
  children: oneOfType([element, node, number, string]),
  notr:     bool,
}

Button.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default Button
