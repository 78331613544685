import { getPath, option, map, not, pipe, safe } from 'crocks'
import { add, append, assocPath, evolve, join, last, path, pathSatisfies, replace, split, when, without } from 'ramda'
import { isArray, isDefined, isNumber, isObject } from 'crocks/predicates'
import { or } from 'crocks/logic'
import { addDays, isPast, parseISO } from 'date-fns'

export const getRefValue = pipe(
  getPath(['current', 'inputRef', 'current', 'value']),
  option(''),
)

export const getRefFile = pipe(
  getPath(['current', 'inputRef', 'current', 'files', 0]),
  option(''),
)

export const getExtension = pipe(
  split('.'),
  last,
)

const setDefaultRepetitions = (exercise, isPlus) => when(
  pathSatisfies(not(isDefined), [isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, 'repetitions']),
  assocPath([isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, 'repetitions'], 1),
)

export const addRepetition = (user, exercise, isPlus) => {
  const exerciseIndex = exercise.index;
  const repetitionsExist = pathSatisfies(isDefined, [isPlus ? 'answersPlus' : 'answers', `exercise${exerciseIndex}`, 'repetitions'], user)
  return pipe(
    setDefaultRepetitions(exerciseIndex, isPlus),
    evolve(isPlus ? 
      {
        answersPlus: {
          [`exercise${exerciseIndex}`]: {
            repetitions: repetitionsExist ? add(1) : 1,
          }
        }
      }
      :
      {
        answers: {
          [`exercise${exerciseIndex}`]: {
            repetitions: repetitionsExist ? add(1) : 1,
          }
        }
      }
  ))(user)
}
  

const setDefaultTotalCorrect = (exercise, isPlus) => when(
  pathSatisfies(not(isDefined), [isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, 'totalCorrect']),
  assocPath([isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, 'totalCorrect'], 0),
)

const addTotalCorrect = (exercise, correct, isPlus) => evolve(
  isPlus ? 
  {
    answersPlus: {
      [`exercise${exercise}`]: {
        totalCorrect: add(correct),
      }
    }
  }
  :
  {
    answers: {
      [`exercise${exercise}`]: {
        totalCorrect: add(correct),
      }
    }
  }
)

export function setAnswer(user, { correct, exercise, stage, step, answer }, isPlus) {
  if (isNumber(stage)) {
    return pipe(
      assocPath([isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, `stage${stage}`, `step${step}`], answer),
      setDefaultTotalCorrect(exercise, isPlus),
      addTotalCorrect(exercise, correct, isPlus),
    )(user)
  }

  return pipe(
    assocPath([isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, `step${step}`], answer),
    setDefaultTotalCorrect(exercise, isPlus),
    addTotalCorrect(exercise, correct, isPlus),
  )(user)
}

export function setTempAnswer(user, { exercise, stage, step, answer }, isPlus) {
  if (isNumber(stage)) {
    return pipe(
      assocPath([isPlus ? 'answersPlusTemp' : 'answersTemp', `exercise${exercise}`, `stage${stage}`, `step${step}`], answer),
    )(user)
  }

  return pipe(
    assocPath([isPlus ? 'answersPlusTemp' : 'answersTemp', `exercise${exercise}`, `step${step}`], answer),
  )(user)
}

export function isAnswered(user, { exercise, stage, step }, isPlus) {
  if (isNumber(stage)) {
    return pipe(
      path([isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, `stage${stage}`, `step${step}`]),
      isDefined,
    )(user)
  }

  return pipe(
    path([isPlus ? 'answersPlus' : 'answers', `exercise${exercise}`, `step${step}`]),
    isDefined,
  )(user)
}

export const isInPast = (date, dayIndex = 0) => pipe(
  parseISO,
  x => addDays(x, dayIndex),
  isPast,
)(date)


const processLetter = x => {
  const letters = [
    ['ss', 'ß'],
    ['a', 'ä'],
    ['o', 'ö'],
    ['u', 'ü'],
    ['a', 'Ä'],
    ['o', 'Ö'],
    ['u', 'Ü'],
  ]

  const foundLetterGroup = letters.find(letterGroup => letterGroup.includes(x))

  return foundLetterGroup ? foundLetterGroup[0] : x
}

export  const processString = (input) => {
  const string = input.split('').map(processLetter).join('')

  const regex = new RegExp(/[A-Za-z0-9]+/, 'g')

  if(string.match(regex)) {
    return string.match(regex).join('').toLowerCase()
  }

  return string
}

// processNumber :: String -> Number
export const processNumber = pipe(
  replace(',', '.'),
  x => parseFloat(x),
)

// processDate :: String -> String
// Returns a date without leading 0's
export const processDate = pipe(
  split('/'),
  map(x => parseInt(x)),
  join('/')
)

export const getStepCount = ({ stages, steps }) => {
  if (stages) {
    return stages.reduce((total, current) => {
      return total + current.length
    }, 0)
  }

  return steps.length
}

export const setAudioFile = (exercise, currentStep, currentStage, playIntro) => {
  if (playIntro) {
    return `${exercise.index + 1}_bsp.mp3`
  }

  if (exercise.stages && currentStage === 1){
    const stepsNumbers = getStepCount(exercise)
    const step = currentStep + (stepsNumbers / exercise.stages.length)  + 1

    return `${exercise.index + 1}-${step}.mp3`
  }

  return `${exercise.index + 1}-${currentStep + 1}.mp3`
}

const isStep = key => /^step.*/.test(key)

const isStage = key => /^stage.*/.test(key)

export const getCompletedStepCount = ({ index, optional }, results = {}) => {
  const res = optional ? results[`exercise-optionalExercise-${index}`] : results[`exercise${index}`]

  if (!res) {
    return 0
  }

  return Object.keys(res)
    .filter(or(isStep, isStage))
    .reduce((total, key) => {
      if (isObject(res[key])) {
        return total + Object.keys(res[key]).length
      }

      return total + 1
    }, 0)
}

export const getCurrentStage = ({ index }, results = {}, optional) => {
  const res = results[`exercise${optional ? `-optionalExercise-${index}` : index}`]

  if (!res) {
    return 0
  }

  return Object.keys(res)
    .reduce((total, key) => {
      if (isObject(res[key])) {
        return getNumber(key)
      }

      return total
    }, 0)
}

const getNumber = pipe(
  key => /stage([0-9]+)/.exec(key),
  safe(isArray),
  map(x => x[1]),
  map(num => parseInt(num)),
  option(0)
)

export const getTotalDaySteps = (exercises) => {
  return exercises.reduce((total, exercise) => {
      return total + getStepCount(exercise)
  }, 0)
}

export const getTotalCompletedDaySteps = (results, exercises) => {
  return exercises.reduce((total, exercise) => {
      return total + getCompletedStepCount(exercise, results)
  },0)
}

export const isDayCompleted = (dayIndex, exercises, results) => {
  const completedDaySteps = getTotalCompletedDaySteps(results, exercises[dayIndex])
  const totalDaySteps = getTotalDaySteps(exercises[dayIndex])

  return completedDaySteps === totalDaySteps
}

export const getCurrentPos = (exercise, results) => {
  const totalSteps = getStepCount(exercise)
  const lastCompletedStep = getCompletedStepCount(exercise, results)

  if(lastCompletedStep === 0 || lastCompletedStep === totalSteps) {
    return [0, 0]
  }

  if(!exercise.stages) {
    return [0, lastCompletedStep]
  }

  const lastAnsweredStage = getCurrentStage(exercise, results)
  const stepsInLastStage = Object.keys(results[`exercise${exercise.index}`][`stage${lastAnsweredStage}`]).length

  if (exercise.stages[lastAnsweredStage].length === stepsInLastStage) {
    return [lastAnsweredStage + 1, 0]
  }

  return [lastAnsweredStage, stepsInLastStage]
}

export const isExerciseCompleted = (exercise, results) => {
  return getCompletedStepCount(exercise, results) === getStepCount(exercise)
}

export const toggleInList = element => list => {
  return  list.includes(element) ? without([element], list) : append(element, list)
}

export const isAccessCodePlus = (accessCode) => accessCode.slice(-1) === '+'
