/* Global imports */
import React from 'react'
import { Grid, Image } from 'semantic-ui-react'

/* Local imports */
import Text from 'Components/Text'
import Spacer from 'Components/Spacer'
import Separator from 'Components/Separator'

/* Component definition */
const ExternalTest = () => {
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4">mainMenu.externalTest</Text>
          <Separator minWidth={0}/>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row centered>
        <Grid.Column mobile={16} tablet={8} computer={8} >
          <Image
            centered
            alt="start-img"
            src={require('Assets/images/home.svg')}
          />
        </Grid.Column>
        <Spacer height="48px"/>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Text as="p" notr>
            <span className="bold">Hörtest</span>
            <br/>
            Sie wollen wissen, wie gut Sie hören? Wir helfen Ihnen dabei. In wenigen Minuten bekommen Sie mit uns ein Ergebnis.
            <br/>
            <span className="bold">Empfehlung:</span>
            <br/>
            Sorgen Sie bitte dafür, dass Sie ungestört sind und Ihre Umgebung ruhig ist. Wir empfehlen die Verwendung von Kopfhörern oder von einem externen Verstärker. Bitte beachten Sie, dass die Lautstärke Ihres Endgerätes auf <span className="bold">100% eingestellt sein muss. Achtung:</span> Der Online-Hörtest <span className="bold">ersetzt nicht</span> den Besuch beim Pro Akustiker oder HNO-Arzt. Er dient lediglich der Einschätzung Ihres Hörvermögens.
          </Text>
        </Grid.Column>
      </Grid.Row>
    <Spacer height="8px"/>
    <Grid.Row stretched centered>
      <iframe
        allowFullScreen=""
        frameBorder="0"
        height="580"
        id="iframe"
        src="/heartest/HearingTest.html"
        title="Heartest"
        width="100%"
      >
      </iframe>
    </Grid.Row>
  </Grid>

  )
}

/* PropTypes */
ExternalTest.propTypes = {}

ExternalTest.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default ExternalTest
