/* Global imports */
import React from 'react'
import { Grid, Label } from 'semantic-ui-react'
import styled from 'styled-components'

/* Local imports */
import Text from 'Components/Text'
import PlayButton from 'Components/PlayButton'
import Spacer from 'Components/Spacer'

/* Component definition */
const ListenAndSelectTone = ({ exercise, results }) => {

  return (
    <>
      <Spacer height="16px" />
      <Text className="exercise-text" notr dangerouslySetInnerHTML={{__html: exercise.description}}></Text>
      <Spacer height="48px" />
      <Grid>
        {
          exercise.steps.map((step, index) =>
            <Grid.Row key={index}>
              <Grid.Column>
                {
                  renderResults(exercise.options, getClientResult(results, index), step.answer, exercise, index)
                }
              </Grid.Column>
            </Grid.Row>
          )
        }
      </Grid>
      <Spacer height="64px" />
    </>
  )
}

/* PropTypes */
ListenAndSelectTone.propTypes = {
}

ListenAndSelectTone.defaultProps = {}

/* Local utility functions */
const areEqual = (correctAnswer, userAnswer) => {

  if (!userAnswer) {
    return
  }

  return correctAnswer.length === userAnswer.length
    && userAnswer.reduce((total, current) => total && correctAnswer.includes(current), true)
}

const getClientResult = (results, index) => {
  if (results && results[`step${index}`]) {
    return results[`step${index}`]
  }
  return
}

const getColor = (result, correctAnswer, buttonValue) => {

  if (!result) {
    return
  }

  if (areEqual(correctAnswer, result) && correctAnswer.includes(buttonValue)) {
    return "rgba(81,184,157,0.45)"
  }

  if (!areEqual(correctAnswer, result)) {
    
    if (result.includes(buttonValue) && !correctAnswer.includes(buttonValue)) {
      return "rgba(224,0,105,0.31)"
    }

    if (correctAnswer.includes(buttonValue)  && !result.includes(buttonValue)) {
      return "transparent"
    }

    if (correctAnswer.includes(buttonValue)  && result.includes(buttonValue)) {
      return "rgba(81,184,157,0.45)"
    }

    return "transparent"
  }

  return "transparent"
}

const renderResults = (options, result, correctAnswer, exercise, stepIndex) => {
  const answerCorrect = areEqual(correctAnswer, result)

  return (
    <SWrapper>
      <Text style={{ transform: 'translate(-8px)' }} as="p" >{answerCorrect ? 'exercise.results.correctAnswer' : 'exercise.results.wrongAnswer'}</Text>
      <Spacer height="16px" />
      {
        options.map((option, index) => {
          const answerCorrect = getColor(result, correctAnswer, option.value)

          return (
            <SLabel
              key={index}
              bgcolor={answerCorrect}
            >
              <p>{option.label}</p>
            </SLabel>
          )
        })
      }
      <SPlayButton exercise={exercise} step={stepIndex} />
    </SWrapper>
  )
}

/* Local Styled Components */
const SPlayButton = styled(PlayButton)`
  margin: 16px 0px !important;
  margin-bottom: 32px !important;

`
const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0px 16px;

  @media (min-width: 786px) {
    max-width: 400px;
    margin: 0 auto;
  }
`

const SLabel = styled(Label)`
  color: #000000 !important;
  background-color: ${({ bgcolor }) => bgcolor} !important;
  border: 1px solid lightgrey !important;
  margin: 4px 0px !important;
  whiteSpace: normal !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 1.5px !important;
  line-height: 16px !important;
  padding: 16px !important;
  padding-left: 40px !important;
`

export default ListenAndSelectTone
