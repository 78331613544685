/* Global imports */
import React, { useEffect, useState } from "react";
import * as R from "ramda";

/* Local imports */
import Input from "Components/Input";

/* Component definition */
const NumberInput = (props) => {
  // const [ backspace, setBackSpace ] = useState(false)
  // const { mask: maskTemplate, onChange, value } = props
  // const mask = getMask(maskTemplate)
  // const periodPosition = mask.indexOf('.')

  // const onKeyDown = ({ key }) => {

  //   if (key === 'Backspace') {
  //     setBackSpace(true)
  //     onChange(null, { value: addPeriod(periodPosition, removeCharFromValue(value)) })
  //   }
  // }

  // const onInputChange = (e, {value: inputValue }) => {
  //   // When pressing backspace
  //   if (inputValue === '0' || backspace) {
  //     setBackSpace(false)
  //     return
  //   }

  //   onChange(null, { value: addPeriod(periodPosition, addKeyToValue(value, inputValue.slice(-1))) })
  // }

  // useEffect(() => {
  //   if (mask.length !== value.length) {
  //     onChange(null, { value: mask })
  //   }
  // }, [mask, onChange, value])

  return (
    <Input
      {...props}
      // onChange={onInputChange}
      // onKeyDown={onKeyDown}
      inputType="number"
    />
  );
};

/* PropTypes */
NumberInput.propTypes = {};

NumberInput.defaultProps = {};

/* Local utility functions */
// getMask :: (String) -> String
const getMask = (maskTemplate) => {
  return maskTemplate.replace(/[^,]/g, "0").replace(",", ".");
};

// addPeriod :: (Int, String) -> String
const addPeriod = (position, value) => {
  if (position < 0) {
    return value;
  }

  const before = value.slice(0, position);
  const after = value.slice(position);

  return before + "." + after;
};

// addKeyToValue :: (String, Char) -> String
const addKeyToValue = (oldValue, key) =>
  oldValue
    .toString()
    .split("")
    .filter((x) => x !== ".")
    .concat(key)
    .slice(1)
    .join("");

// removeCharFromValue :: String -> String
const removeCharFromValue = R.pipe(
  R.toString(),
  R.split(""),
  R.filter((x) => x !== "." && x !== '"'),
  R.prepend("0"),
  R.dropLast(1),
  R.join("")
);

/* Local Styled Components */

export default NumberInput;
