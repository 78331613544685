import getRoute from 'Utils/routeCreator'

export const ABOUT_US = getRoute('/about')

export const AUDIO_SETTINGS = getRoute('/audio-settings/:dayIndex/exercise/:exerciseIndex')

export const BANANA = getRoute('/horfeld')

export const EXTERNAL_TEST = getRoute('/hortest')

export const PROFESSIONAL_INFO = getRoute('/akustiker')

export const PROFESSIONAL_SEARCH = getRoute('/professional-search')

export const OUR_PRODUCTS = getRoute('/our-products')

export const LOGIN = getRoute('/login')

export const HOME = getRoute('/')

export const IMPRESSUM = getRoute('/impressum')

export const DATA_PROTECTION = getRoute('/data-protection')

export const TRAINING_OVERVIEW = getRoute('/training-overview')

export const TRAINING_DAY = getRoute('/training-day/:dayIndex')

export const REVIEW_EXERCISE = getRoute('/training-day/:dayIndex/review-exercise')

export const EXERCISE = getRoute('/training-day/:dayIndex/exercise/:exerciseIndex')

export const OPTIONAL_EXERCISE = getRoute('/training-day/:dayIndex/optional/:exerciseIndex')

export const RESULTS = getRoute('/training-day/:dayIndex/exercise/:exerciseIndex/results')

export const OPTIONAL_RESULTS = getRoute('/training-day/:dayIndex/optional/:exerciseIndex/results')

export const SETTINGS = getRoute('/settings')

export const DEMO = getRoute('/demo')

export const DEMO_EXERCISE = getRoute('/demo/:dayIndex/exercise/:exerciseIndex')
