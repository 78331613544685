/* Global imports */
import React from 'react'
import { Grid,  Segment } from 'semantic-ui-react'

// import { } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import { DEMO_EXERCISE } from 'Definitions/routes'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import exercises from '../ExercisesDemo'

/* Component definition */
const Demo = ({ history }) => {

  return (
    <Grid padded>
        <Grid.Row>
          <Grid.Column width={16}>
            <Text as="h4">trainingDay.title</Text>
            <Separator minWidth={0} />
          </Grid.Column>
          <Spacer height={'16px'} />
          <Grid.Column width={16}>
            {
              exercises[0]
                .map((exercise, index) => {
                  return (
                    <SSegment key={index}>
                      <div
                        key={index}
                        onClick={() => history.push(DEMO_EXERCISE(0, index))}
                      >
                        <Grid padded>
                          <STopWrapper >
                            <Text className="caption-blue" notr>Übung {exercise.number} </Text>
                          </STopWrapper>
                          <Grid.Row>
                            <Text as="p" style={{ paddingLeft: '8px' }} notr> {exercise.title} </Text>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </SSegment>
                  )
                }
                )
            }
          </Grid.Column>
          <Spacer height="64px" />
        </Grid.Row>
    </Grid>
  )
}

/* PropTypes */
Demo.propTypes = {}

Demo.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SSegment = styled(Segment)`
  background-color: #F2F9FB !important;
  border:           none !important;
  box-shadow:       none !important;
  cursor:           pointer;
  height:           auto !important;
`

const STopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width 100% !important;
  padding: 8px 0px !important;
  padding-left: 8px !important;
`

export default Demo
