/* Global imports */
import React, { useRef, useState } from 'react'
import { Grid,  Image, Responsive } from 'semantic-ui-react'
import styled from 'styled-components'
import { Redirect } from 'react-router-dom'

/* Local imports */
import { DEMO, TRAINING_OVERVIEW } from 'Definitions/routes'
import { getRefValue } from 'Utils'
import useSession from 'Hooks/useSession'
import Input from 'Components/Input'
import NextButton from 'Components/NextButton'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import userService from '../services/user'


/* Component definition */
const Login = ({ history, location }) => {
  const [error, setError] = useState('')
  const accessCodeRef = useRef()
  const { login, user } = useSession()

  const plus = (location.state && location.state.isPlus);

  const onSubmit = (e) => {
    e.preventDefault()
    const accessCode = getRefValue(accessCodeRef).trim()
    
    userService.initFirebase()
    .then(() => {
      login(accessCode)
        .subscribe({
          next: (user) => {
            setError('')
            history.push(TRAINING_OVERVIEW())
          },
          error: (e) => {
            setError(`Error: Ihr Zugangscode ist entweder falsch oder Ihre Benutzungszeit ist bereits abgelaufen. Versuchen Sie es noch einmal oder kontaktieren Sie ihren Hörgeräteakustiker.`)
            console.error(e)
          }
        })
    })
  }

  if (user) {
    return <Redirect to={TRAINING_OVERVIEW()} />
  }

  const handleDemo = () => {
    userService.initFirebase()
    history.push(DEMO())
  }

  return (
    <form onSubmit={onSubmit}>
      <Grid padded>
        <Grid.Row>
          <Grid.Column verticalAlign="middle" width={16}>
            <Responsive minWidth={768}>
            <Text as="h4">login.title</Text>
            <Separator minWidth={768}/>
            <Spacer height="64px" />
            </Responsive>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <Image
              centered
              alt="login-img"
              src={require('Assets/images/login.svg')}
            />
          <Spacer height="32px" />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={8}>
            <SWrapper>
              <Grid.Row style={{ paddingBottom: '8px'}}>
              <Text className="caption">{!plus ? 'login.accessCode.label' : 'login.accessCode.labelPlus'}</Text>
              </Grid.Row>
              <SInput
                placeholder="login.accessCode.placeholder"
                ref={accessCodeRef}
              />
              <NextButton
                className={`full-width rounded login-btn ${plus && 'plus'}`}
                primary
                type="submit"
              >
                login.submit
              </NextButton>
              <ErrorText notr>{ error }</ErrorText>
              <Spacer height="16px" />
              <Text >firebase.acceptText</Text>
              <Spacer height="16px" />
              {!plus && 
                <SBottomButton
                  className="full-width rounded login-btn"
                  onClick={handleDemo}
                >
                  <Text as="p" style={{ textAlign: 'center', paddingTop: '4px'}}>login.demo</Text>
                </SBottomButton>
              }
            </SWrapper>
          </Grid.Column>
          </Grid.Row>
        </Grid>
      </form>
  )
}

/* PropTypes */
Login.propTypes = {}

Login.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SWrapper = styled.div`
  margin: 0 auto;

@media (min-width: 768px) {
  diplay: flex;
  flex-direction: column;
  max-width: 360px;
  margin: 120px auto;
}
`
const SInput = styled(Input)`
  border: none !important;
  width: 100% !important;
`
const ErrorText = styled(Text)`
  color: red;
`
const SBottomButton = styled.button`
background-color: transparent;
border-radius: 4px;
border: 1px solid #0082BA;
color: #000000;
display: flex;
align-items: center !important;
font-family: OfficinaSansITCPro-Bold !important;
font-weight: bold;
height: 36px;
letter-spacing: 1.5px;
line-height: 36px;
margin: 0 auto !important;
text-align: center;
text-transform: uppercase;
width: fit-content;
`

export default Login
