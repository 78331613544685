import { useState } from 'react'
import { getCompletedStepCount, getStepCount } from 'Utils'

import useResults from 'Hooks/useResults'

const useSteps = (exercise, onNext) => {
  const steps = exercise.steps
  const { results } = useResults()
  const [currentStep, setCurrentStep] = useState(getCurrentStepPos(exercise, results))

  const next = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1)
    } else {
      setCurrentStep(0)
      onNext(true)
    }
  }

  return {
    next,
    currentStep,
  }
}

const getCurrentStepPos = (exercise, results) => {
  const totalSteps = getStepCount(exercise)  
  const lastCompletedStep = getCompletedStepCount(exercise, results)

  if(lastCompletedStep === 0 || lastCompletedStep === totalSteps) {
    return 0
  }

  return lastCompletedStep
}

export default useSteps
