/* Global imports */
import React, { useState } from 'react'
import { Confirm } from 'semantic-ui-react'
import { number, object } from 'prop-types'

/* Local imports */
import I18n from 'Common/i18n'
import { OPTIONAL_EXERCISE, OPTIONAL_RESULTS, TRAINING_DAY } from 'Definitions/routes'
import useSession from 'Hooks/useSession'
import useResults from 'Hooks/useResults'
import CancelButton from 'Components/CancelButton'
import NextButton from 'Components/NextButton'
import Spacer from 'Components/Spacer'
import ListenAndType from 'Components/results/ListenAndType'
import ListenAndSort from 'Components/results/ListenAndSort'
import ListenAndSelectTone from 'Components/results/ListenAndSelectTone'
import ListenAndPick from 'Components/results/ListenAndPick'
import ListenAndPickAccentedWord from 'Components/results/ListenAndPickAccentedWord'
import ListenAndPickImages from 'Components/results/ListenAndPickImages'
import ListenAndMarkGaps from 'Components/results/ListenAndMarkGaps'
import ListenAndTypeAndPick from 'Components/ListenAndTypeAndPick'
import Text from 'Components/Text'

import { isExerciseCompleted } from '../utils'
import optionalExercises from '../ExercisesPlus/OPTIONAL_EXERCISE'

/* Component definition */
const OptionalResults = ({ history, match }) => {
  const { isPlus } = useSession()
  const { exerciseIndex, dayIndex } = match.params
  const { results, temporaryResults, resetTemp } = useResults()
  const [cancelModalOpen, setCancelModalOpen] = useState(false)

  const exercises = optionalExercises

  const currentExercise = exercises[dayIndex][exerciseIndex]
  const nextExercise = exercises[dayIndex][parseInt(exerciseIndex)+ 1]


  const activeResults = isExerciseCompleted(currentExercise, temporaryResults) ? temporaryResults : results

  return (
    <>
    <div>
      <Confirm
        cancelButton={I18n.t('exercise.buttons.cancel.title')}
        confirmButton={I18n.t('exercise.buttons.confirmation')}
        content={
          <Text as="h5" vars={{ exerciseNumber: currentExercise.index + 1 }}>
            exercise.buttons.cancel.confirmationText
          </Text>
        }
        open={cancelModalOpen}
        onCancel={() => setCancelModalOpen(false)}
        onConfirm={() => {
          resetTemp()
          history.push(TRAINING_DAY(currentExercise.day - 1))
        }}
      />
      <CancelButton
        onClick={() => setCancelModalOpen(true)}
      />
    </div>
    <div className="padded">
      <header>
      {
          isExerciseCompleted(currentExercise, temporaryResults) &&
          <Text as="h6" notr>Bitte beachten Sie, dass die in der Übungswiederholung erzielten Ergebnisse nicht gespeichert werden. Ihre bestehenden Ergebnisse aus dem ersten Durchlauf werden nicht überschrieben.</Text>
        }
        <Text as="h4" className={isPlus ? 'plusText' : 'primaryText'} vars={{ letter: currentExercise.letter }}>
          exercise.results.letter
        </Text>
        <Spacer height="16px"/>
        <Text as="h5" notr>{ currentExercise.title }</Text>
      </header>
      {
        renderExercise(currentExercise, getExerciseAnswers(activeResults, currentExercise.index, true))
      }
    </div>
    <div style={{display: 'flex', position: 'fixed', bottom: 0, width: '100%', maxWidth: '1300px'}}>
    <NextButton
        className="color-green full-width"
        secondary
        onClick={() => {
          resetTemp()
          history.push({
            pathname: OPTIONAL_EXERCISE(dayIndex, parseInt(exerciseIndex)),
            state: { repeated: true }
          })
        }}
      >
        exercise.buttons.retry
      </NextButton>
      {
        !isExerciseCompleted(currentExercise, temporaryResults) &&
        <NextButton
          className={`full-width ${isPlus ? 'plus' : ''}`}
          primary
          onClick={() => {
            resetTemp()
            history.push(parseInt(exerciseIndex) === exercises[dayIndex].length - 1 || !isExerciseCompleted(nextExercise, results) ? TRAINING_DAY(dayIndex) : OPTIONAL_RESULTS(dayIndex, parseInt(exerciseIndex) + 1))
          }}
        >
          exercise.buttons.next
        </NextButton>
      }
    </div>
    </>
  )
}

/* PropTypes */
OptionalResults.propTypes = {
  currentDay: number,
  match:      object,
}

OptionalResults.defaultProps = {
  currentDay: 0,
}

/* Local utility functions */
const renderExercise = (exercise, results, optional) => {
  switch (exercise.type) {
  case 'LISTEN_AND_TYPE':
    return <ListenAndType exercise={exercise} results={results} optional={optional}/>
  case 'LISTEN_AND_SORT':
    return <ListenAndSort exercise={exercise} results={results} optional={optional}/>
  case 'LISTEN_AND_SELECT_TONE':
    return <ListenAndSelectTone exercise={exercise} results={results} optional={optional}/>
  case 'LISTEN_AND_PICK':
    return <ListenAndPick exercise={exercise} results={results} optional={optional}/>
  case 'LISTEN_AND_PICK_ACCENTED_WORD':
    return <ListenAndPickAccentedWord exercise={exercise} results={results} optional={optional}/>
  case 'LISTEN_AND_PICK_IMAGE':
    return <ListenAndPickImages exercise={exercise} results={results} optional={optional}/>
  case 'LISTEN_AND_MARK_GAPS':
    return <ListenAndMarkGaps exercise={exercise} results={results} optional={optional}/>
  case 'LISTEN_AND_TYPE_AND_PICK':
    return <ListenAndTypeAndPick exercise={exercise} results={results} optional={optional}/>
  default:
    throw Error(`Unrecognized Type ${exercise.type}`)
  }
}

const getExerciseAnswers = (answers, exerciseIndex, optional) => {
  const answeredExercises = Object.keys(answers)
  return answeredExercises.reduce((acc, exercise) => {
    const condition = optional ? exercise === `exercise-optionalExercise-${exerciseIndex}` : exercise === `exercise${exerciseIndex}`
    if (condition) {
      return answers[exercise]
    }
    return acc
  }, {})
}

/* Local Styled Components */

export default OptionalResults
