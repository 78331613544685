/* Global imports */
import React, { useState, useEffect } from "react";
import { number, object } from "prop-types";
import { Confirm } from "semantic-ui-react";

/* Local imports */
import {
  AUDIO_SETTINGS,
  EXERCISE,
  OPTIONAL_EXERCISE,
  REVIEW_EXERCISE,
  TRAINING_DAY,
  RESULTS,
} from "Definitions/routes";
import I18n from "Common/i18n";
import ListenAndType from "Components/ListenAndType";
import ListenAndSort from "Components/ListenAndSort";
import ListenAndSelectTone from "Components/ListenAndSelectTone";
import ListenAndPick from "Components/ListenAndPick";
import ListenAndPickAccentedWord from "Components/ListenAndPickAccentedWord";
import ListenAndPickImages from "Components/ListenAndPickImages";
import ListenAndMarkGaps from "Components/ListendAndMarkGaps";
import ListenAndTypeAndPick from "Components/ListenAndTypeAndPick";
import ReviewExercise from "Components/ReviewExercise";
import FinalMessage from "Components/FinalMessage";
import SuccessMessage from "Components/SuccessMessage";
import Text from "Components/Text";
import CancelButton from "Components/CancelButton";
import { isDayCompleted } from "Utils";
import useResults from "Hooks/useResults";
import useSession from "Hooks/useSession";

import exerciseStandard from "../Exercises";
import exercisesPlus from "../ExercisesPlus";
import { isExerciseCompleted } from "Utils";

/* Component definition */
const Exercise = ({ history, match, location: { state } }) => {
  const { exerciseIndex, dayIndex } = match.params;
  const { isPlus } = useSession();
  const { results, updateRepetitions } = useResults();
  const [lastDayExercise, setLastDayExercise] = useState(false);
  const [audioAdjusted] = useState(
    state && (state.audioAdjusted || state.repeated)
  );
  const [finalMessage, setFinalMessage] = useState(false);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);

  const exercises = isPlus ? exercisesPlus : exerciseStandard;

  const currentExercise = exercises[dayIndex][exerciseIndex];
  const nextExercise = exercises[dayIndex][parseInt(exerciseIndex) + 1];

  const dayIndexesWithReviewExercises = isPlus
    ? [0, 2, 5, 8, 10, 13]
    : [3, 6, 9, 13];

  useEffect(() => {
    if (!audioAdjusted) {
      window.scrollTo(0, 0);
    }
  }, [audioAdjusted]);

  useEffect(() => {
    if (state && state.finalMessage) {
      setFinalMessage(true);
    }
  }, [state]);

  const onNext = (final) => {
    const nextExerciseIndex = parseInt(exerciseIndex) + 1;
    setFinalMessage(false);

    if (final && isExerciseCompleted(currentExercise, results)) {
      updateRepetitions(currentExercise);
      return history.push(RESULTS(dayIndex, exerciseIndex));
    }

    if (final && nextExerciseIndex < exercises[dayIndex].length) {
      return history.push({
        pathname: EXERCISE(dayIndex, parseInt(exerciseIndex)),
        state: { finalMessage: true },
      });
    }

    if (final && nextExerciseIndex === exercises[dayIndex].length) {
      if (dayIndexesWithReviewExercises.includes(parseInt(dayIndex))) {
        return history.push({
          pathname: EXERCISE(dayIndex, parseInt(exerciseIndex)),
          state: { finalMessage: true },
        });
      }
    }

    if (nextExerciseIndex < exercises[dayIndex].length) {
      return history.push(EXERCISE(dayIndex, nextExerciseIndex));
    }

    if (dayIndexesWithReviewExercises.includes(parseInt(dayIndex))) {
      return isPlus
        ? history.push(OPTIONAL_EXERCISE(dayIndex, 0), { audioAdjusted })
        : history.push(REVIEW_EXERCISE(dayIndex));
    }

    return setLastDayExercise(true);
  };

  if (!audioAdjusted) {
    history.replace(AUDIO_SETTINGS(dayIndex, exerciseIndex));
  }

  if (lastDayExercise && !isDayCompleted(dayIndex, exercises, results)) {
    return <SuccessMessage history={history} match={match} incomplete />;
  }

  return lastDayExercise ? (
    <SuccessMessage history={history} match={match} />
  ) : (
    <>
      <Confirm
        cancelButton={I18n.t("exercise.buttons.cancel.title")}
        confirmButton={I18n.t("exercise.buttons.confirmation")}
        content={<Text as="h5">exercise.buttons.cancel.confirmationText</Text>}
        open={cancelModalOpen}
        onCancel={() => setCancelModalOpen(false)}
        onConfirm={() => history.push(TRAINING_DAY(currentExercise.day - 1))}
      />
      <CancelButton onClick={() => setCancelModalOpen(true)} />
      {finalMessage ? (
        <FinalMessage
          currentExercise={currentExercise}
          nextExercise={nextExercise}
          onNext={onNext}
          history={history}
          match={match}
        />
      ) : (
        renderExercise(currentExercise, onNext)
      )}
    </>
  );
};

/* PropTypes */
Exercise.propTypes = {
  currentDay: number,
  match: object,
};

Exercise.defaultProps = {
  currentDay: 0,
};

/* Local utility functions */
const renderExercise = (exercise, onNext) => {
  switch (exercise.type) {
    case "LISTEN_AND_TYPE":
      return <ListenAndType exercise={exercise} onNext={onNext} />;
    case "LISTEN_AND_SORT":
      return <ListenAndSort exercise={exercise} onNext={onNext} />;
    case "LISTEN_AND_SELECT_TONE":
      return <ListenAndSelectTone exercise={exercise} onNext={onNext} />;
    case "LISTEN_AND_PICK":
      return <ListenAndPick exercise={exercise} onNext={onNext} />;
    case "LISTEN_AND_PICK_ACCENTED_WORD":
      return <ListenAndPickAccentedWord exercise={exercise} onNext={onNext} />;
    case "LISTEN_AND_PICK_IMAGE":
      return <ListenAndPickImages exercise={exercise} onNext={onNext} />;
    case "LISTEN_AND_MARK_GAPS":
      return <ListenAndMarkGaps exercise={exercise} onNext={onNext} />;
    case "REVIEW_EXERCISE":
      return <ReviewExercise exercise={exercise} onNext={onNext} />;
    case "LISTEN_AND_TYPE_AND_PICK":
      return <ListenAndTypeAndPick exercise={exercise} onNext={onNext} />;
    default:
      throw Error(`Unrecognized Type ${exercise.type}`);
  }
};

/* Local Styled Components */

export default Exercise;
