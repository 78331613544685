/* Global imports */
import React, { useEffect, useState } from "react";
import { func } from "prop-types";
import { Grid, Image, Responsive } from "semantic-ui-react";
import { isArray, isDefined, isEmpty } from "crocks/predicates";
import { not } from "crocks/logic";
import { any, equals, path, update } from "ramda";
import { withRouter } from "react-router-dom";
import styled from "styled-components";

/* Local imports */
import { listenAndPickShape } from "Definitions/shapes";
import { toggleInList } from "Utils";
import Button from "Components/Button";
import NextButton from "Components/NextButton";
import PlayButton from "Components/PlayButton";
import Separator from "Components/Separator";
import ProgressBar from "Components/ProgressBar";
import Spacer from "Components/Spacer";
import Text from "Components/Text";
import useResults from "Hooks/useResults";
import useSteps from "Hooks/useSteps";
import useSession from "Hooks/useSession";

const setChoices = (exercise, currentStep) => {
  if (exercise.oneStep) {
    return [];
  }

  const stepChoices = path(["steps", currentStep, "choices"], exercise);

  return exercise.choices ? exercise.choices : stepChoices;
};

const getAnswerTemplate = ({ oneStep, steps }) =>
  oneStep ? Array.from({ length: steps[0].options.length }) : [];

const addAnswer = ({ oneStep }, step, answer, value) =>
  oneStep
    ? update(step, answer[step] === value ? undefined : value, answer)
    : toggleInList(value)(answer || []);

/* Component definition */
const ListenAndPick = ({ exercise, onNext, optional }) => {
  const { isPlus } = useSession();
  const [currentScreen, setCurrentScreen] = useState(
    !!exercise.intro ? "intro" : "exercise"
  );
  const { currentStep, next } = useSteps(exercise, onNext);
  const [answer, setAnswer] = useState(getAnswerTemplate(exercise));
  const { saveAnswer } = useResults();
  const correctAnswer = path(["steps", currentStep, "answer"], exercise);
  const [audioPlayed, setAudioPlayed] = useState(false);

  useEffect(() => {
    setAnswer(getAnswerTemplate(exercise));
  }, [exercise]);

  const setCurrentAnswer =
    (index) =>
    (e, { value }) => {
      setAnswer(
        exercise.multi
          ? addAnswer(
              exercise,
              exercise.oneStep ? index : currentStep,
              answer,
              value
            )
          : [value]
      );
    };

  const getCorrectnessBit = (selectedAnswer) => {
    if (exercise.oneStep) {
      return equals(correctAnswer, selectedAnswer) ? 1 : 0;
    }

    return answerCorrect(correctAnswer, selectedAnswer) ? 1 : 0;
  };

  const goNext = () => {
    switch (currentScreen) {
      case "intro":
        setAudioPlayed(false);
        return setCurrentScreen("exercise");
      default:
        setAudioPlayed(false);
        return saveAnswer({
          answer,
          correct: getCorrectnessBit(answer),
          exercise: optional
            ? `-optionalExercise-${exercise.index}`
            : exercise.index,
          step: currentStep,
        }).then((res) => {
          setAnswer([]);
          next();
        });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentStep]);

  return (
    <Grid padded>
      <Grid.Row className="relaxed">
        <Grid.Column width={16}>
          <Text as="h4" className={isPlus ? "plusText" : "primaryText"} notr>
            {optional
              ? `Zusatzübung  ${exercise.letter}`
              : `Übung ${exercise.index + 1}`}
          </Text>
          <Separator minWidth={768} />
        </Grid.Column>
        <Spacer height="16px" />
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <div>
            <Text as="h5" notr>
              {exercise.title}
            </Text>
          </div>
          <Spacer height="30px" />
          <Text
            className="exercise-text"
            notr
            dangerouslySetInnerHTML={{
              __html: getDescription(exercise, currentScreen),
            }}
          ></Text>
          <Spacer height="16px" />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={
                    isPlus
                      ? currentStep > 0
                        ? 1
                        : 0
                      : currentScreen !== "intro"
                      ? 1
                      : 0
                  }
                  className="full-width audio-btn"
                  exercise={exercise}
                  scrollOnLoad
                  scrollOnPlay
                  step={currentStep}
                  onPlayAudio={() => setAudioPlayed(true)}
                  playIntro={currentScreen === "intro"}
                  optional={optional}
                />
                <ProgressBar exercise={exercise} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} tablet={8} computer={8}>
          <Responsive maxWidth={768}>
            <Spacer height="20px" />
          </Responsive>
          <div>
            {!!exercise.customHeader ? (
              <Text as="h5" notr>
                {exercise.customHeader}
              </Text>
            ) : (
              <Text as="h5">exercise.selectOption</Text>
            )}
          </div>
          <Spacer height="30px" />
          {exercise.oneStep
            ? renderAllStepsAtOnce(
                currentScreen,
                exercise,
                answer,
                setCurrentAnswer
              )
            : !!exercise.customSeparator
            ? renderCustomSteps(
                currentScreen,
                exercise,
                currentStep,
                setCurrentAnswer,
                answer
              )
            : renderSteps(
                currentScreen,
                exercise,
                currentStep,
                setCurrentAnswer,
                answer
              )}
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <NextButton
          className={`bottom full-width pos-fixed btn-next-disabled ${
            isPlus ? "plus" : ""
          }`}
          disabled={
            currentScreen === "intro"
              ? !audioPlayed
              : exercise.oneStep
              ? any(not(isDefined), answer)
              : isEmpty(answer)
          }
          onClick={() => goNext()}
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  );
};

/* PropTypes */
ListenAndPick.propTypes = {
  exercise: listenAndPickShape,
  onNext: func.isRequired,
};

ListenAndPick.defaultProps = {};

/* Local utility functions */
const getDescription = (exercise, currentScreen) => {
  if (!exercise.intro) {
    return exercise.description[0];
  }

  return currentScreen === "intro"
    ? exercise.description[0]
    : exercise.description[1];
};

const setIcon = (exercise, index) => {
  return isArray(exercise.icon) ? exercise.icon[index] : exercise.icon;
};

const answerCorrect = (correctAnswer, userAnswer) => {
  return (
    correctAnswer.length === userAnswer.length &&
    userAnswer.reduce(
      (total, current) => total && correctAnswer.includes(current),
      true
    )
  );
};

const renderAllStepsAtOnce = (
  currentScreen,
  exercise,
  answer,
  setCurrentAnswer
) => {
  return exercise.steps[0].options.map((option, index) => (
    <div key={index}>
      <Grid.Row>
        {option.description && (
          <Text className="padded" as="h6" notr>
            {option.description}
          </Text>
        )}
      </Grid.Row>
      <Grid padded>
        {option.choices.map((choice, cindex) => (
          <Grid.Row key={cindex}>
            <Button
              className="full-width main-font"
              notr
              onClick={setCurrentAnswer(index)}
              secondary={answer[index] === cindex}
              toggle
              value={cindex}
              disabled={currentScreen === "intro"}
            >
              <p>{choice}</p>
              {exercise.icon && (
                <Image
                  key={index}
                  src={require(`Assets/icons/${exercise.icon}.png`)}
                  alt="icon"
                  floated="right"
                />
              )}
            </Button>
          </Grid.Row>
        ))}
      </Grid>
    </div>
  ));
};

const renderSteps = (
  currentScreen,
  exercise,
  currentStep,
  setCurrentAnswer,
  answer
) => {
  const { steps } = exercise;
  const choices = setChoices(exercise, currentStep);

  return (
    <div>
      <Grid.Row>
        {steps[currentStep].description && (
          <>
            <div>
              <Text as="h3" notr dan>
                {steps[currentStep].description}
              </Text>
            </div>
            <Spacer height="8px" />
          </>
        )}
      </Grid.Row>
      <Grid padded>
        {choices.map((choice, index) => (
          <Grid.Row key={index}>
            <SButton
              className="full-width main-font"
              key={index}
              notr
              icon={exercise.icon}
              onClick={setCurrentAnswer(index)}
              secondary={answer.includes(index)}
              toggle
              value={index}
              disabled={currentScreen === "intro"}
            >
              {exercise.icon && (
                <Image
                  key={index}
                  src={require(`Assets/icons/${setIcon(exercise, index)}.svg`)}
                  alt="icon"
                />
              )}
              <p>{choice}</p>
            </SButton>
          </Grid.Row>
        ))}
      </Grid>
    </div>
  );
};

const renderCustomSteps = (
  currentScreen,
  exercise,
  currentStep,
  setCurrentAnswer,
  answer
) => {
  const { customSeparator, steps } = exercise;
  const choices = setChoices(exercise, currentStep);
  const longChoice = choices.reduce((prev, cur) => {
    return prev ? prev : cur.length > 19;
  }, false);

  return (
    <div>
      <Grid.Row>
        {steps[currentStep].description && (
          <>
            <div>
              <Text as="h3" notr dan>
                {steps[currentStep].description}
              </Text>
            </div>
            <Spacer height="8px" />
          </>
        )}
      </Grid.Row>
      <Grid padded>
        {choices.map((choice, index) => (
          <>
            <Grid.Column
              key={index}
              mobile={16}
              tablet={longChoice ? 16 : 7}
              computer={longChoice ? 16 : 7}
            >
              <SButton
                className="full-width main-font"
                key={index}
                notr
                icon={exercise.icon}
                onClick={setCurrentAnswer(index)}
                secondary={answer.includes(index)}
                toggle
                value={index}
                disabled={currentScreen === "intro"}
              >
                {exercise.icon && (
                  <Image
                    key={index}
                    src={require(`Assets/icons/${setIcon(
                      exercise,
                      index
                    )}.svg`)}
                    alt="icon"
                  />
                )}
                <p>{choice}</p>
              </SButton>
            </Grid.Column>
            {choices.length - 1 > index && (
              <Grid.Column
                mobile={16}
                tablet={2}
                computer={2}
                className="center aligned"
                style={{ margin: "auto" }}
              >
                <p>{customSeparator}</p>
              </Grid.Column>
            )}
          </>
        ))}
      </Grid>
    </div>
  );
};

/* Local Styled Components */
const SButton = styled(Button)`
  align-items: center;
  display: flex !important;
  justify-content: flex-start;
  padding: ${({ icon }) => (icon ? "0px 6px" : "6 16px")} !important;
  box-shadow: ${({ icon }) =>
    icon ? "0 0 5px 0 rgba(0,0,0,0.15)" : "none"} !important;
  border: ${({ icon }) => icon && "none"} !important;
`;

export default withRouter(ListenAndPick);
