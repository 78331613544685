/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { isInteger } from 'crocks/predicates'
import { Label, Grid } from 'semantic-ui-react'

// import { } from 'prop-types'

/* Local imports */
import Text from 'Components/Text'
import PlayButton from 'Components/PlayButton'
import Spacer from 'Components/Spacer'
import { processDate, processNumber, processString } from 'Utils'
import { eqBy } from 'ramda'

/* Component definition */
const ListenAndTypeAndPick = ({ exercise, results }) => {
  return (
    <>
      <Spacer height="16px" />
      <Text className="exercise-text" notr>{exercise.description}</Text>
      <Spacer height="48px" />
      <Grid>
        {
          exercise.steps.map((step, index) =>
            {
              const typeResult = getClientTypeResult(results, index)
              const typeAnswerCorrect = isTypeAnswerCorrect(step.type.answer, typeResult, step.type.inputType)
              return (
                <Grid.Row key={index}>
                  <Grid.Column width={16}>
                    <SWrapper>
                      <Text style={{ transform: 'translate(-8px)' }} as="p" >{typeAnswerCorrect ? 'exercise.results.correctAnswer' : 'exercise.results.wrongAnswer'}</Text>
                      <Spacer height="16px" />
                      <SLabel
                        bgcolor={getTypeColor(typeResult, step.type.answer, exercise.inputType)}
                      >
                        {
                          step.type.prefix &&
                          <p>{step.type.prefix}</p>
                        }
                        <SAnswer>
                          {` ${formatClientTypeResults(typeResult)} `} 
                        </SAnswer>
                        {
                          step.type.sufix &&
                          <p>{step.type.sufix} </p>
                        }
                      </SLabel>
                      <SPlayButton exercise={exercise} stage={0} step={index} />
                    </SWrapper>
                    {
                      renderResults(setChoices(exercise, step), getClientPickResult(results, index), step.pick.answer, exercise, index)
                    }
                  </Grid.Column>
                </Grid.Row>
              )
            }
          )
        }
      </Grid>
      <Spacer height="64px" />
    </>
  )
}

/* PropTypes */
ListenAndTypeAndPick.propTypes = {}

ListenAndTypeAndPick.defaultProps = {}

/* Local utility functions */
const setChoices = (exercise, step) => {
  return exercise.choices ? exercise.choices : step.pick.choices
}

const areEqual = (correctAnswer, userAnswer) => {
  if (!userAnswer) {
    return
  }
  return correctAnswer.length === userAnswer.length
    && userAnswer.reduce((total, current) => total && correctAnswer.includes(current), true)
}

const formatClientTypeResults = (result) => {
  if (!result) {
    return
  }

  const date = result.split('/').slice(0, 3).join('/')
  const time = result.split('/').slice(3).join(':')

  return `${date} ${time}`
}

const isTypeAnswerCorrect = (correctAnswer, userAnswer, inputType) => {
  if (!userAnswer) {
    return
  }

  const comparator = inputType === 'number'
    ? processNumber
    : inputType === 'date'
      ? processDate
      : processString

  return eqBy(comparator, userAnswer, correctAnswer)
}

const getClientPickResult = (results, index) => {
  if (results && results[`step${index}`]) {
    return [results[`step${index}`][1]]
  }
  return
}

const getClientTypeResult = (results, index) => {
  console.log("Results ", results)
  if (!results) {
    return
  }

  if (results && results[`step${index}`]) {
    return results[`step${index}`][0]
  }
  return
}

const getTypeColor = (result, correctAnswer, inputType) => {
  const comparator = inputType === 'number'
    ? processNumber
    : inputType === 'date'
      ? processDate
      : processString

  if (!result) {
    return
  }

  if (eqBy(comparator, result, correctAnswer)) {
    return "rgba(81,184,157,0.45)"
  }

  return "rgba(224,0,105,0.31)"
}

const getColor = (result, correctAnswer, buttonValue) => {

  if (!result) {
    return
  }

  if (isInteger(result) && isInteger(correctAnswer)) {
    return result === correctAnswer && result === buttonValue ? "rgba(81,184,157,0.45)" :
      result !== correctAnswer && result === buttonValue ? "rgba(224,0,105,0.31)" : "transparent"
  }

  if (areEqual(correctAnswer, result) && correctAnswer.includes(buttonValue)) {
    return "rgba(81,184,157,0.45)"
  }

  if (!areEqual(correctAnswer, result) && result.includes(buttonValue)) {
    return "rgba(224,0,105,0.31)"
  }

  return "transparent"
}

const renderResults = (options, result, correctAnswer, exercise, stepIndex) => {
  const answerCorrect = areEqual(correctAnswer, result)

  return (
    <SWrapper>
      <Text style={{ transform: 'translate(-8px)' }} as="p" >{answerCorrect ? 'exercise.results.correctAnswer' : 'exercise.results.wrongAnswer'}</Text>
      <Spacer height="16px" />
      {
        options.map((option, index) => {
          const answerCorrect = getColor(result, correctAnswer, index)

          return (
            <SLabel
              key={index}
              bgcolor={answerCorrect}
            >
              <p>{option}</p>
            </SLabel>
          )
        })
      }
      <SPlayButton exercise={exercise} step={stepIndex} />
    </SWrapper>
  )
}

/* Local Styled Components */

const SPlayButton = styled(PlayButton)`
  margin: 16px 0px !important;
  margin-bottom: 32px !important;

`
const SWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin: 0px 16px;

  @media (min-width: 786px) {
    max-width: 400px;
    margin: 0 auto;
  }
`

const SLabel = styled(Label)`
  color: #000000 !important;
  background-color: ${({ bgcolor }) => bgcolor} !important;
  border: 1px solid lightgrey !important;
  margin: 4px 0px !important;
  whiteSpace: normal !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 1.5px !important;
  line-height: 16px !important;
  padding: 16px !important;
  padding-left: 40px !important;
`

const SAnswer = styled.p`
  font-weight: bold !important;
  `

export default ListenAndTypeAndPick
