/* Global imports */
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

/* Local imports */
import Text from 'Components/Text'
import Separator from 'Components/Separator'
import Button from '../components/Button'

/* Component definition */
const ProfessionalSearch = () => {
  const [permissionForMap, setPermissionForMap] = useState(false)

  useEffect(() => {
    if (permissionForMap) {
      const script = document.createElement('script')
      script.src = 'storeFinderWidget-v2.js'
      script.async = true
  
      document.body.appendChild(script)
      
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [permissionForMap])

  useEffect(()=> {
    if (localStorage.getItem('mapConditionsAccepted')) {
      setPermissionForMap(true)
    }
  }, [])
  
  const handleAcceptedConditions = () => {
    localStorage.setItem('mapConditionsAccepted', true)
    setPermissionForMap(true)
  }

  return (
    <div style={{ padding: '0px 8px'}}>
      <Text
        as="h4"
      >mainMenu.professionalSearch
      </Text>
      <Separator minWidth={0} />
      {permissionForMap ? (
        <div id="store-finder-widget"
            data-buttonbgcolor="#3B99C7"
            data-buttontextcolor="#FFFFFF"
            data-filters=""
            data-footerbgcolor="#F4F4F5"
            data-footertext="© 2019 Pro Akustik Hörakustiker GmbH & Co KG"
            data-headerbgcolor="#FFFFFF"
            data-imperialunits="false"
            data-key="XsJDByk72Bx1xRow0QldVZUhNUN2oy"
            data-language="de"
            data-linkcolor="#004967"
            data-listitembgcolor="#004967"
            data-locationpageheader=""
            data-locationpinurl=""
            data-logourl=""
            data-maxresults=""
            data-showattribution="true"
            data-showfooter="true"
            data-showheader="true"
            data-showsimilarlocations="true"
            data-showwebsite={true}
            data-showzipcity="true"
            data-textprimarycolor="#004967"
            data-title=" "
            data-useidentifier="false">
        </div>
      ) : (
        <MapPermissionsWrapper>
          <div>
            <Text>map.text</Text>
            <a href='https://uberall.com/en-gb/privacy-policy' target="_blank"><Text>map.textLink</Text></a>
          </div>
          <Button
            className="main-font accept-all-btn"
            onClick={handleAcceptedConditions}
            toggle
            primary
            style={{marginTop: '15px'}}
          >map.buttonText</Button>
        </MapPermissionsWrapper>
      )}
    </div>
  )
}

/* PropTypes */
ProfessionalSearch.propTypes = {}

ProfessionalSearch.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const MapPermissionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px;
  min-height: 400px;
  margin-top: 60px;
`

export default ProfessionalSearch
