import { useEffect, useState } from 'react'
import createUseContext from 'constate'
import userService from 'Services/user'
import * as professionalService from 'Services/professional'
import createPersistedState from 'use-persisted-state'
import { map, switchMap, tap } from 'rxjs/operators'
import { isAccessCodePlus } from 'Utils'

const useUserId = createPersistedState('userId')
const usePlus = createPersistedState('isPlus')

export default createUseContext(function useSession() {
  const [user, setUser] = useState(null)
  const [userId, setUserId] = useUserId(null)
  const [settings, setSettings] = useState(null)
  const [isPlus, setPlus] = usePlus(false)

  const login = (accessCode) => {
    setPlus(() => isAccessCodePlus(accessCode))
    return userService.login(accessCode)
      .pipe(tap(({ uid }) => setUserId(uid)))
  }

  const logout = () => {
    setUserId(null)
    return Promise.resolve()
  }

  useEffect(() => {
    const getUser = async () => {
      if (userId) {
        await userService.initFirebase()
        const subscription = userService.getUser(userId)
              .pipe(switchMap((u) => {
                return professionalService.getProfessional(u.professional_id)
                  .pipe(tap(professionalInfo => setSettings(professionalInfo)))
                  .pipe(map(() => u))
              }))
              .subscribe(setUser)
        
        return subscription.unsubscribe.bind(subscription)
      } else {
        setUser(null)
      }
    }

    getUser()

  }, [userId])

  return {
    user,
    userId,
    isPlus,
    login,
    logout,
    setPlus,
    settings,
  }
})
