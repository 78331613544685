import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'

import Routes from 'Screens'
import useSession from 'Hooks/useSession'
import useStyles from 'Hooks/useStyles'
import './App.css'
import 'semantic-ui-less/semantic.less'
import useAppVersionCheck from './hooks/useAppVersionCheck'
import CookiesState from './context/cookies/cookiesState'
import CookieBanner from './modules/cookieBanner'

function App() {
  useAppVersionCheck()

  return (
    <CookiesState>
      <useSession.Provider>
        <useStyles.Provider>
          <Router>
            <Routes />
          </Router>
        </useStyles.Provider>
      </useSession.Provider>
      <CookieBanner />
    </CookiesState>
  )
}

export default App
