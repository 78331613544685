/* Global imports */
import React from 'react'
import styled from 'styled-components'

import { func, string} from 'prop-types'
import useSession from 'Hooks/useSession'

/* Local imports */

/* Component definition */
const CancelButton = ({ className, onClick }) => {
  const { isPlus } = useSession()

  return (
    <SButton
      alt="Abbrechen"
      className={`${className} ${isPlus ? 'plusImg' : ''}`}
      onClick={onClick}
      src={require('Assets/icons/cross.svg')}
      width="48px"
    />
  )
}

/* PropTypes */
CancelButton.propTypes = {
  className: string,
  onClick:   func.isRequired,
}

CancelButton.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SButton = styled.img`
  border:    0px !important;
  cursor:    pointer;
  max-width: 48px !important;
  padding:   0px !important;
  position:  absolute;
  right:     0px;
  top: 0px;
  z-index:   2;
`
export default CancelButton
