/* Global imports */
import React, { useState, useEffect } from 'react'
import { func } from 'prop-types'
import { Grid, Responsive } from 'semantic-ui-react'
import { withRouter } from 'react-router-dom'

/* Local imports */
import { listenAndTypeShape } from 'Definitions/shapes'
import InsertHearingAids from 'Components/InsertHearingAids'
import NextButton from 'Components/NextButton'
import PlayButton from 'Components/PlayButton'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Input from 'Components/Input'
import NumberInput from 'Components/NumberInput'
import Text from 'Components/Text'
import DemoProgressBar from 'Components/DemoProgressBar'
import useStages from 'Hooks/useStages'

import DateInput from '../DateInput'

/* Component definition */
const ListenAndType = ({ exercise, onNext }) => {
  const [answer, setAnswer] = useState('')
  const [ insertScreenDisplayed, setInsertScreenDisplayed ] = useState(false)
  const [ lastStepDisplayed, setLastStepDisplayed ] = useState(false)
  const { currentStage, currentStep, next } = useStages(exercise, onNext)
  const step = exercise.stages[currentStage][currentStep]
  const hasIntermediateScreens = exercise && exercise.intermediateScreens

  const lastStepCompleted = hasIntermediateScreens && !insertScreenDisplayed && currentStage === 1 && currentStep === 9

  const goNext = () => {
    setAnswer('')
    next()
  }

  useEffect(()=> {
    window.scrollTo(0, 0)
  },[currentStep])

  useEffect(()=> {
    if(currentStep === 1){
      setInsertScreenDisplayed(false)
    }
  },[currentStep])
  useEffect(()=> {
    if(currentStep === 1){
      setInsertScreenDisplayed(false)
    }
  },[currentStep])


  if (hasIntermediateScreens && !insertScreenDisplayed && currentStage === 0 | 1  && currentStep === 0) {
    const message = currentStage === 0 ? 'Bitte führen Sie die folgende Übung mit Ihrem Hörsystem durch.' : 'Bitte führen Sie die folgende Übung ohne Ihr Hörsystem durch.'

    return <InsertHearingAids onNext={() => setInsertScreenDisplayed(true)} message={message}/>
  }

  if ( hasIntermediateScreens && lastStepDisplayed && currentStage === 1 && currentStep === 9) {
    const message = 'Bitte führen Sie die folgende Übung mit Ihrem Hörsystem durch.'

    return <InsertHearingAids 
              lastStepDisplayed={lastStepDisplayed}
              onNext={() => setInsertScreenDisplayed(true)} 
              message={message} 
              goNext={goNext}
            />
  }

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4" notr>Übung {exercise.number}</Text>
          <Separator minWidth={992} />
        </Grid.Column>
        <Spacer height="16px" />
        <Grid.Column mobile={16} computer={8}>
          <Text as="h5" notr>{exercise.title}</Text>
          <Text  className="exercise-text" notr>{exercise.description}</Text>
          <Spacer height="16px" />
          <Grid>
            <Grid.Row>
              <Grid.Column mobile={16} computer={8}>
                <PlayButton
                  allowedReplays={2}
                  autoplay={currentStep > 0 ? 1 : 0}
                  className="full-width"
                  exercise={exercise}
                  scrollOnLoad
                  scrollOnPlay
                  stage={currentStage}
                  step={currentStep}
                />
                <DemoProgressBar currentStep={currentStep} total={exercise.stages[currentStage].length}/>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Grid.Column>
        <Grid.Column mobile={16} computer={8}>
          <Responsive minWidth={992}>
            <Text as="h5">exercise.selectOption</Text>
            <Spacer height="42px" />
          </Responsive>
          <Grid.Row>
            {
              renderInput({
                exercise,
                step,
                answer,
                onChange: (e, { value }) => setAnswer(value),
              })
            }
          </Grid.Row>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <NextButton
          className="bottom full-width pos-fixed btn-next-disabled"
          disabled={isEmpty(answer, exercise.inputType)}
          onClick={() => !lastStepCompleted ? goNext() : setLastStepDisplayed(true) }
        >
          exercise.buttons.next
        </NextButton>
      </Grid.Row>
      <Spacer height="68px" />
    </Grid>
  )
}

/* PropTypes */
ListenAndType.propTypes = {
  exercise: listenAndTypeShape,
  onNext: func.isRequired,
}

ListenAndType.defaultProps = {}

/* Local utility functions */
const isEmpty = (answer, type) => {
  return type === 'number'
    ? answer
      .replace('.', '')
      .split('')
      .every(c => c === '0')
    : !answer
}
const renderInput = ({ exercise, step, answer, onChange }) => {

  switch (exercise.inputType) {
    case 'number':
      return (
        <NumberInput
          mask={step.answer}
          className="full-width increase"
          labelClass="input-label"
          onChange={onChange}
          placeholder="exercise.placeholder.answer"
          prefix={step.prefix}
          sufix={step.sufix}
          value={answer}
        />
      )
    case 'date':
      return (
        <DateInput
          format={step.answer}
          className="full-width increase"
          labelClass="input-label"
          onChange={onChange}
          placeholder="exercise.placeholder.answer"
          value={answer}
        />
      )
    default:
      return (
        <Input
          className="full-width increase"
          labelClass="input-label increase"
          onChange={onChange}
          placeholder="exercise.placeholder.answer"
          prefix={step.prefix}
          sufix={step.sufix}
          value={answer}
        />
      )
  }
}

/* Local Styled Components */

export default withRouter(ListenAndType)
