/* Global imports */
import React from 'react'
import styled from 'styled-components'

// import { } from 'prop-types'
import { Grid, Image } from 'semantic-ui-react'

/* Local imports */
import { RESULTS } from 'Definitions/routes'
import NextButton from 'Components/NextButton'
import Text from 'Components/Text'
import Spacer from 'Components/Spacer'
import Separator from 'Components/Separator'
import useSession from 'Hooks/useSession'
import IntroductionTexts from '../ExercisesPlus/OPTIONAL_EXERCISE/IntroductionTexts'

/* Component definition */
const FinalMessage = ({ history, match, currentExercise, nextExercise, onNext, optional }) => {
  const { isPlus } = useSession()
  const { dayIndex } = match.params

  console.log('FINAL MESSAGE')
  const exerciseIndexesBeforeReviewExercise = isPlus ? [2, 8, 17, 26, 31, 39] : [14, 23, 32, 43]

  return (
    <Grid padded>
      <Grid.Row>
      <Grid.Column width={16}>
          <Text as="h4" className={isPlus ? 'plusText' : 'primaryText'} notr>{optional ? `Zusatzübung  ${currentExercise.letter}` : `Übung ${currentExercise.index + 1 }`}</Text>
          <Separator minWidth={768}/>
        </Grid.Column>
        <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'center' }}>
          <SContentWrapper>
            <Image
              alt="login-img"
              max-width="517px"
              style={{ padding: '8px' }}
              src={require('Assets/images/finish-exercise.svg')}
            />
            <SWrapper>
              <Spacer height="10px"/>
              {
                exerciseIndexesBeforeReviewExercise.includes(currentExercise.index) ?
                <>
                  <Text as="p" style={{ textAlign: 'center' }}notr>
                    {isPlus ? (IntroductionTexts[dayIndex].introductionText) : 
                      "Ihr Trainingstag ist zu Ende. Sie haben Lust auf eine zusätzliche Herausforderung? Die folgenden Zusatzübungen trainieren nicht nur Ihr Hörverstehen, sondern fragen gleichzeitig auf unterhaltsame Weise Wissen aus verschiedenen Themenbereichen ab." 
                    }
                  </Text>
                  <SNextButton
                    className="full-width rounded message-btn"
                    onClick={() => onNext()}
                  >
                    exercise.success.additionalExercise
                  </SNextButton>
                  <Spacer height="8px"/>
                  <SNextButton
                    className="full-width rounded login-btn"
                    onClick={() => history.push(RESULTS(dayIndex, 0))}
                    >
                    exercise.success.results
                  </SNextButton>
                </>
                :
                <div>
                  <Text as="h5" style={{ textAlign: 'center'}} notr>{optional ? `Zusatzübung  ${nextExercise.letter}` : `Übung ${nextExercise.index + 1 }`}</Text>
                  <Text as="p" notr dangerouslySetInnerHTML={{__html: nextExercise.description[0]}}></Text>
                  <SNextButton
                    className={`full-width rounded message-btn ${isPlus ? 'plus' : ''}`}
                    notr
                    onClick={() => onNext()}
                  >
                    Nächste Übung
                  </SNextButton>
                </div>
              }
            </SWrapper>
          </SContentWrapper>
        </Grid.Column>
      </Grid.Row>
  </Grid>
  )
}

/* PropTypes */
FinalMessage.propTypes = {}

FinalMessage.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SContentWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width : 500px !important;
`
const SWrapper = styled.div`
  @media (min-width: 992px) {
    max-width: 360px;
  }
`
const SNextButton = styled(NextButton)`
@media (min-width: 768px) {
  height: 44px !important;
}
`

export default FinalMessage
