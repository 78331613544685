export default [
  {
    introductionText: "Ihr Trainingstag ist zu Ende. Sie haben Lust auf eine zusätzliche Herausforderung? Die folgenden Zusatzübungen trainieren nicht nur Ihr Hörverstehen, sondern fragen gleichzeitig auf unterhaltsame Weise Wissen aus verschiedenen Themenbereichen ab."
  },
  {},
  {
    introductionText: "Ihr Trainingstag ist zu Ende. Sie haben Lust auf eine zusätzliche Herausforderung? Die folgende Übung trainiert nicht nur Ihr Hörverstehen, sondern fragt gleichzeitig auf unterhaltsame Weise Wissen ab."
  },
  {},
  {},
  {
    introductionText: "Ihr Trainingstag ist zu Ende. Sie haben Lust auf eine zusätzliche Herausforderung? Die folgenden Zusatzübungen trainieren nicht nur Ihr Hörverstehen, sondern fragen gleichzeitig auf unterhaltsame Weise Wissen aus verschiedenen Themenbereichen ab."
  },
  {},
  {},
  {
    introductionText: "Ihr Trainingstag ist zu Ende. Sie haben Lust auf eine zusätzliche Herausforderung? Die folgende Übung trainiert nicht nur Ihr Hörverstehen, sondern fragt gleichzeitig auf unterhaltsame Weise Wissen ab. Hinweis: Diese Übung ist sehr anspruchsvoll. Sie können drei Dialekte einwandfrei zuordnen: Sehr gute Leistung! Sie schaffen sogar mehr als drei? Gratulation! Im Bereich deutsche Dialekte verfügen Sie über Expertenwissen!"
  },
  {},
  {
    introductionText: "Ihr Trainingstag ist zu Ende. Sie haben Lust auf eine zusätzliche Herausforderung? Die folgende Übung trainiert nicht nur Ihr Hörverstehen, sondern fragt gleichzeitig auf unterhaltsame Weise Wissen ab."
  },
  {},
  {},
  {
    introductionText: "Ihr Trainingstag ist zu Ende. Sie haben Lust auf eine zusätzliche Herausforderung? Die folgenden Zusatzübungen trainieren nicht nur Ihr Hörverstehen, sondern fragen gleichzeitig auf unterhaltsame Weise Wissen aus verschiedenen Themenbereichen ab."
  }
]