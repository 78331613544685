import React, { useContext, useEffect, useState } from "react";
import Button from "../components/Button";
import DC_Bakery from "../utils/dc_bakery"
import cookiesContext from "../context/cookies/cookiesContext";
import styled from 'styled-components'
import Text from 'Components/Text'
import { initializeFirebaseAnalytics } from "..";
import userService from 'Services/user'

DC_Bakery.__init();
const CookieBanner = () => {
  const { cookies, cookiesSetted, updateCookies, cookiesSettedTo } = useContext(
    cookiesContext
  );
  const [selection, setSelection] = useState(cookies);

  const typesOfCookies = [
    "functionalCookies",
    "statisticalCookies",
  ];

  useEffect(() => {
    if (typeof window !== "undefined") {
      cookiesSettedTo(cookieExists("cookieConsent"));
    }
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!cookiesSetted) {
      DC_Bakery.flush();
      if (cookieExists('statisticalCookies')) {
        userService.initFirebase()
        .then(() => {
          initializeFirebaseAnalytics()
        })
      }
    }
  }, [cookiesSetted]);

  const changeSelection = index => {
    let state = [...selection];
    state[index] = !state[index];
    setSelection(state);
  };

  const writeCookie = (selectAll = false) => {
    let selectedCookies = selection;
    if (selectAll) {
      selectedCookies = [true, true, true];
    }

    createCookie("cookieConsent");
    cookiesSettedTo(true);

    selectedCookies.forEach((cookie, index) => {
      if (cookie) {
        return createCookie(typesOfCookies[index]);
      } else {
        return deleteCookie(typesOfCookies[index]);
      }
    });
    DC_Bakery.clean();
    updateCookies(selectedCookies);
  };

  const createCookie = cookie => {
    const cookieDate = new Date();
    cookieDate.setFullYear(cookieDate.getFullYear() + 1);
    return (document.cookie = `${cookie}=true; expires=${cookieDate.toUTCString()}; path=/;`);
  };
  const deleteCookie = cookie => {
    return (document.cookie = `${cookie}=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;`);
  };

  const cookieExists = cookie => {
    return Boolean(document.cookie) && document.cookie.indexOf(cookie) > -1;
  };

  return cookiesSetted !== null && !cookiesSetted ? (
    <Container className="cookieBanner">
      <div className="cookieBannerInner">
        <div className="headline"><Text>cookies.title</Text></div>
        <div className="text">
          <Text>cookies.text.firstPart</Text><a href="/data-protection"><Text>cookies.text.dataPrivacyLink</Text></a>. <Text>cookies.text.secondPart</Text>
        </div>
        <form className="formWrapper">
          {typesOfCookies.map((type, index) => (
            <label className="checkboxLabel" key={index} htmlFor={type}>
              <div className="checkboxWrapper">
                <input
                  name={type}
                  id={type}
                  type="checkbox"
                  disabled={index === 0}
                  checked={selection[index]}
                  onChange={() => changeSelection(index)}
                />
                <span className="slider"></span>
              </div>
              <Text>{`cookies.options.${type}`}</Text>
            </label>
          ))}
        </form>
        <div className="buttonContainer">
          <Button
              className="full-width main-font accept-selected-btn"
              onClick={() => writeCookie(false)}
              secondary
              toggle
            >
              cookies.buttons.acceptSelected
            </Button>
          <Button
            className="full-width main-font accept-all-btn"
            onClick={() => writeCookie(true)}
            toggle
            primary
          >
            cookies.buttons.acceptAll
          </Button>
        </div>
      </div>
    </Container>
  ) : null;
};

CookieBanner.propTypes = {};

CookieBanner.defaultProps = {};

const Container = styled.div`
  margin:    0 auto;
  max-width: 1300px;
`

export default CookieBanner;
