/* Global imports */
import React, { useEffect, useState } from 'react'
import { Accordion } from 'semantic-ui-react'
import styled from 'styled-components'


/* Local imports */
import Text from 'Components/Text'
import Separator from 'Components/Separator'
import { toggleInList } from 'Utils'
import useWindowSize from 'Hooks/useWindowSize'
import image from 'Assets/images/our_products_tmp.jpeg'
import imageHdO from 'Assets/images/HdO.jpeg'
import imageidO from 'Assets/images/idO.jpeg'

const MOBILE_WIDTH = 425

/* Component definition */
const OurProducts = () => {
  const [active, setActive] = useState([0, 1, 2, 3])
  const { width } = useWindowSize()

  useEffect(() => {
    if (width > MOBILE_WIDTH) {
      setActive([0, 1, 2, 3])
    } else {
      setActive([0])
    }
  }, [width])

  const handleClick = (e, { index }) => {
    if (width > MOBILE_WIDTH) {
      setActive(toggleInList(index)(active))
    } else {
      setActive([index])
    }
  }

  return (
    <div style={{ padding: '0px 8px'}}>
      <Text as="h4">ourProducts.title</Text>
      <Separator minWidth={0}/>

      <Accordion fluid styled exclusive={false}>
        <Accordion.Title index={0} onClick={handleClick}>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <SText notr as="h5">HdO-Geräte</SText>
            {
              active.includes(0) ?
              <img alt="arrow-up" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
              :
              <img className="arrow-down" alt="arrow-down" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
            }
          </div>
        </Accordion.Title>

        <Accordion.Content active={active.includes(0)}>
          <div style={{ textAlign: 'center', marginBottom: '32px' }}>
            <SImage alt="Unsere Produkte" src={imageHdO} />
          </div>
          <Text notr>
              Hinter-dem-Ohr-Geräte (HdO) haben ihren Namen von dem kleinen Gehäuse, das hinter dem Ohr getragen wird. In ihm sind nicht nur Batterie oder Akku untergebracht, sondern auch Mikrofone und Lautsprecher. Über einen Schallschlauch werden die akustischen Reize verstärkt in den Gehörgang geleitet. HdOs sind für jeden Grad von Hörverlust einsetzbar.
          </Text>
        </Accordion.Content>

        <Accordion.Title index={1} onClick={handleClick}>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <SText notr as="h5">RIC-Geräte</SText>
            {
              active.includes(1) ?
              <img alt="arrow-up" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
              :
              <img className="arrow-down" alt="arrow-down" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
            }
          </div>
        </Accordion.Title>

        <Accordion.Content active={active.includes(1)}>
          <div style={{ textAlign: 'center', marginBottom: '32px' }}>
            <SImage alt="Unsere Produkte" src={image} />
          </div>

          <Text notr>
          Bei Receiver-In-The-Channel-Geräten (RIC) sitzt das Mikrofon in einem kleinen Gehäuse hinter dem Ohr, der Lautsprecher sitzt im Ohr. Verbunden sind beide über ein dünnes Kabel. Da der Lautsprecher kurz vor dem Trommelfell im Gehörgang sitzt, gibt es keine Übertragungsverluste. Durch die Trennung von Mikrofon und Lautsprecher ist das Gerät, das hinter dem Ohr getragen wird, weitaus kleiner als beim HdO. RIC-Geräte eignen sich für leichte bis hohe Hörverluste.
          </Text>
        </Accordion.Content>

        <Accordion.Title index={2} onClick={handleClick}>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <SText notr as="h5">IO-Geräte</SText>
            {
              active.includes(2) ?
              <img alt="arrow-up" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
              :
              <img className="arrow-down" alt="arrow-down" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
            }
          </div>
        </Accordion.Title>

        <Accordion.Content active={active.includes(2)}>
          <div style={{ textAlign: 'center', marginBottom: '32px' }}>
            <SImage alt="Unsere Produkte" src={imageidO} />
          </div>

          <Text notr>
          Im-Ohr-Geräte (IO) werden im Gehörgang oder in der Ohrmuschel getragen. Die gesamte Technik befindet sich in dem Gehäuse, das mit einer individuelle Ohrabformung perfekt Ihrem Gehörgang angepasst wird. Dadurch haben IO-Geräte gute Übertragungseigenschaften ohne Klangeinbußen.
          </Text>
          <br/>
          <br/>
          <Text notr>
          Hier suchen Sie nach einem Pro Akustik Mitglied in Ihrer Nähe:
          </Text>
          <br/>
          <br/>
          <Text notr>
          Unseren Online-Shop besuchen Sie unter:
          </Text>
          <br/>
          <br/>
          <a href="https://proakustik-hoergeraete.de/produkte" target="_blank" rel="noopener noreferrer">https://proakustik-hoergeraete.de/produkte</a>
        </Accordion.Content>

        <Accordion.Title index={3} onClick={handleClick}>
          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
            <SText notr as="h5">AO-Geräte</SText>
            {
              active.includes(3) ?
              <img alt="arrow-up" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
              :
              <img className="arrow-down" alt="arrow-down" src={require('Assets/icons/arrow-up.svg')} height="32px" width="32px" />
            }
          </div>
        </Accordion.Title>

        <Accordion.Content active={active.includes(3)}>
          <Text notr>
          <span style={{ fontWeight: 'bold' }}>Sie möchten mehr erfahren? Für eine</span> kostenlose und unverbindliche Beratung kontaktieren einen unserer kompetenten Pro Akustik Kollegen oder besuchen Sie unseren Online-Shop und stöbern Sie nach Ihrem Wunschgerät.
          <br/>
          <br/>
          <a href="https://www.proakustik.de/pro-akustiker-suche/#!" target="_blank" rel="noopener noreferrer">Hier</a> suchen Sie nach einem Pro Akustik Mitglied in Ihrer Nähe:
          <br/>
          <br/>
          Unseren Online-Shop besuchen Sie unter: <a href="https://www.proakustik-hoergeraete.de" target="_blank" rel="noopener noreferrer">www.proakustik-hoergeraete.de</a> 
          </Text>
        </Accordion.Content>
      </Accordion>
    </div>
  )
}

/* PropTypes */
OurProducts.propTypes = {}

OurProducts.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

const SImage = styled.img`
  border-radius: 100%;
  border: 10px solid #FAFAFA;
  height: 300px;
  width: 300px;
`

const SText = styled(Text)`
  margin: 0px !important;
`

export default OurProducts
