/* Global imports */
import React, { useState } from 'react'
import { func, bool } from 'prop-types'
import { Image, Menu, Responsive, Dropdown } from 'semantic-ui-react'
import { isNil } from 'crocks/predicates'
import ifElse from 'crocks/logic/ifElse'
import { withRouter } from 'react-router-dom'
import styled from 'styled-components'

/* Local imports */
import * as ROUTES from 'Definitions/routes'
import useSession from 'Hooks/useSession'
import useWindowSize from 'Hooks/useWindowSize'
import BurguerMenu from 'Components/BurguerMenu'
import Text from 'Components/Text'

/* Component definition */
const MainMenu = ({ history, location, onToggleMenu, menuItems, visible }) => {
  const { logout, user, isPlus, setPlus } = useSession()
  const { width } = useWindowSize()
  const [ activeItem, setActiveItem ] = useState()
  const logoUrl = require('Assets/images/logo.svg')
  const exerciseRouteRegex = /^\/training-day\/[0-9]+\/exercise|optional|review-exercise\/*[0-9]*/
  const burguerMenuUrl = visible ? require('Assets/icons/cross.svg') : require('Assets/icons/menu.svg')

  const onLogout = () => {
    logout()
      .then(console.log.bind(null, 'Logging out...'))
      .then(() => { 
        history.push({
          pathname: ROUTES.LOGIN(),
          state: { isPlus }
        })
        setPlus(false)
      })
  }

  const goTo = (path, plus) => {
    setActiveItem(plus ? `${path}+` : path)
    history.push({ 
      pathname: path, 
      state: { isPlus: plus},
    })
  }

  if(exerciseRouteRegex.test(location.pathname)){
    return null
  }

  return (
    <Wrapper width={width}>
      <Menu pointing secondary>
        <Menu.Item
          content={<Image alt="Logo" className="menu-logo" src={logoUrl}/>}
          onClick={() => onToggleMenu(true) | history.push(ROUTES.HOME())}
          style={{ alignSelf: 'center', padding: '0px' }}
        />
        <Menu.Menu position="right">
          <Responsive style={{ display: 'flex'}} minWidth={1065}>
            {
              getVisibleItems(user, menuItems)
                .map(item => (
                    <Menu.Item
                      active={activeItem === item.to}
                      name={item.to}
                      key={item.to}
                    >
                      <Text style={{ fontFamily: 'OfficinaSansITCPro-Bold' }} as="h6" onClick={() => goTo(item.to)}>
                        { isPlus && item.titlePlus ? item.titlePlus : item.title}
                      </Text>
                    </Menu.Item>
                ))
            }
            { renderSessionButton(activeItem, onLogout, goTo)(user) }
          </Responsive>
        </Menu.Menu>
        <Responsive maxWidth={1064}>
          <Menu.Menu position="right">
            <Menu.Item
              content={<BurguerMenu alt="menu" src={burguerMenuUrl} isOpen={visible}/>}
              onClick={() => onToggleMenu(false)}
              style={{padding: '0px'}}
            />
          </Menu.Menu>
        </Responsive>
      </Menu>
    </Wrapper>
  )
}

/* PropTypes */
MainMenu.propTypes = {
  onToggleMenu: func,
  visible:      bool,
}

MainMenu.defaultProps = {}

/* Local utility functions */
const renderSessionButton = (activeItem, logout, goTo) => ifElse(
  isNil,
  (e) =>  <Menu.Item>
            <Dropdown text={<Text as="h6" style={{ fontFamily: 'OfficinaSansITCPro-Bold' }}>mainMenu.login</Text>}>
              <Dropdown.Menu>
                <Dropdown.Item
                  active={activeItem === "/login"}
                  name="login"
                  key="login"
                >
                  <Text as="h6" className='primaryText' style={{ fontFamily: 'OfficinaSansITCPro-Bold' }} onClick={() => goTo(ROUTES.LOGIN())}>mainMenu.login</Text>
                </Dropdown.Item>
                <Dropdown.Item
                  active={activeItem === "/login+"}
                  name="loginPlus"
                  key="loginPlus"
                >
                  <Text as="h6" className='plusText' style={{ fontFamily: 'OfficinaSansITCPro-Bold' }} onClick={() => goTo(ROUTES.LOGIN(), true)}>mainMenu.loginPlus</Text>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Item>,
  () => <Menu.Item
          active={activeItem === "/logout"}
          name="logout"
          key="logout"
        >
          <Text as="h6" style={{ fontFamily: 'OfficinaSansITCPro-Bold' }} onClick={logout}>mainMenu.logout</Text>
        </Menu.Item>,
)

const getVisibleItems = (user, items) => {
  if (user) {
    return items.filter(i => !i.sessionHide)
  }

  return items.filter(x => !x.session)
}

/* Local Styled Components */
const Wrapper = styled.div`
  background-color: white;
  cursor: pointer;
  padding-bottom:    16px;
  padding-top: ${({ width }) => width >= 992 ? '16px' : '0px'};
  position:         fixed;
  top:              0;
  width:            100%;
  z-index:          1;
`

export default withRouter(MainMenu)
