/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { Image, Button } from 'semantic-ui-react'
import I18n from 'Common/i18n'

import { string, func } from 'prop-types'

/* Local imports */

/* Component definition */
const BurguerMenu = ({ alt, className, onClick, src, isOpen}) => {

  return (
    <React.Fragment>
      {isOpen ? (
        <SBurguerMenu
          alt={alt}
          className={`${className}`}
          onClick={onClick}
          src={src}
        />
      ) : (
        <SBurguerMenuButton
          alt={alt}
          className={`${className} topMenu`}
          onClick={onClick}
          src={src}
        >
          {I18n.t('mainMenu.menu')}
        </SBurguerMenuButton>
      )}
    </React.Fragment>
    

    
  )
}

/* PropTypes */
BurguerMenu.propTypes = {
  className: string,
  onClick:   func,
}

BurguerMenu.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

const SBurguerMenu = styled(Image)`
  height: 48px !important;
  width:  48px !important;
`

const SBurguerMenuButton = styled(Button)`
  background-color: transparent;
`

export default BurguerMenu
