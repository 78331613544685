import { useEffect } from 'react'
import createStyleContext from 'constate'
import createPersistedState from 'use-persisted-state'

const useFontSizeFactor = createPersistedState('fontSizefactor')

export default createStyleContext(function useStyles() {
  const [fontSizeFactor, setFontSizeFactor] = useFontSizeFactor(1)

  useEffect(() => {
    setFontSizeFactor(fontSizeFactor)
  },[fontSizeFactor, setFontSizeFactor])
  
  return {
    fontSizeFactor,
    setFontSizeFactor
  }
})