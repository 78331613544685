/* Global imports */
import React, { useEffect, useState } from 'react'
import { isEmpty } from 'crocks/predicates'
import { findIndex, map, pipe, reduce } from 'ramda'
import { Grid, Progress, Responsive, Segment, Label } from 'semantic-ui-react'

// import { } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
import { EXERCISE, OPTIONAL_EXERCISE, OPTIONAL_RESULTS, RESULTS, TRAINING_OVERVIEW } from 'Definitions/routes'
import {
  getCompletedStepCount,
  getStepCount,
  isDayCompleted,
  isExerciseCompleted,
}
  from 'Utils'
import Comment from 'Components/Comment'
import NextButton from 'Components/NextButton'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import useResults from 'Hooks/useResults'
import useSession from 'Hooks/useSession'
import exerciseStandard from '../Exercises'
import exercisesPlus from '../ExercisesPlus'
import optionalExercises from '../ExercisesPlus/OPTIONAL_EXERCISE'

/* Component definition */
const TrainingDay = ({ history, match }) => {
  const { user, isPlus } = useSession()
  const { startDay: startDayStandard = 1, startDayPlus = 1 } = user || {}
  const { dayIndex } = match.params
  const { results } = useResults()
  const [loading, setLoading] = useState(true)
  const [writeComment, setWriteComment] = useState(false)
  const [dayCompleted, setDayCompleted] = useState(false)
  const exercises = isPlus ? exercisesPlus : exerciseStandard
  const startDay = isPlus ? startDayPlus : startDayStandard

  const availableDays = exercises.slice(startDay - 1)

  const getProgressForDay = getDailyProgress(results, availableDays)

  useEffect(() => {
    if (!isEmpty(results)) {
      setDayCompleted(isDayCompleted(dayIndex, exercises, results))
      setLoading(false)
    }

  }, [dayIndex, results, exercises])

  const nextExerciseIndex = getFirstNonCompleted(exercises[dayIndex], results)

  return (
    <>
   { 
    writeComment ?
    <Comment
      dayIndex={dayIndex}
      history={history}
      setWriteComment={setWriteComment}/>
    :
    <Grid padded>
        <SBackButtonWrapper
          onClick={() => history.push(TRAINING_OVERVIEW())}
        >
          <img className={isPlus ? 'plusImg' : ''} alt="arrow" src={require('Assets/icons/arrow-left.svg')} height="32px" width="32px" />
          <SButton><Text className={isPlus ? 'plusText' : 'primaryText'}>trainingOverview.back</Text></SButton>
        </SBackButtonWrapper>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment disabled={false}>
              <Grid padded>
                <Grid.Column mobile={16} computer={8}>
                  <Text className={isPlus ? 'plusText' : ''} as="h6" style={{ color: '#41B094' }} vars={{ dayNumber: parseInt(dayIndex) + 1 }}>trainingOverview.titleDay</Text>
                </Grid.Column>
                <Grid.Column mobile={16} computer={8}>
                  <Responsive minWidth={992}>
                    <Text className={isPlus ? 'plusText' : 'primaryText'} as="h6">trainingOverview.progress</Text>
                    <Spacer height="16px" />
                  </Responsive>
                  <div style={{ textAlign: 'center' }}>
                    <Progress
                      autoSuccess
                      percent={getProgressForDay(dayIndex - (startDay - 1))}
                      size="tiny"
                      indicating
                    />
                    <Text className="caption" as="p" notr>{`${getProgressForDay(dayIndex- (startDay - 1))} %`}</Text>
                  </div>
                </Grid.Column>
              </Grid>
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Spacer height={'8px'} />
        <Grid.Row>
          <Grid.Column width={16}>
            <Text className={isPlus ? 'plusText' : 'primaryText'} as="h4">trainingDay.title</Text>
            <Separator minWidth={0} />
          </Grid.Column>
          <Spacer height={'16px'} />
          <Grid.Column width={16}>
            {
              exercises[dayIndex]
                .map((exercise, index) => {
                  const completedSteps = getCompletedStepCount(exercise, results)
                  const totalSteps = getStepCount(exercise)

                  return (
                    <SSegment className={isPlus ? 'plus' : ''} key={index}>
                      <div
                        key={index}
                        onClick={() => history.push(isExerciseCompleted(exercise, results) ? RESULTS(dayIndex, index) : EXERCISE(dayIndex, index))}
                      >
                        <Grid padded>
                          <STopWrapper >
                            <Text className={isPlus ? 'plusText caption-blue' : 'primaryText caption-blue' } notr>Übung {exercise.index + 1} </Text>
                            <Text notr>
                              <SLabel className={isPlus ? 'plus' : ''} exercisecompleted={(completedSteps === totalSteps).toString()}>
                                {completedSteps}
                                <span>&nbsp;</span>
                                von
                                <span>&nbsp;</span>
                                {totalSteps}
                              </SLabel>
                            </Text>
                          </STopWrapper>
                          <Grid.Row>
                            <Text as="p" style={{ paddingLeft: '8px' }} notr> {exercise.title} </Text>
                          </Grid.Row>
                        </Grid>
                      </div>
                    </SSegment>
                  )
                })
            }
          </Grid.Column>
          <Spacer height="8px" />
          <Grid.Column width={16}>
          {
            (dayCompleted && isPlus) &&
            optionalExercises[dayIndex]
              .map((exercise, index) => {
                const completedSteps = getCompletedStepCount(exercise, results)
                const totalSteps = getStepCount(exercise)

                return (
                  <SSegment className={'optional'} key={index}>
                    <div
                      key={index}
                      onClick={() => history.push(isExerciseCompleted(exercise, results) ? OPTIONAL_RESULTS(dayIndex, index)
                        : OPTIONAL_EXERCISE(dayIndex, index))}
                    >
                      <Grid padded>
                        <STopWrapper >
                          <Text className={isPlus ? 'plusText caption-blue' : 'caption-blue' } notr>Zusatzübung {exercise.letter} </Text>
                          <Text notr>
                            <SLabel className={isPlus ? 'plus' : ''} exercisecompleted={(completedSteps === totalSteps).toString()}>
                              {completedSteps}
                              <span>&nbsp;</span>
                              von
                              <span>&nbsp;</span>
                              {totalSteps}
                            </SLabel>
                          </Text>
                        </STopWrapper>
                        <Grid.Row>
                          <Text as="p" style={{ paddingLeft: '8px' }} notr> {exercise.title} </Text>
                        </Grid.Row>
                      </Grid>
                    </div>
                  </SSegment>
                )
              })
          }
          </Grid.Column>
          <Spacer height="8px" />
          <SBottomButton
            className={`full-width rounded login-btn ${isPlus ? 'plus' : ''}`}
            onClick={() => setWriteComment(true)}
          >
            <Text as="p" style={{ textAlign: 'center', paddingTop: '4px'}}>exercise.success.leaveComment</Text>
          </SBottomButton>
          <Spacer height="64px" />
          {
            !loading &&
            renderContinueButton(history, dayIndex, dayCompleted, nextExerciseIndex, isPlus)
          }
        </Grid.Row>
    </Grid>
    }
    </>
  )
}

/* PropTypes */
TrainingDay.propTypes = {}

TrainingDay.defaultProps = {}

/* Local utility functions */
const getFirstNonCompleted = (exercises, results) =>
  findIndex(e => !isExerciseCompleted(e, results), exercises)

const renderContinueButton = (history, dayIndex, dayCompleted, nextExerciseIndex, isPlus) => {
  if (dayCompleted) {
    return <NextButton
      className={`full-width pos-fixed ${isPlus ? 'plus' : ''}`}
      onClick={() => history.push(EXERCISE(dayIndex, 0))}
    >
      trainingDay.button.retry
           </NextButton>
  }

  return <NextButton
    className={`full-width pos-fixed ${isPlus ? 'plus' : ''}`}
    onClick={() => history.push(EXERCISE(dayIndex, nextExerciseIndex))}
  >
    trainingDay.button.continue
         </NextButton>
}

const getDailyProgress = (results, days) => {
  const countAnsweredSteps = day => day.reduce(
    (total, exercise) => total + getCompletedStepCount(exercise, results),
    0
  )

  const getProgress = map(countAnsweredSteps)

  const exerciseCount = pipe(
    map(reduce((t, exercise) => {
      return t + getStepCount(exercise)
    }, 0)),
  )(days)

  return index => {
    const progress = getProgress(days)[index] * 100 / exerciseCount[index]

    return progress.toFixed(0)
  }
}

/* Local Styled Components */
const SSegment = styled(Segment)`
  background-color: #EEF5F3 !important;
  border:           none !important;
  box-shadow:       none !important;
  cursor:           pointer;
  height:           auto !important;
`

const SButton = styled.button`
  background-color: transparent !important;
  border: 0 !important;
  color: #0082BA !important;
  font-weight: bold !important;
  height: 32px !important;
  letter-spacing: 1.5px !important;
  line-height: 21px !important;
  margin: 0 !important;
  padding: 0 !important;
  padding-top: 4px !important;
  text-transform: uppercase !important;
`
const SBackButtonWrapper = styled.div`
  cursor: pointer;
  display: flex;
  padding-bottom: 8px;

  @media (max-width: 768px) {
    top: 40px !important;
  }
`

const STopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width 100% !important;
  padding: 8px 0px !important;
  padding-left: 8px !important;
`

const SLabel = styled(Label)`
  background-color: ${({ exercisecompleted }) => exercisecompleted === 'true' ? '#51B89D' : 'transparent' } !important;
  border-radius: 12px !important;
  border: 1px solid #51B89D !important;
  color: ${({ exercisecompleted }) => exercisecompleted === 'true' ? 'white' : '#51B89D' } !important;
  min-width: 93px;
  text-align: center;
`

const SBottomButton = styled.button`
background-color: transparent;
border-radius: 4px;
border: 1px solid #0082BA;
color: #000000;
display: flex;
align-items: center !important;
font-family: OfficinaSansITCPro-Bold !important;
font-weight: bold;
height: 36px;
letter-spacing: 1.5px;
line-height: 36px;
margin: 0 auto !important;
text-align: center;
text-transform: uppercase;
width: fit-content;
`
export default TrainingDay
