/* Global imports */
import React, { useRef, useState } from "react";
import { func } from "prop-types";
import styled from "styled-components";
import ReactSwipe from "react-swipe";

/* Local imports */
import Text from "Components/Text";
import Board1 from "Assets/images/Board_1@2x.png";
import Board2 from "Assets/images/Board_2@2x.png";
import Board3 from "Assets/images/Board_3@2x.png";
import Board4 from "Assets/images/Board_4@2x.png";
import Board5 from "Assets/images/Board_5@2x.png";
import Board6 from "Assets/images/Board_6@2x.png";
import Board7 from "Assets/images/Board_7@2x.png";
import DotProgress from "Components/DotProgress";

const defaultSteps = [
  {
    image: Board7,
    text: "Das Pro Akustik Hörtraining ist eine Trainingsmethode zur aktiven Umstellung auf Ihre neue Hörsituation mit Hörsystemen. Damit wir das, was wir hören, auch verstehen, werden über die Nervenverbindungen der Hörbahn akustische Reize an das Hörzentrum im Gehirn weitergeleitet. Erst dort werden sie interpretiert und verstanden, z.B. als Sprache. Damit diese Nervenverbindungen aktiv bleiben, brauchen sie kontinuierliche Anregung. Unser Pro Akustik Hörtraining unterstützt Sie bei diesem Prozess. Das Pro Akustik Hörtraining Plus ist ideal für Fortgeschrittene, die das Pro Akustik Hörtraining erfolgreich abgeschlossen haben und ihr Hörverstehen noch weiter verbessern möchten.",
    title: "Was ist das Hörtraining?",
  },
  {
    image: Board1,
    text: "Das Hörtraining beginnt begleitend zur Hörgeräte-Anpassung und dauert 14 Tage, 10 – 20 Minuten täglich. Das Hörtraining geleitet Sie von Tag zu Tag durch die einzelnen Übungen.",
    title: "Ablauf",
  },
  {
    image: Board2,
    text: "Damit Sie Ihr Hörtraining optimal nutzen können, sollten die Tonbeispiele der Übungen eine Lautstärke haben, die für Sie angenehm ist. Deshalb wird sie vor jedem Training wieder neu eingestellt.",
    title: "Lautstärke",
  },
  {
    image: Board3,
    text: "Mit systematisch aufeinander aufbauenden Hör- und Verstehübungen unterstützen Sie die Verarbeitung des neuen Klangbildes mit Hörsystem. Es gibt verschiedene Aufgabentypen bei denen Sie Eingaben tätigen oder ein bzw. mehrere Antwortmöglichkeiten auswählen und anklicken. Jede Übung wird Ihnen erklärt, beachten Sie bitte die entsprechenden Anweisungen.",
    title: "Übungen",
  },
  {
    image: Board4,
    text: "Nach jedem Trainingstag werde Ihnen die Ergebnisse angezeigt. Sie können die Übungen beliebig oft wiederholen. Nur das Ergebnis des ersten Durchlaufs wird gespeichert. Eine detaillierte Auswertung findet nach dem Training mit Ihrem Pro Akustiker statt.",
    title: "Ergebnisseen",
  },
  {
    image: Board5,
    text: "Das Hörfeld gibt einen Überblick über den Frequenz- und Pegelbereich von Schall, der vom menschlichen Gehör wahrgenommen werden kann. Durch eine Hörminderung verändert sich diese Wahrnehmung und das Hörfeld verschiebt sich. Wie sich die Hörminderung auf Ihr Hörfeld auswirkt können Sie im privaten Login Bereich einsehen.",
    title: "Hörfeld",
  },
  {
    image: Board6,
    text: "Bei Pro Akustik berücksichtigen wir bei der Hörversorgung den gesamten Hörvorgang. Deshalb setzen wir nicht nur auf hochwertige, gut angepasste Hörtechnik, sondern auch auf ein gezieltes Training der Hörverarbeitung im Gehirn – Für gutes Hören und gutes Verstehen. Hier kommen Sie zur Pro Akustiker Suche, für Ihren kompetenten Ansprechpartner in Ihrer Nähe.",
    title: "Hinweis Pro Akustiker",
  },
];

/* Component definition */
const Wizard = ({ onClose, steps = defaultSteps }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const swipeRef = useRef(null);

  const swipeOptions = {
    callback: (index) => {
      // Beware!! Hack here to allow ease in transition
      setTimeout(() => setCurrentStep(index), 100);
    },
    continuous: false,
    startSlide: currentStep,
  };

  const onDotClick = (index) => {
    swipeRef.current.slide(index);
  };

  return (
    <Wrapper>
      <ReactSwipe
        className="carousel"
        swipeOptions={swipeOptions}
        ref={swipeRef}
      >
        {steps.map((step, index) => (
          <Content key={index}>
            <SImage alt={step.image} src={step.image} />
            {step.title && (
              <Text as="h4" notr>
                {step.title}
              </Text>
            )}
            <Text className="carousel-text" notr>
              {step.text}
            </Text>
          </Content>
        ))}
      </ReactSwipe>
      <Footer>
        <SkipText notr onClick={onClose}>
          skip
        </SkipText>
        <DotProgress steps={steps} active={currentStep} onClick={onDotClick} />
      </Footer>
    </Wrapper>
  );
};

/* PropTypes */
Wizard.propTypes = {
  onClose: func,
};

Wizard.defaultProps = {};

/* Local utility functions */

/* Local Styled Components */
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 800px;
  margin: 0 auto;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
`;

const SImage = styled.img`
  max-width: 500px;
  width: 100%;
  max-height: 500px;
  object-fit: contain;
`;

const SkipText = styled.div`
  color: #0082ba;
  cursor: pointer;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 8px;
  font-size: 20px;
`;

export default Wizard;
