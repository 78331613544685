/* Global imports */
import React, { useState } from 'react'
import { Responsive } from 'semantic-ui-react'

import {
  Link,
  Redirect,
  Route as RouterRoute,
  Switch,
  withRouter,
} from 'react-router-dom'
import styled from 'styled-components'

/* Local imports */
import * as ROUTES from 'Definitions/routes'
import useSession from 'Hooks/useSession'
import useStyles from 'Hooks/useStyles'
import AudioAdjust from 'Screens/AudioAdjust'
import AboutUs from 'Screens/AboutUs'
import Banana from 'Screens/Banana'
import Demo from 'Screens/Demo'
import DemoExercise from 'Screens/DemoExercise'
import Exercise from 'Screens/Exercise'
import Optional from 'Screens/Optional'
import ExternalTest from 'Screens/ExternalTest'
import Home from 'Screens/Home'
import Login from 'Screens/Login'
import Results from 'Screens/Results'
import OptionalResults from 'Screens/OptionalResults'
import Settings from 'Screens/Settings'
import TrainingOverview from 'Screens/TrainingOverview'
import TrainingDay from 'Screens/TrainingDay'
import OurProducts from 'Screens/OurProducts'
import ProfessionalInfo from 'Screens/ProfessionalInfo'
import ProfessionalSearch from 'Screens/ProfessionalSearch'
import Impressum from 'Screens/Impressum'
import DataProtection from 'Screens/DataProtection'
import ReviewExercise from 'Components/ReviewExercise'
import MobileMenu from 'Components/MobileMenu'
import MainMenu from 'Components/MainMenu'
import GlobalStyles from 'Common/globalStyles'

const routes = [
  {
    component: Home,
    exact:     true,
    key:       1,
    path:      ROUTES.HOME.PATH,
  },
  {
    component: Login,
    key:       2,
    path:      ROUTES.LOGIN.PATH,
  },
  {
    component: TrainingOverview,
    key:       3,
    path:      ROUTES.TRAINING_OVERVIEW.PATH,
    protect:   true,
  },
  {
    component: Exercise,
    exact:     true,
    key:       4,
    path:      ROUTES.EXERCISE.PATH,
    protect:   true,
  },
  {
    component: Results,
    key:       5,
    path:      ROUTES.RESULTS.PATH,
    protect:   true,
  },
  {
    component: ReviewExercise,
    key:       6,
    path:      ROUTES.REVIEW_EXERCISE.PATH,
    protect:   true,
  },
  {
    component: TrainingDay,
    exact:      true,
    key:       7,
    path:      ROUTES.TRAINING_DAY.PATH,
    protect:   true,
  },
  {
    component: Banana,
    key:       8,
    path:      ROUTES.BANANA.PATH,
    protect:   true,
  },
  {
    component: ExternalTest,
    key:       9,
    path:      ROUTES.EXTERNAL_TEST.PATH,
  },
  {
    component: OurProducts,
    key:       10,
    path:      ROUTES.OUR_PRODUCTS.PATH,
  },
  {
    component: AboutUs,
    key:       11,
    path:      ROUTES.ABOUT_US.PATH,
  },
  {
    component: ProfessionalInfo,
    key:       12,
    path:      ROUTES.PROFESSIONAL_INFO.PATH,
    protect:   true,
  },
  {
    component: ProfessionalSearch,
    key:       13,
    path:      ROUTES.PROFESSIONAL_SEARCH.PATH,
  },
  {
    component: Impressum,
    key:       14,
    path:      ROUTES.IMPRESSUM.PATH,
  },
  {
    component: DataProtection,
    key:       15,
    path:      ROUTES.DATA_PROTECTION.PATH,
  },
  {
    component: AudioAdjust,
    key:       15,
    path:      ROUTES.AUDIO_SETTINGS.PATH,
  },
  {
    component: Settings,
    key:       16,
    path:      ROUTES.SETTINGS.PATH,
  },
  {
    component: Demo,
    exact:     true,
    key:       17,
    path:      ROUTES.DEMO.PATH,
  },
  {
    component: DemoExercise,
    exact:     true,
    key:       18,
    path:      ROUTES.DEMO_EXERCISE.PATH,
  },
  {
    component: Optional,
    exact:     true,
    key:       19,
    path:      ROUTES.OPTIONAL_EXERCISE.PATH,
    protect:   true,
  },
  {
    component: OptionalResults,
    exact:     true,
    key:       20,
    path:      ROUTES.OPTIONAL_RESULTS.PATH,
    protect:   true,
  }
]
const menu = [
  {
    sessionHide:  true,
    title:   'mainMenu.aboutUs',
    icon:     'aboutUs.svg',
    to:      ROUTES.ABOUT_US(),
  },
  {
    sessionHide:  true,
    title:   'mainMenu.ourProducts',
    icon:     'ourProducts.svg',
    to:      ROUTES.OUR_PRODUCTS(),
  },
  {
    session: true,
    title: 'mainMenu.professionalInfo',
    icon:   'professionalInfo.svg',
    to:     ROUTES.PROFESSIONAL_INFO(),
  },
  {
    sessionHide:  true,
    title: 'mainMenu.professionalSearch',
    icon:   'professionalSearch.svg',
    to:     ROUTES.PROFESSIONAL_SEARCH(),
  },
  {
    title: 'mainMenu.externalTest',
    icon:   'externalTest.svg',
    to:     ROUTES.EXTERNAL_TEST(),
  },
  {
    session: true,
    title:   'mainMenu.exercises',
    titlePlus:   'mainMenu.exercisesPlus',
    icon:   'exercises.svg',
    to:      ROUTES.TRAINING_OVERVIEW(),
  },
  {
    session: true,
    title:   'mainMenu.banana',
    icon: 'hearingLoss.svg',
    to:      ROUTES.BANANA(),
  },
  {
    title:   'mainMenu.settings',
    icon:     'settings.svg',
    to:      ROUTES.SETTINGS(),
  },
]

const mobileMenu = [
  ...menu,
  {
    title:   'mainMenu.dataProtection',
    icon:     'dataProtection.svg',
    to:      ROUTES.DATA_PROTECTION(),
  },
  {
    title:   'mainMenu.impressum',
    icon:     'impressum.svg',
    to:      ROUTES.IMPRESSUM(),
  }
]

const Route = props => {
  window.scrollTo(0,0)

  return <RouterRoute {...props} />
}

const ProtectedRoute = ({ component: Component, condition, location, ...rest }) => {
  const { userId } = useSession()
  return (
    <Route {...rest} render={(props) => {
      if (userId) {
        return <Component {...props} />
      }

      return <Redirect to={{
        pathname: ROUTES.LOGIN.PATH,
        state: { isPlus: location.state && location.state.isPlus }
      }} />
    }} />
  )}

const Footer = withRouter(({ location }) => {
  const excluded = /(training-overview|training-day|audio-setting)/
  const plus = (location.state && location.state.isPlus);
  
  if (excluded.test(location.pathname)) {
    return null
  }

  return <div className={`footer ${plus && 'plus'}`}>
           <Link to={ROUTES.DATA_PROTECTION()}>Datenschutz</Link> | <Link to={ROUTES.IMPRESSUM()}>Impressum</Link>
         </div>
})

/* Component definition */
const Routes = (props) => {
  const { fontSizeFactor } = useStyles()
  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <>
      <div className="allButFooter">
        <GlobalStyles fontSizeFactor={fontSizeFactor}/>
        <MainMenu
          onToggleMenu={(bool) => bool ? setMenuOpen(false) : setMenuOpen(!menuOpen)}
          menuItems={menu}
          visible={menuOpen}
        />
        {
          menuOpen ?
            <MobileMenu
              onToggleMenu={() => setMenuOpen(!menuOpen)}
              menuItems={mobileMenu}
            /> :
          <Container>
            <Switch>
              { routes.map(({ protect, ...props }) => protect ? <ProtectedRoute {...props} /> : <Route {...props} />) }
            </Switch>
          </Container>
        }
      </div>
      <Responsive minWidth={992}>
        <Footer />
      </Responsive>
    </>
  )
}

/* PropTypes */
Routes.propTypes = {}

Routes.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const Container = styled.div`
  height:    100%;
  margin:    0 auto;
  max-width: 1300px;
`

export default Routes
