import { array, arrayOf, object, oneOfType, number, shape, string } from 'prop-types'

export const listenAndPickAccentedWord = shape({
  day:         number,
  description: oneOfType([arrayOf(string), string]),
  index:       number,
  stages:      oneOfType([arrayOf(array), arrayOf(object)]),
  title:       string,
  type:        string,
})

export const listenAndTypeShape = shape({
  day:         number,
  description: oneOfType([arrayOf(string), string]),
  index:       number,
  stages:      oneOfType([arrayOf(array), arrayOf(object)]),
  title:       string,
  type:        string,
})

export const listenAndSelectToneShape = shape({
  day:         number,
  description: oneOfType([arrayOf(string), string]),
  index:       number,
  options:     array,
  title:       string,
  type:        string
})

export const listenAndPickShape = shape({
  day:         number,
  description: oneOfType([arrayOf(string), string]),
  index:       number,
  title:       string,
  type:        string
})

export const listenAndReviewShape = shape({
  day:         number,
  description: oneOfType([arrayOf(string), string]),
  index:       number,
  title:       string,
  type:        string
})

export const listenAndMarkGapsShape = shape({
  day:         number,
  description: oneOfType([arrayOf(string), string]),
  index:       number,
  title:       string,
  type:        string
})

export const listenAndTypeAndPickShape = shape({
  day:         number,
  description: oneOfType([arrayOf(string), string]),
  index:       number,
  title:       string,
  type:        string
})

export const exerciseShape = object
