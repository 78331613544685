/* Global imports */
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { bool, number, func } from 'prop-types'

/* Local imports */
import { setAudioFile } from 'Utils'
import useAudio, { STATE as AUDIO_STATUS } from 'Hooks/useAudio'
import Button from 'Components/Button'
import Text from 'Components/Text'
import { exerciseShape } from 'Definitions/shapes'

/* Component definition */
const PlayButton = ({
  allowedReplays,
  audiostopped,
  autoplay,
  exercise,
  notExerciseAudio,
  onPlayAudio,
  stage,
  scrollOnLoad,
  scrollOnPlay,
  step,
  optional,
  playIntro,
  ...rest
}) => {
  const [audio, setAudio] = useState(null)
  const { disabled, play, status } = useAudio(audio, allowedReplays, optional)
  const [height, setHeight] = useState(0)
  const [audioClicked, setAudioClicked] = useState(false)

  const playAudio = useCallback(() => {
    setAudioClicked(true)

    if (onPlayAudio){
      onPlayAudio()
    }

    if (scrollOnPlay && height > 0 && status !== AUDIO_STATUS.PLAYING) {
      window.scroll({
        behavior: 'smooth',
        left: 0,
        top: height
      })
    }
    play()
  }, [height, onPlayAudio, play, scrollOnPlay, status])
  
  const getIcon = status => {
    if (status === AUDIO_STATUS.PLAYING) {
      return require('Assets/icons/pause.svg')
    }

    return require('Assets/icons/play.svg')
  }

  const scrollPlayButton = (height) => {
    if(scrollOnLoad && autoplay) {
      return window.scroll(0, height)
    }
  }

  useEffect(() => {
    if(audiostopped && audioClicked && (status === AUDIO_STATUS.STOPPED || status === AUDIO_STATUS.PAUSED)) {
      audiostopped()
    }
  }, [audioClicked, audiostopped, status])

  useEffect(() => {
    if(scrollOnLoad && height === 0) {
      setHeight(document.getElementById('play-button').getBoundingClientRect().top)
    }
  }, [autoplay, height, scrollOnLoad]) 

  useEffect(() => {
    setAudio(!notExerciseAudio ? setAudioFile(exercise, step, stage, playIntro) : 'audioAdjustment.mp3')
  }, [exercise, notExerciseAudio, step, stage, playIntro])

  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      if (audio && autoplay > 0){
        play()
      }

    }, autoplay * 1000)

    return () => clearTimeout(timeoutHandler)

  }, [audio, autoplay, play])


  return (
    <SButton
      id="play-button"
      color="green"
      disabled={disabled}
      notr
      onClick={playAudio}
      onLoad={scrollPlayButton(height)}
      {...rest}
    >
      <Flex>
        <img alt="play" width="48px" src={getIcon(status)}/>
        {
          status !== AUDIO_STATUS.PLAYING ?
          <SText>exercise.buttons.play</SText>
          :
          <SText>exercise.buttons.pause</SText>
        }
      </Flex>
    </SButton>
  )
}

/* PropTypes */
PlayButton.propTypes = {
  allowedReplays:   number,
  autoplay:         number,
  exercise:         exerciseShape,
  notExerciseAudio: bool,
  onPlayAudio:      func,
  stage:            number,
  step:             number,
}

PlayButton.defaultProps = {
  autoplay: 0,
}

/* Local utility functions */

/* Local Styled Components */
const Flex = styled.div`
  display: flex;
  align-items: center;
`
const SText = styled(Text)`
  padding-top: 4px !important;
`

const SButton = styled(Button)`
  font-family: OfficinaSansITCPro-Bold !important
  font-size:      18px !important;
  font-weight:    bold !important;
  letter-spacing: 1.5px !important;
  padding-left:   6px !important;
  padding-right:  12px !important;
  padding:        0 !important;
  text-transform: uppercase !important;
  width:          100% !important;
  margin-bottom:  8px !important;

  img {
    margin-right: 14px;
  }
`
export default PlayButton
