/* Global imports */
import React, { forwardRef } from 'react'
import { Input as SemanticInput, Label } from 'semantic-ui-react'
import I18n from 'Common/i18n'
import { isEmpty } from 'crocks/predicates'
import styled from 'styled-components'

/* Local imports */

/* Component definition */
const Input = ({ className, labelClass, notr, inputType, prefix, sufix, placeholder, ...props }, ref) => {
  const placeholderText = notr || isEmpty(placeholder) ? null : I18n.t(placeholder)

  return (
        <SSemanticInput
          className={className}
          labelPosition='left'
          placeholder={placeholderText}
          ref={ref}
          {...props}
        >
          {
            prefix &&
            <Label
              className={labelClass}
              basic>{prefix}</Label>
          }
            <input
             type={inputType}
             pattern={inputType === "number" ? "[0-9]*" : null}
             style={{ marginBottom: '5px'}}
             className={className}/>
          {
            sufix &&
            <Label
              className={labelClass}
              basic>{sufix}</Label>
          }
        </SSemanticInput>
  )
}

const InputWithRef = forwardRef(Input)

/* PropTypes */
InputWithRef.propTypes = {}

InputWithRef.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SSemanticInput = styled(SemanticInput)`
  display:  flex;
  flex-wrap: wrap;
`

export default InputWithRef
