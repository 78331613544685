/* Global imports */
import React, { useState } from 'react'
import styled from 'styled-components'

// import { } from 'prop-types'
import { Grid, Image } from 'semantic-ui-react'

/* Local imports */
import { PROFESSIONAL_SEARCH } from 'Definitions/routes'
import NextButton from 'Components/NextButton'
import Spacer from 'Components/Spacer'
import Text from 'Components/Text'
import Wizard from 'Components/Wizard'
import results1 from 'Assets/images/demo_results_1.png'
import results2 from 'Assets/images/demo_results_2.png'
import results3 from 'Assets/images/demo_results_3.png'

const steps = [
  {
    image : results1,
    text: '',
    title:  '',
  },
  {
    image : results2,
    text:   '',
    title:  '',
  },
  {
    image : results3,
    text:   '',
    title:  '',
  }
]


/* Component definition */
const DemoSuccessMessage = ({ history, match }) => {
  const [showResults, setShowResults] = useState(false)
  const [showFinalInfo, setShowFinalInfo] = useState(false)
  const { dayIndex } = match.params

  const onClose = () => {
    setShowResults(false)
    setShowFinalInfo(true) 
  }

  return (
    <>
      {
        showResults ?
        <Wizard onClose={onClose} steps={steps}/>
      :
    <Grid padded>
      <Grid.Row>
        {
          showFinalInfo ?
          <>
            <Spacer height="120px"/> 
            <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'center' }}>
                <SContentWrapper>
                <Text as="h5" style={{ textAlign: 'center'}}>exercise.success.demo_final_message</Text>
                <SNextButton
                  className="full-width rounded login-btn"
                  onClick={() => history.push(PROFESSIONAL_SEARCH())}
                  >
                  exercise.success.demo_professional_link
                </SNextButton>
              </SContentWrapper>
          </Grid.Column>
          </>
          :
          <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'center' }}>
            <SContentWrapper>
              <Image
                alt="login-img"
                max-width="517px"
                style={{ padding: '30px' }}
                src={require(`Assets/images/${setIcon(dayIndex)}.svg`)}
              />
              <SWrapper>
                <Text as="h5" style={{ textAlign: 'center'}}>exercise.success.title</Text>
                <Text as="p" style={{ textAlign: 'center'}}>exercise.success.demo_message</Text>
                <Spacer height="16px"/>
                <SNextButton
                  className="full-width rounded login-btn"
                  onClick={() => setShowResults(true)}
                  >
                  exercise.success.demo_results
                </SNextButton>
                <Spacer height="16px"/>
              </SWrapper>
            </SContentWrapper>
          </Grid.Column>
        }
      </Grid.Row>
      </Grid>
    }
    </>
  )
}

/* PropTypes */
DemoSuccessMessage.propTypes = {}

DemoSuccessMessage.defaultProps = {}

/* Local utility functions */
const setIcon = (dayIndex) => {
  return dayIndex === "13" ? 'success-training' : 'success-day'
}

/* Local Styled Components */
const SContentWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width : 500px !important;
`
const SWrapper = styled.div`
  @media (max-width: 992px) {
    max-width: 360px;
  }
`
const SNextButton = styled(NextButton)`
@media (min-width: 768px) {
  height: 44px !important;
}
`

export default DemoSuccessMessage
