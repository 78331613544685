/* Global imports */
import React, { useState, useEffect } from 'react'
import { Image } from 'semantic-ui-react'
import { assocPath } from 'ramda'
import styled from 'styled-components'

/* Local imports */
import Text from 'Components/Text'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'
import useSession from 'Hooks/useSession'

const INITAL_STATE = {
  standard: {
    description: "Die Abbildung zeigt eine Grafik, die dem menschlichen Hörfeld nahekommt. Ohne Hörminderung nehmen Sie das gesamte Hörfeld von gerade eben zu hören bis unangenehm laut wahr (Hördynamik). Dabei zeigt das Sprachfeld (Sprachbanane) keine Veränderungen.",
    image: require('Assets/images/banana/right/ohne.png')
  },
  standardLeft: {
    description: "Die Abbildung zeigt eine Grafik, die dem menschlichen Hörfeld nahekommt. Ohne Hörminderung nehmen Sie das gesamte Hörfeld von gerade eben zu hören bis unangenehm laut wahr (Hördynamik). Dabei zeigt das Sprachfeld (Sprachbanane) keine Veränderungen.",
    image: require('Assets/images/banana/left/ohne.png')
  },
  user: {
    description: "Die Abbildung zeigt eine Grafik, die Ihrer Hörwahrnehmung nahekommt. Durch Ihre Hörminderung verringert sich die Spanne Ihrer Hördynamik. Ziel ist es mit Hilfe von Hörgeräten die Sprachanteile so zu verschieben, dass Sie für Sie wieder hörbar werden.",
    image: require('Assets/images/banana/right/ohne.png'),
  },
  userLeft: {
    description: "Die Abbildung zeigt eine Grafik, die Ihrer Hörwahrnehmung nahekommt. Durch Ihre Hörminderung verringert sich die Spanne Ihrer Hördynamik. Ziel ist es mit Hilfe von Hörgeräten die Sprachanteile so zu verschieben, dass Sie für Sie wieder hörbar werden.",
    image: require('Assets/images/banana/left/ohne.png'),
  },
}

/* Component definition */
const Banana = () => {
  const { user } = useSession()
  const banana = user && user.bananaType
  const bananaLeft = user && user.bananaTypeLeft
  const earToDisplay = user && user.earToDisplay
  const [ bananaData, setBananaData ] = useState(INITAL_STATE)
  const [ activeBanana, setActiveBanana ] = useState('standard')

  useEffect(() => {
    if(banana) {
      setBananaData(assocPath(['user', 'image'], require(`Assets/images/banana/right/${banana}.png`)))
      setBananaData(assocPath(['userLeft', 'image'], require(`Assets/images/banana/left/${bananaLeft}.png`)))
    }
  },[banana, bananaLeft])

  return (
    <div style={{ padding: '12px 8px' }}>
      <Text as="h4">mainMenu.banana</Text>
      <Separator minWidth={0}/>
      <div style={{ 
        maxWidth: '600px',
        margin: '0 auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
        }}>
        <Text  as="p" style={{ alignSelf: 'flex-start', paddingTop: '16px'}} notr>{bananaData[activeBanana].description}</Text>
        <Image src={bananaData[activeBanana].image} alt="banana" style={{ maxWidth: '500px' }}/>
        <Spacer height="16px"/>
        { 
          (earToDisplay === 'both' || earToDisplay === 'right') &&
          <div style={{ textAlign: 'center'}}>
            <Text style={{minWidth: '100px'}} notr>Rechtes Ohr:</Text>
            <SButtonWrapper>
                <SButton 
                  active={ activeBanana === 'standard'}
                  onClick={() => setActiveBanana('standard')}
                >
                  Normalhörigkeit
                </SButton>
                <SButton
                  active={ activeBanana === 'user'}
                  onClick={() => setActiveBanana('user')}
                >
                  Mein Hörvermögen
                </SButton>
            </SButtonWrapper>
          </div>
        }
        <Spacer height="16px"/>
        {
          (earToDisplay === 'both' || earToDisplay === 'left') && 
          <div style={{ textAlign: 'center'}}>
            <Text style={{minWidth: '100px'}} notr>Linkes Ohr:</Text>
          <SButtonWrapper>
              <SButton 
                active={ activeBanana === 'standardLeft'}
                onClick={() => setActiveBanana('standardLeft')}
              >
                Normalhörigkeit
              </SButton>
              <SButton
                active={ activeBanana === 'userLeft'}
                onClick={() => setActiveBanana('userLeft')}
              >
                Mein Hörvermögen
              </SButton>
          </SButtonWrapper>
          </div>
        }
      </div>
    </div>
  )
}

/* PropTypes */
Banana.propTypes = {}

Banana.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SButtonWrapper = styled.div`
border-radius: 20px;
border: 1px solid lightgray;
padding: 3px;
min-width: 280px;
`
const SButton =styled.button`
  background-color: ${({ active }) => active ? '#0082BA' : 'transparent'};
  color: ${({ active }) => active ? 'white' : 'black'}; 
  border: none;
  border-radius: 20px;
  outline: none;
  font-size: 16px !important;
  padding: 8px;
  font-family: "OfficinaSansITCPro-Bold";
  cursor: pointer;
  padding-top: 10px;
`

export default Banana
