/* Global imports */
import React from 'react'
import { Grid, Image } from 'semantic-ui-react'
// import { } from 'prop-types'

/* Local imports */
import Text from 'Components/Text'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'

const aboutUs1 = require('Assets/images/aboutUs1.jpg')
const aboutUs2 = require('Assets/images/aboutUs2.jpg')
const aboutUs3 = require('Assets/images/aboutUs3.jpg')
const aboutUs4 = require('Assets/images/aboutUs4.jpg')

const images = [ aboutUs1, aboutUs2, aboutUs3, aboutUs4]


/* Component definition */
const AboutUs = () => {
  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4">mainMenu.aboutUs</Text>
          <Separator minWidth={0}/>
        </Grid.Column>
      </Grid.Row>
      <Spacer height="32px"/>
      <Grid.Row centered>
      <Grid.Column mobile={16}  tablet={14} computer={14}>
        <Text notr as="p">
        Wir kümmern uns um den Schutz und Erhalt Ihres Gehörs und machen uns stark für eine individuelle, ganzheitliche Hörversorgung – und das in jeder Preisklasse.<br/><br/>

        Wir sind keine Kette – wir sind ein bundesweiter Zusammenschluss selbständiger Hörakustikmeister. So verbinden wir persönlichen Service in inhabergeführten Betrieben mit dem Know-how einer starken Gemeinschaft.<br/><br/>

        Vereinbaren Sie einfach einen Termin vor Ort.<br/><br/>

        Wir freuen uns auf Sie!
        </Text>
      </Grid.Column>
      </Grid.Row>
      <Spacer height="32px"/>
      <Grid.Row centered>
        {
          images.map((image, index) => (
        <Grid.Column mobile={16} tablet={6} computer={6} key={index}>
            <Image style={{ padding: '8px', borderRadius: '16px'}} src={image} alt={`image-${index}`}/>
      </Grid.Column>
          ))
        }
      </Grid.Row>
    </Grid>
  )
}

/* PropTypes */
AboutUs.propTypes = {}

AboutUs.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */

export default AboutUs
