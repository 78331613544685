/* Global imports */
import React from 'react'
import styled from 'styled-components'

// import { } from 'prop-types'
import { Grid, Image } from 'semantic-ui-react'

/* Local imports */
import NextButton from 'Components/NextButton'
import Text from 'Components/Text'
import Spacer from 'Components/Spacer'
import Separator from 'Components/Separator'

/* Component definition */
const InsertHearingAids = ({ lastStepDisplayed, goNext, message, onNext }) => {

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4" notr>Übung 1</Text>
          <Separator minWidth={768}/>
        </Grid.Column>
        <Grid.Column width={16} style={{ display: 'flex', justifyContent: 'center' }}>
          <SContentWrapper>
            <Image
              alt="login-img"
              max-width="517px"
              style={{ padding: '8px' }}
              src={require('Assets/images/ear.svg')}
            />
            <SWrapper>
              <Spacer height="10px"/>
              <Text as="h5" style={{ textAlign: 'center'}} notr>{message}</Text>
            </SWrapper>
          </SContentWrapper>
        </Grid.Column>
      </Grid.Row>
      <NextButton
        className="bottom full-width pos-fixed"
        onClick={() => lastStepDisplayed ? goNext() : onNext()}
      >
        exercise.buttons.next
      </NextButton>
  </Grid>
  )
}

/* PropTypes */
InsertHearingAids.propTypes = {}

InsertHearingAids.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SContentWrapper = styled.div`
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  max-width : 500px !important;
`
const SWrapper = styled.div`
  @media (min-width: 992px) {
    max-width: 360px;
  }
`

export default InsertHearingAids
