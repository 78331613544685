/* Global imports */
import React, { useState } from 'react'
import { Grid } from 'semantic-ui-react'
import styled from 'styled-components'

/* Local imports */
import useStyles from 'Hooks/useStyles'
import Text from 'Components/Text'
import Separator from 'Components/Separator'
import Spacer from 'Components/Spacer'

/* Component definition */
const Settings = () => {
  const { fontSizeFactor, setFontSizeFactor } = useStyles()
  const [activeButton, setActiveButton] = useState(fontSizeFactor)

  const handleClick = value => {
    setActiveButton(value)
    setFontSizeFactor(value)
  }

  return (
    <Grid padded>
      <Grid.Row>
        <Grid.Column width={16}>
          <Text as="h4">mainMenu.settings</Text>
          <Separator minWidth={0}/>
        </Grid.Column>
      </Grid.Row>
      <Spacer height="32px"/>
      <Grid.Row centered>
        <Grid.Column mobile={16} computer={6} style={{ display: 'flex', justifyContent: 'space-around'}}>
          <SButton
            onClick={() => handleClick(1)}
            active={ activeButton === 1}
          >
            <img alt="arrow" src={require('Assets/icons/text_size.svg')} height="48px" width="48px" />
            <span className="no-increase" style={{ paddingTop: '16px'}}>x1</span>
          </SButton>
          <SButton 
            onClick={() => handleClick(1.2)}
            active={ activeButton === 1.2}
          >
            <img alt="arrow" src={require('Assets/icons/text_size.svg')} height="48px" width="48px" />
            <span className="no-increase" style={{ paddingTop: '16px'}}>x2</span>
          </SButton>
          <SButton 
            onClick={() => handleClick(1.4)}
            active={ activeButton === 1.4}
          >
            <img alt="arrow" src={require('Assets/icons/text_size.svg')} height="48px" width="48px" />
            <span className="no-increase" style={{ paddingTop: '16px'}}>x3</span>
          </SButton>
        </Grid.Column>
      </Grid.Row>
      <Spacer height="32px"/>
      <Grid.Row centered>
        <Grid.Column mobile={16} computer={6} style={{ display: 'flex', justifyContent: 'center'}}>
          <Text as="p" notr>Beispiel Textgröße</Text>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

/* PropTypes */
Settings.propTypes = {}

Settings.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const SButton = styled.button`
  font-family: "OfficinaSansITCPro-Bold";
  border: none !important;
  outline: none !important;
  background-color: ${({ active }) => active ? 'rgba(0,130,186,0.15)' : 'transparent'} !important;
  padding:  12px 24px !important;
  border-radius: 8px;
  display: flex !important
  flex-direction: column !important;
  align-items: center;
  cursor: pointer;
`

export default Settings
