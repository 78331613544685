/* Global imports */
import React from 'react'
import styled from 'styled-components'
import { Grid,Progress, Segment } from 'semantic-ui-react'

/* Local imports */
import Text from 'Components/Text'

/* Component definition */
const DemoProgressBar = ({ currentStep, total }) => {
  return (
    <SSegment disabled={false}>
    <Grid>
      <Grid.Column style={{paddingTop: '8px'}} width={16}>
        <Text as="h6" style={{ color: '#0082BA', textAlign: 'center' }}>trainingOverview.progress</Text>
      </Grid.Column>
      <Grid.Column width={16}>
        <div style={{ textAlign: 'center' }}>
          <Progress
            autoSuccess
            percent={(currentStep / total * 100).toFixed(0)}
            size="tiny"
            indicating
          />
          <Text className="caption" as="p" notr>{`${(currentStep / total *100).toFixed(0)} %`}</Text>
        </div>
      </Grid.Column>
    </Grid>
  </SSegment>

  )
}
/* PropTypes */
DemoProgressBar.propTypes = {}
DemoProgressBar.defaultProps = {}

/* Local utility functions */

/* Styles */
const SSegment = styled(Segment)`
  height: 85px !important;
  margin-bottom: 16px !important;
`
export default DemoProgressBar