import { useEffect } from "react";
import { getLatestWebAppVersion } from "../services/appVersion";

const useAppVersionCheck = () => {
  useEffect(() => {
    getLatestWebAppVersion()
      .then((webAppVersion) => {
        if (process.env.REACT_APP_VERSION < webAppVersion) {
          displayDialog();
        }
      })
      .catch((e) => {
        return;
      });
  });
};

const displayDialog = () => {
  if (navigator.userAgent.includes("iOSApp")) {
    if (
      window.confirm(
        "Eine neue Version der Hörtraining App ist im Appstore verfügbar. Jetzt herunterladen?"
      )
    ) {
      window.open(
        "https://apps.apple.com/de/app/h%C3%B6rtraining/id1521197899"
      );
    }
  } else if (navigator.userAgent.includes("Android")) {
    if (
      window.confirm(
        "Eine neue Version der Hörtraining App ist im Playstore verfügbar. Jetzt herunterladen?"
      )
    ) {
      window.open(
        "https://play.google.com/store/apps/details?id=com.unitedcuisines.proakustik.hoertraining&gl=DE"
      );
    }
  }
};

export default useAppVersionCheck;
