/* Global imports */
import React from 'react'
// import { } from 'prop-types'
import styled from 'styled-components'

/* Local imports */
// import spinner from 'Assets/images/spinner.svg'
import Text from 'Components/Text'

const spinner = `iVBORw0KGgoAAAANSUhEUgAAALwAAAC8CAYAAADCScSrAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QAAAAAAAD5Q7t/AAAA
CXBIWXMAAA3XAAAN1wFCKJt4AAATaElEQVR42u3de5RdVX3A8e9vn3vvzOQ1k4SQF0QIYC1gQImo
UUpDJpmQSBWQuOparupa1Wh9tNgFFMzjhCEivuiS1mVou3BpV12doKtKDE4SmGAgFcEFBKMNNalC
HpCQZIY8ZjL3nv3rH5No1JDM3Hv2edzZn7Xgn8zdv/07+eXOOfvsB3ie53me53me53me53me53me
53me53me53me53me53me53me53me53me53me53me53me53me5w1TknYHhpWOjoCfNjcyvlwCYH+x
nyt7+li0KEq7a8OFL3jXwtDArEkYO4GybSGQ37/mkSqYbgKzDza/TBjatLtcz3zBu7Ryw0T6ytMJ
aBjcBwp9YHcQtu1Nu+v1yhe8C6rC0jUXUihMra6B4m6Wz/lfRDTtVOpNkHYH6pAgsy7ByKTqm7Cj
2bR9JF3//mraydQbk3YH6k645nywZ9XcjtUJLOk8L+106o0v+DiFXaMwpXNja68k07j1+6PTTque
+IKPU3TsAmwU33ORjYQRpfPTTque+IKPy1c3NxHoWActjyN8aETa6dULX/BxeW3/BGdtl0vj006v
XviCj0tkmp21XcRd28OML/i4DPrlUhWMw7aHmULaHYjFzR1NNI+eiCmMxdrRiBSJ1FCQfixHqNBD
ofIK4YLXnPUhMkVnbzXKtuSs38NMfgs+VAOd07FyEQFnoyJYBQQUMAInZqUUjv8v7OzGmO3YKdsI
L+mPuUcVcPRNHARlV5dxuMlnwYdrpiPrr0DNSAwDBT4o0oLVK5CdMwgffp6th7ayOqaZigHHgJFu
Eo7i/sc5bOWr4MMHGpGp70I5d/BFfgoqRQjeyiXN07lgzWN84T0Ha+5bYA4R2XGOMnd3KzbM5Kfg
w7VjIJiHMiq+RqWFxtJCPtf5GCvbXqqpqdLIV+k99AYnuR/t3++k3ZOpCis2NgAFeisBTWVlZBBx
5Jyyg9u/1ORjtuRAsS8AaXR0GSywkXDuizU1s/ThtxHEfVsTHCac97SbvIGwqxH6x1CuNFE0p66H
gokwwVF+vf017l+c6+eJ7A9Lhg80QjDPXbEDqMHq1Xx+bW0vjyrFHfH3zTpoE/jYqiIrN0yEvklg
R7xusQNUbEB/eTTTLpzC7RvGDyxqyafsd1ymvgskxtuY170SAf3B1YQd1Q8B3t26HyP74uuU7iWc
fyD2XMOuRqadN4lyuWlIn6v0Cw3l0TBrEmFXfm6HT5Ltgg/XTEeJb/bhGckooua31NSEbfslxhyq
vS/2MFsPb4s9xfChERTsRCq2hrcGUYlC/+Q8Fn12Cz5Ug5SuSDyu8KaBZ4Zq+y2WieO3gO2uug2j
PXBkS2xDpieseroIjWdR6a/92a1iA8qHJ+bt9ibDne2cjroa1z4NIwLBpTW1sXhmGZ7aQqAvgRnC
omxjofgiS699lnBR/CMjew5MgEp8f+fFQpFjM1zMEHUmuwVv5aLUYgvn09FR20SBMLQsXbCdnp6n
qLCHSF9/dCPSMhR3Q+mnhK07nKxlDTtGQRT/FIWRzaPydGuTzY7e3NE0MF0gpfgqRV5ongLUNjYP
cO+iXmAb6AuED4+mWGqi3DswBaHYdIxyfy/t1x4Cxwu2y03NFB20W+kXSsVmwP27ghhks+CbR09E
Jd13BDaYRBwF/1uihLxGGm9NVz1dZM8+F+U+wEYjyEnBZ/OWxhTSvy9UdTVNIHl79g5t+HGoKjYg
3JqLGZ3ZLHhr01+4rBnoQ1yOldx9u59weKf7GDHIZsGLZODimVx8Yw1KQ+R+/6FRCcSIQTYLPtL0
+yU2H/OMBqO/z30uvcVcXK/0C+uUvbLpT1BSk34f4lIy7ncnbjqYix2Qs1nwEUfS7gI2Sr8PsalU
3MeYkECM2mWz4DXoTrsLmGLti0KyYlxDr9sABUv458fSTnMwslnwhcoraXeBgPT7EJfPLDhGweVt
je1z/uIsJtks+HDBa6Dd6XVALJHZlfZliFXlaAwzOF9PKTdLELNZ8ACR2Z5abNFdhLP70r4EsVr+
vh4n3/L9/b15ulbZLfhgyjZE0xkpKZifp51+7ESUStxrYyuW0phcTCk4IbsFH17SjwbPpxB5N59r
rZ/795OF1x2Fvu5Y2iqUFEbtJZydi9GZE7Jb8ABbD2xN9F5eTBkqP0k7bafC67trL/qCpXJ0X55u
ZU7IdsGvXhTRV36MgV29Erga8hOn2/FlRXh9N1T2VndPH/Qzeeyegd8W+ZOL18GEXedA5RpwOOUg
kudon/tM2qkmSlW4Z8MYKnbMGde4litlWkrd3NKW6xdy+Sh4gHD9NKxejXGwZamR51g2zIr9D316
bQPjjzVRGh3Qf2IimFbAVtj9Um/e96M5IT8FD3D72gk0BFfHtm2HmDJGfsLSOekNgXqJylfBA4Qd
JUzzW6jwpoEF19UyO2lqfJLb3u3whYyXNfkr+BMGtt+7FOH8gc1RB5WuRXQXBfPzuh169E4rvwV/
QkdHwAvNU6joZCQYO7BSSUvHz1yuYKMjmOJBAl4henEX4UdyN5TmeZ7neZ7neZ7neZ7nefUlW8OS
N3UEXEITDSOKmKhC75jevE0/9bIt3YLv6Aj4RfNFCBdhZBowDqsnz5VRRHvQ4CVUtzOu8ks+syAX
i4W9bEqn4MMHGjFT347oldgh7QFfxsgzRJXHh8U0Xi92yRd8+/o/Re3Cmo6fFOkHHmPp3M1O9lL3
qhN2lAjGn4vYyahtQcyrVMo7ufjwHhbFfJpJlZIseCHsnE1grsLGtY2dbkcbVudx5U3d+NraBrqL
HwB9H9AGjDjFT+0DeQi1D7Js3o/S/JJKpuDD0GDe8X5ULo69bWNeodF+K+8LE3InDA1m1geBduC8
IXzySYzeypK2H6fR7WQKvn3dAqxe6TCL3diebzo5F8n7Y1/qHEmvfBu4vsoWFOWL6OY7CMMhnIFV
O/drWu9c/xanxQ6gTCFo/gvnuXgQdp5NrzxB9cUOIAi3YWatHjhZMDluCz780TiwCxPJxHIp7Wsv
SCTWcLXq6SJGOoDLYmrxBl4+cG+SKbgteDFtaJLnSBVaUc3Wy7R68vKB+4CrY21T+CQr1n8kqRTc
FfzKDRMx8sakEgHA6mTa19Z2xqp3auGGGQgfddK26D01HQY9BO4KvhK9Nb7hxyHQYGbiMYcDY7+A
u3qZgASfTSQNR+0KxsEQ5KAyCqYRdsWzq4E3IFw3BZjvNIbIXydxO+qm4MOu8VhN5xQ8awVz9PxU
YterQN6L+yHsqbSve6vrVBx9w/dPct3x07LBxFTj1xvVa5IJJHNcR3BT8AXT4rrjpyUm/YON64ly
TjJx1HkcNwUfaYPrjp+WkG78eiNMqZc42d492MuKhCZ7uZ9U5qbgA0l3kYbiF4nESncnFMh5HDcF
X7Hdrjt+BgdSjl9fRHYmFMn5QXKuRmledt3x01L1+0bGSXk0kTjWbnAdwtE4/HWvgqSzK68xCj2/
TiV2vbL8APf38btY3uZ8j36HD636C9edP3VY/Q3hosOpxK5X4bzdwFqnMUT+JYmVUO4KXvnZwLdt
wqw+nXjMYUFuB1ytS91L6ehXk8jCXcGHbXtRuy2JJH5H9rB83tZkYw4Ty+Y+D7LKTeNyC7e9N5Fb
YLfj8FbXIQmdwAegZr3fxcChiWP/DtgYa5uq97Fs7reSSsHxiqf5BzDG+ZP38UyeJWzdkUis4Wrx
zDKFyiKEZ2Nq8UG0lMi04BPcv2ldMvdJjNniOI1dRD1uH6q8AXcs2EdUfCfof9TQiqLcg938gaS3
UkxmgcZNHQFvbr4J5U0OWn+FQ3yLL/ttOhKlKrSv+wDISmD64D/HZoRbWTbviTS6ndyKpDA0MGs+
EuMOBiK/wu560J/blKKwo4RpWTSwEZPMh1NunbgX+AEqD7J8bmea3U1nqz2rC6CWBSLai9rHWH7t
k/4hNUPCjhJB81RUpiA6FmUfprCTyqY9Se8/83rSWeH/6bUNTDBXovIOGMJmqiL9iPkpUfC4317P
q0a6W1rc1BEwY/SFROYiDNNQGQ8nbZctYlF6MPISkfyK/f3buM9vl+1VL1t7uISh4fA7mxhfLnEw
qjDCH4jgeZ7neZ7neZ7neZ7neZ7n5Vq2xuFrEa4dgylegEoLJmoGY7D2EKo9EL1IuDDdheVeJuS7
4L+wvplj9s8wcjmW85HT5rMX5VmC4McsmeN8O4jcCjtKFCeNRw5XeObwAVZn47jJuOSz4MOuRqT/
vYi0whBPGBFR0J8RFf+TcParaaeSurDrLEzUCnYOwkyUlt/9oVhU92JkE9Z20d2zkXsX9abd5Vrk
r+Db116ABh8HOaumdpRjCN9Oa1526v5hzVhGlj6G8mGgcZCfOgjcT0vlAT6TzzlN+Sr4cN1MDIsZ
6rf6aa+A/pClbd8lsf0TM2BF51xEvgJUN0Vb2EFkFxPO/1XaqQxVfjZTDdddjtFPEGexA6gspL3z
xrTTS4YKd67/FGK+QbXFDqBMx5jv0d6Z0L7x8clHwd/1yFSMfALETX9VFnJX56y003Tuzg2fBP17
0Diu42iU+/N23bJf8B0dAdZ+HLTkNI6VD/H5DePTTteZFZ1zgZvjbVQCVP6Zux55Q9rpDVb2C35b
yxxwfzIE0Eiki9JO14m7N40duGeP5Zv99yktaPTltFMcrGwXfNhVQOXaBCO+jbsemZp22rGr9P0N
tdyzn4kyk/ZHcnE/n+2Cp3w5aEti4VQFW7kq7axjFXadhfIh53HUJrqhUrWyXfCBXpZ8UJNCTJfp
VOZBEmdu6SW0d2b+uNDsFryqoDIjhcCTWLmhfo69lKSOnATU/bGTtcpuwa/Y2AyMSSV22Z6bdvrx
EeeH/Z4U64q0sz2T7BZ8wTanFltIL3acwo5Sos9Awtlpp3wm2S34Sn+KRSctaacfi+Kk8WiC00eU
CWmnfCbZLfhSMcX9aKL62AtHDiebh2g57ZTPJN55KXGy0pNabDHdTtoNO8/GFCdjo4ExcRMcotC3
mzsW7HMS75nDB5gxNkJP2s3NJWVvInFqkN2Cb6h005vWZM5Cd2xN3fnoZRBdBfoukEkQnfR7NYJK
EVas3wPyBAU28bnW+PbSX70o4s2de0EmJ3PdNPOryrJb8Le0HWHFuj0ICf1lHaco2vd/NbczsEvy
YoiOj+uf5h+vMBn0/US8nzvX/4JAVsVW+GIeR/WmJC4dYjYlEqcG2S14APS55L6djjOynaULXqv6
82FXgSD6W1Tfg1T1G+piIv1H7ly3Blv8Ws17a1rbhUgCBS+WQB5zH6c22X1oBTD22cRj2hrOLwrX
jsFUvoTqe2rshYBch6ncy92bxtbUUnfPRhA3zwi/12Pt4o7W/c7j1CjbBb/02m2o/Ca5gNpPY9OP
q/rop9c2IIUvApfH2KFLKR/7ImHXYJfg/bGBNaj/FOdV+mNiiUwi56zWKtsFD6D6X8nFMuu47d1V
nBeqwoTCbYj8iYNOXYiU7wCt/gl+4tjvILg74VD4LmFrOievD1H2Cz6c9yzIzxKItJdXy2uq+mT7
+utAZjvrmchVrNiwoOrPL55ZJjIfRaT6Z5PX90uivtBZ7jHLfsEDNNkHQN1tqaFaRvQbVZ0u8tXN
Taj8VQJX4SM13dqErTvA3gyxHhS9H1v5GOF1RxPIPxb5KPhb2o5gS19B1cHx5GoRVrG0rbqhyENH
bwDGOb8GwnhM5Yaa2lja9iiYDwIxPFzK/2D0esIFO53nHqN8FDxAOPtltPR5JMYRB+EYFL/Gsrbq
b5lE5yV3EXRuzU0sa30KKzcgPF1lwhaR1di+G1nS9lJyuccjX/vSAHypcyR98mGUmTW29CKWVYTz
dlfdQrj2HEzx24nmbwsfIpwdz7fqinVzMHwW5eIz/7BY0EeRwpdZes22RHOOUf4K/oSVG2ZQ0RsQ
HdqKeZWDoD9E53YRSm1nh65YfyPCpxLN23If4dzvxdrm3V3nUS63Hp/PPgFhIqrHgP2o7AKeQKMu
wvkHEs3VgYy/aT2NgVfvWwg3vBGjl4NeBkw59Q9LN8oWNHoOGrbEdzKgnpf4d4YQ/5YYt8/+NfCv
x/+ra/kt+BPC1heAF4AOwodGQEMLgTRjpIhGB6lEPYQLDuFkKz2p7S1obmLWj/wX/MkGhseOAtXf
lw+N282hTkmrH5r0cjRKk0nal3xIzfV21WnzBV8Lk8CkrD8kJvOLLLLMF3wtrEl+u2jD9rTTzjNf
8LVQ899ogvvKK0qh4cm0084zX/C1CGd3gyQ5S/Dn3H7VwbTTzjNf8LUyVDfDsqpYJrlYdcoXfK2W
zOkESeJefgdLrlmfdrp55wu+ViIK3I/bM6IU7NePx/Jq4As+Dstan0JxOYnsmzXN6PR+yxd8XJa3
fhMl/lX7ykaWtSY7I7OO+YKPjSjaeifId2JrUnUNWlgJ/lYmLvmdHpxlK9YvBD6BMLLKFg6j9uss
b3s47VTqjS94V+55fDTH+v4S9EYGP8msguqP0OK/DYzxe3HzBe/ard8fzeiRb8fquxHezB+uf1X2
Y3geq4+jxScJZx9Ou8v1zBd80sKuRkq9A7sH9zcdIpyd/IxLz/M8z/M8z/M8z/M8z/M8z/M8z/M8
z/M8z/M8z/M8z/M8z/M8z/M8z/M8z/M8z/M8b4j+H4u316D1csvpAAAAJXRFWHRkYXRlOmNyZWF0
ZQAyMDIwLTA3LTA4VDEyOjE4OjQwKzAyOjAwNPh/jgAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMC0w
Ny0wOFQxMjoxODo0MCswMjowMEWlxzIAAAAZdEVYdFNvZnR3YXJlAHd3dy5pbmtzY2FwZS5vcmeb
7jwaAAAAAElFTkSuQmCC`

/* Component definition */
const Spinner = () => {
  return (
    <Wrapper>
      <img alt="spinner" src={`data:image/png;base64,${spinner}`} />
      <Text>trainingOverview.loading</Text>
    </Wrapper>
  )
}

/* PropTypes */
Spinner.propTypes = {}

Spinner.defaultProps = {}

/* Local utility functions */

/* Local Styled Components */
const Wrapper = styled.div`
  align-items:     center;
  display:         flex;
  flex-direction:  column;
  height:          80vh;
  justify-content: center;

  img {
    animation: spin 3s linear infinite;
    width:     100px;
  }

  @keyframes spin {
  100% {
    transform: rotate(360deg);
    }
  }
`

export default Spinner
